<template>
  <tr
    :key="customer.id"
    v-if="!editMode"
    class="el-table__row"
    data-cy-label="customer-row">
    <template v-if="isReleaseOpen || !isPhysicalSignup">
      <td class="el-check" />
      <td @click.prevent="$emit('expandCustomer', customer.id)">{{customer.index}}</td>
      <td
        @click.prevent="$emit('expandCustomer', customer.id)"
        data-cy-label="customer-row-name">
        {{customer.full_name}}
        <span v-if="customer.note" class="asterisks">*</span>
        <span
          class="asterisks"
          v-if="customer.label"
          :class="`label-${customer.label}`"
        >*</span>
      </td>
      <td
        v-if="shouldShowTimeSlot"
        @click.prevent="$emit('expandCustomer', customer.id)">{{customer.timeslot}}</td>
      <td
        @click.prevent="$emit('expandCustomer', customer.id)"
        class="hide-xs"
      >{{isPhysicalSignup ? (customer.ticket || ' - ') : customer.last_4}}</td>
      <td :class="( isReleaseOpen ? 'text-right pr-ten' : '' )">
        <customer-checkin-actions
          v-if="isReleaseOpen && !notPermittedStore"
          :customer="customer"
          :timeslot="timeslot"
          @checkinAction="$emit('refreshCustomers')" />
        <customer-status v-else :customer="customer" />
      </td>
      <td
        v-if="!isReleaseOpen"
        class="handle-wrapper" />
    </template>
    <template v-else>
      <td class="el-check" />
      <td
        @click.prevent="$emit('expandCustomer', customer.id)"
      >{{customer.ticket || ' - '}}</td>
      <td
        @click.prevent="$emit('expandCustomer', customer.id)"
      >{{customer.index}}</td>
      <td
        v-if="shouldShowTimeSlot"
        @click.prevent="$emit('expandCustomer', customer.id)">{{customer.timeslot}}</td>
      <td @click.prevent="$emit('expandCustomer', customer.id)">
        {{customer.full_name}}
        <span v-if="customer.note" class="asterisks">*</span>
        <span v-if="customer.label" class="asterisks" :class="`label-${customer.label}`">*</span>
      </td>
      <td @click.prevent="$emit('expandCustomer', customer.id)">{{customer.e164_phone}}</td>
      <td v-if="isReleaseOpen" class="text-right pr-ten">
        <customer-checkin-actions
          v-if="isReleaseOpen && !notPermittedStore"
          :customer="customer"
          :timeslot="timeslot"
          @checkinAction="$emit('refreshCustomers')" />
      </td>
      <td
        v-if="!isReleaseOpen"
        @click.prevent="$emit('expandCustomer', customer.id)"
      >
        <span>{{customer.email}}</span>
      </td>
    </template>
  </tr>
</template>
<script>
import { mapState } from 'vuex'
import CustomerStatus from '@/views/admin/lists/customer-status'
import CustomerCheckinActions from '@/views/admin/lists/customer-checkin-actions'
import listStates from '@/mixins/listStates'
import listHelpers from '@/mixins/listHelpers'
import permissionMixin from '@/mixins/permissions'
import calc from '@/mixins/calc'

export default {
  name: 'timeslot-groups-static-row',
  props: [
    'editMode',
    'customer',
    'customers',
    'list',
    'selectedTabInFocus',
    'timeslot',
    'timeslotIndex',
    'expandedCustomer'
  ],
  components: {
    CustomerStatus,
    CustomerCheckinActions
  },
  mixins: [ listStates, listHelpers, calc, permissionMixin ],
  data () {
    return {}
  },
  computed: {
    ...mapState({
      meta: state => state.list.meta,
      filter: state => state.list.filter
    }),
    shouldShowTimeSlot () {
      return this.selectedTabInFocus && !this.isReleaseOpen
    }
  }
}
</script>

<template>
  <fragment>
    <span
      v-if="isInSelections"
      :class="statusKlass(customer)"
    >{{getCustomerStatus(customer) | capitalize}}</span>
    <span v-else>{{emptyLabel}}</span>
  </fragment>
</template>
<script>
import { mapState } from 'vuex'
import listStates from '@/mixins/listStates'

export default {
  name: 'customer-status',
  props: [ 'customer' ],
  mixins: [ listStates ],
  data () {
    return {
      emptyLabel: ' - '
    }
  },
  computed: {
    ...mapState({
      list: state => state.list.list
    })
  },
  methods: {
    getCustomerStatus (customer) {
      if (customer.status === 'unprocessed') {
        return 'Not Selected'
      }

      return customer.status
    },
    statusKlass (customer) {
      if (customer.status === 'confirmed') {
        return 'text-success'
      } else if (customer.status === 'pending') {
        return 'text-warning'
      } else if (customer.status === 'canceled') {
        return 'text-danger'
      }
    }
  }
}
</script>

<template>
  <fragment>
    <tbody class="el-table_body" v-if="!isPhysicalSignup || !showSelected">
      <tr class="el-table__row timeslot-row">
        <td
          class="el-table_empty-label"
          :colspan="!isPhysicalSignup ? 6 : 7">No results</td>
      </tr>
    </tbody>
    <tbody class="el-table__body" v-else>
      <tr class="el-table-row">
        <td
          colspan="7"
          class="text-center">
          <el-button
            type="info"
            icon="el-icon-upload"
            size="small"
            class="upload-csv-btn"
            @click.prevent="$emit('showUploadCSV')">Upload CSV</el-button>
        </td>
      </tr>
    </tbody>
  </fragment>
</template>
<script>
import { mapState } from 'vuex'
import listStates from '@/mixins/listStates'

export default {
  name: 'physical-signup',
  props: [ 'showSelected' ],
  mixins: [ listStates ],
  computed: {
    ...mapState({
      list: state => state.list.list
    })
  }
}
</script>

<template>
  <div class="auth-new-password">
    <div class="logo">
      <img src="@/assets/images/logo.png">
    </div>
    <el-form
      status-icon
      class="auth-form"
      @submit.prevent.native="reset"
    >
      <h5 class="text-center">New password</h5>
      <el-form-item
        :show-message="errors.has('password')"
        :error="errors.first('password')"
      >
        <el-input
          placeholder="Password"
          type="password"
          v-model="auth.password"
          autocomplete="off"
          data-vv-name="password"
          v-validate="'required|min:8|verify_password'"
          ref="password"
          @keyup.enter.native="reset"
        ></el-input>
      </el-form-item>
      <el-form-item
        :show-message="errors.has('repeat password')"
        :error="errors.first('repeat password')"
      >
        <el-input
          placeholder="Repeat password"
          type="password"
          v-model="auth.password_confirmation"
          autocomplete="off"
          data-vv-name="repeat password"
          v-validate="'required|confirmed:password'"
          @keyup.enter.native="reset"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          @click.prevent="reset"
          class="full-width"
        >
          Update my password
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          type="info"
          @click.prevent="cancel"
          class="full-width"
          plain
        >
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'auth.reset',
  data () {
    return {
      loading: false,
      auth: {
        password: '',
        password_confirmation: '',
        client: '',
        uid: '',
        token: ''
      }
    }
  },
  created () {
    var urlParam = new URLSearchParams(location.search)
    this.auth.token = urlParam.get('token')
    this.auth.uid = urlParam.get('uid')
    this.auth.client = urlParam.get('client')
  },
  methods: {
    reset () {
      this.$validator.validateAll()
        .then(res => {
          if (res) {
            this.loading = true
            this.$store.dispatch('auth/FedToken', this.auth).then(() => {
              this.$store.dispatch('auth/updatePassword', this.auth)
                .then(() => {
                  this.loading = false
                  this.$store.dispatch('auth/FedLogOut')
                  this.$message({
                    message: this.$t('auth.password-updated'),
                    type: 'success'
                  })
                  this.login()
                }).catch(() => {
                  this.loading = false
                  this.$message({
                    message: 'Can\'t update password.',
                    type: 'error'
                  })
                })
            })
          }
        })
    },
    cancel () {
      window.location.href = '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/login'
    },
    login () {
      window.location.href = '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/login'
    }
  }
}
</script>

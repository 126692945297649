import Vue from 'vue'
import Vuex from 'vuex'

import layout from './modules/layout'

import auth from './modules/auth'
import customer from './modules/customer'
import invite from './modules/invite'
import list from './modules/list'
import release from './modules/release'
import season from './modules/season'
import store from './modules/store'
import waitlist from './modules/waitlist'
import user from './modules/user'
import setting from './modules/setting'

import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layout,
    auth,
    user,
    invite,
    customer,
    list,
    store,
    waitlist,
    season,
    release,
    setting
  },
  getters
})

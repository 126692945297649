import router from './router'
import { getToken } from './utils/auth'
import store from './store'

const whiteList = [`login`, 'signup', 'reset', 'new-password']
  .map(wl => '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/' + wl)
  .concat(['/', '/404'])

router.beforeEach((to, from, next) => {
  if (getToken()) {
    if (to.path === '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/login') {
      next({ path: '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/seasons' })
    } else {
      if (store.getters.authenticated) {
        next()
      } else if (to.path.indexOf(process.env.VUE_APP_PREFIX_ADMIN_ROUTE) === -1) {
        next()
      } else {
        store.dispatch('auth/validateToken').then(() => {
          next()
        }).catch((err) => {
          store.dispatch('auth/FedLogOut').then(() => {
            console.log(err || 'Verification failed, please login again')
          })
          next({ path: '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/login' })
        })
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1 ||
      to.path.indexOf(process.env.VUE_APP_PREFIX_ADMIN_ROUTE) === -1 ||
      (to.path.includes(process.env.VUE_APP_PREFIX_ADMIN_ROUTE) &&
      to.path.includes('login'))
    ) {
      next()
    } else {
      next('/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/login?redirect=' + to.path)
    }
  }
})

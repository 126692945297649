import request from '@/utils/request'

export function list (params) {
  return request({
    url: `/seasons`,
    method: 'get',
    params: params
  })
}

export function create (data) {
  return request({
    url: `/seasons`,
    method: 'post',
    data: data
  })
}

export function update (data) {
  return request({
    url: `/seasons/${data.id}`,
    method: 'patch',
    data: data
  })
}

export function remove (id) {
  return request({
    url: `/seasons/${id}`,
    method: 'delete'
  })
}

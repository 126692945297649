<template>
  <div class="auth-reset">
    <div class="logo">
      <img src="@/assets/images/logo.png">
    </div>
    <el-form
      status-icon
      class="auth-form"
      @submit.prevent.native="reset"
    >
      <h5 class="text-center">Reset password</h5>
      <el-form-item
        :show-message="errors.has('email')"
        :error="errors.first('email')"
      >
        <el-input
          type="email"
          v-model="auth.email"
          autocomplete="off"
          placeholder="Enter your Email"
          data-vv-name="email"
          v-validate="'required|email'"
          @keyup.enter.native="reset"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          @click.prevent="reset"
          class="full-width"
        >
          Reset my password
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          type="info"
          @click.prevent="cancel"
          class="full-width"
          plain
        >
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'auth.reset',
  data () {
    return {
      loading: false,
      sent: false,
      auth: {
        email: ''
      }
    }
  },
  methods: {
    reset () {
      this.$validator.validateAll()
        .then(res => {
          if (res) {
            this.loading = true
            this.$store.dispatch('auth/resetPassword', this.auth.email)
              .then(() => {
                this.$message({
                  message: this.$t('auth.reset-email-sent'),
                  type: 'success'
                })
                this.sent = true
              }).catch(err => {
                this.$message({
                  message: err.response.data.errors.join('\n'),
                  type: 'error'
                })
              }).then(() => {
                this.loading = false
              })
          }
        })
    },
    cancel () {
      if (typeof localStorage.getItem('store_handle') !== 'undefined') {
        this.$router.push({
          name: 'auth.signin.store',
          params: { store_handle: localStorage.getItem('store_handle') }
        })
      } else {
        this.$router.push({ name: 'auth.signin' })
      }
    }
  }
}
</script>

import { load, update, destroy, loadEvents, search } from '@/api/customer'

const customer = {
  namespaced: true,
  state: {
    customers: [],
    labels: [],
    totals: 0
  },
  actions: {
    load ({ commit }, params) {
      return new Promise((resolve, reject) => {
        load(params).then(res => {
          const data = res.data
          commit('SET_CUSTOMERS', data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    search ({ commit }, params) {
      return new Promise((resolve, reject) => {
        search(params).then(res => {
          const data = res.data
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    update ({ commit }, data) {
      return new Promise((resolve, reject) => {
        update(data).then(res => {
          commit('UPDATE_CUSTOMER', res.data)
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    remove ({ commit }, id) {
      return new Promise((resolve, reject) => {
        destroy(id).then(res => {
          const data = res.data
          commit('AFTER_REMOVE', data)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    refresh ({ commit }, data) {
      return new Promise(resolve => {
        commit('UPDATE_CUSTOMER', data)
        resolve()
      })
    },
    loadMoreEvents ({ commit }, data) {
      return new Promise((resolve, reject) => {
        loadEvents(data).then(res => {
          commit('UPDATE_CUSTOMER_HISTORIES', res.data)
          resolve(res.data.histories)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  mutations: {
    SET_CUSTOMERS: (state, data) => {
      state.customers = data.customers
      state.labels = data.labels
      state.totals = data.totals
    },
    UPDATE_CUSTOMER: (state, data) => {
      let customers = state.customers
      state.customers = []
      let index = customers.findIndex(customer => customer && data && customer.id === data.id)
      if (index > -1) {
        customers[index] = data
      }
      state.customers = customers
    },
    AFTER_REMOVE: (state, data) => {
    },
    UPDATE_CUSTOMER_HISTORIES: (state, data) => {
      let customers = state.customers
      state.customers = []
      let index = customers.findIndex(customer => customer.id === data.customer.id)
      if (index > -1) {
        customers[index].actions = data.histories
      }
      state.customers = customers
    }
  },
  getters: {
    getCustomers: (state) => () => {
      return state.customers
    },
    getTotalCount: (state) => () => {
      return state.totals
    },
    getLabels: (state) => () => {
      return state.labels
    },
    getLabelById: (state) => (id) => {
      let label = state.labels.find(label => label.id === +id)
      return label ? label.label : 'N/A'
    }
  }
}

export default customer

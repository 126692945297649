<template>
  <div class="bulk-edit-cascader">
    <label>Edit selected item(s)</label>
    <el-cascader
      :expandTrigger="trigger"
      v-model="value"
      :options="options"
      placeholder="Perform action to selected"
      data-vv-name="bulk-actions"
      size="medium"
    ></el-cascader>
    <el-button
      type="primary"
      size="medium"
      :loading="loading"
      @click.prevent="confirm"
      :disabled="value.length === 0 || meta.selects.length === 0"
    >Confirm</el-button>
    <el-dialog
      title="Send Custom SMS"
      :visible.sync="sms_dialog"
      center
    >
      <p>This will send an SMS message to every selected customer. If you wish to procees type your message below and send.</p>
      <el-form>
        <el-form-item
          :show-message="errors.has('sms')"
          :error="errors.first('sms')"
        >
          <el-input
            type="textarea"
            rows="7"
            maxlength="500"
            v-model="sms"
            placeholder="Enter your message here..."
            show-word-limit
            data-vv-name="sms"
            v-validate="'required'"
          ></el-input>
        </el-form-item>
        <el-alert
          v-if="sms.length > 160"
          :closable="false"
          type="warning"
          description="This will require multiple text messages.">
        </el-alert>
      </el-form>
      <div slot="footer" class="dialog-footer text-right">
        <el-button
          size="medium"
          class="float-left"
          @click.prevent="sms_dialog = false"
          round
        >Cancel</el-button>
        <el-button
          type="primary"
          round
          size="medium"
          :loading="saving"
          @click.prevent="submitMessage"
        >Send</el-button>
      </div>
    </el-dialog>
    <ConfirmModule
      :open="confirm_modal"
      title="Confirm this action."
      :description="message"
      @on-close="confirm_modal = false"
      @on-confirm="confirmAction"
    />
  </div>
</template>
<script>
import moment from 'moment-timezone'
import { mapActions } from 'vuex'
import calcMixin from '@/mixins/calc'
import permissionMixin from '@/mixins/permissions'
import listStates from '@/mixins/listStates'
import { labels } from '@/consts'
import { sendSMS } from '@/api/customer'
import ConfirmModule from '@/views/components/confirm-module.vue'
import isScreen from '@/utils/screen-helper'

export default {
  name: 'cascade-module',
  components: {
    ConfirmModule
  },
  props: ['list', 'store', 'meta', 'filter', 'loading'],
  mixins: [ calcMixin, permissionMixin, listStates ],
  data () {
    return {
      value: [],
      labels: labels,
      sms_dialog: false,
      saving: false,
      sms: '',
      confirm_modal: false,
      modal_description: ''
    }
  },
  computed: {
    trigger () {
      if (isScreen('xs') || isScreen('sm')) {
        return 'hover'
      } else {
        return 'click'
      }
    },
    timeslots () {
      if (this.list) {
        const timeslotMap = this.getTimeSlotList('00:00', '24:00', this.list.time_slot_interval)
        let timeslots = this.list.time_slot_limits.flatMap((limits, spanIndex) => {
          return timeslotMap.map(timeslot => ({
            label: `${this.isEuStore(this.list.store.country) ? timeslot : this.$options.filters.standard_time(timeslot)}`,
            value: `${spanIndex}->${timeslot}`
          }))
        })

        if (this.list.time_slot_limits.length > 1) {
          timeslots = this.list.time_slot_limits.map((limits, spanIndex) => {
            return {
              value: spanIndex,
              label: `${this.releaseDay(this.list.release.release_date, spanIndex)}`,
              children: timeslotMap.map(timeslot => ({
                label: `${this.isEuStore(this.list.store.country) ? timeslot : this.$options.filters.standard_time(timeslot)}`,
                value: `${spanIndex}->${timeslot}`
              }))
            }
          })
        }
        return timeslots
      } else {
        const timeslotMap = this.getTimeSlotList(this.store.waitlist_first_slot, this.store.waitlist_last_slot, this.store.waitlist_slot_interval)
        const allDates = Array.from({ length: 6 }, (v, i) => moment(new Date()).add(i, 'days'))
        return allDates.flatMap((date) => {
          return timeslotMap.map(timeslot => ({
            label: `${moment(date).format('MMM.D')} - ${timeslot}`,
            value: `${moment(date).format('YYYY-MM-DD')} - ${timeslot}`
          }))
        })
      }
    },
    options () {
      const moveChildren = [
        {
          value: 'time_slot',
          label: 'Time Slot',
          children: this.timeslots
        },
        {
          value: 'store',
          label: 'Store',
          disabled: this.filter.selected === '2',
          children: ((this.list && this.list.stores) || [])
            .map(store => ({ label: store.name, value: store.id }))
            .filter(store => +store.value !== +this.list.store.id)
        }
      ]
      const options = [
        !this.canMoveTimeSlots ? null : {
          value: 'move',
          label: 'Move to ...',
          // Remove "Move to Store" when list is in Finalization
          children: this.list && this.isFinalization ? moveChildren : [moveChildren[0]]
        },
        this.canManageCustomer && {
          value: 'set_label',
          label: 'Set Label',
          children: this.labels
        },
        this.canManageCustomer && {
          value: 'remove',
          label: 'Remove',
          disabled: this.filter.selected === '2'
        },
        {
          value: 'custom_sms',
          label: 'Send Custom SMS',
          disabled: !this.canSendSMS
        }
      ].filter(({ value }) => value)
      return options
    },
    message () {
      let message = ''
      if (this.value.includes('custom_sms')) {
        message = `Do you want to send message to ${this.meta.selects.length} user${this.meta.selects.length > 1 ? 's' : ''}?`
      } else if (this.value[0] === 'move') {
        if (this.value[1] === 'time_slot') {
          message = `Do you want to move ${this.meta.selects.length} user${this.meta.selects.length > 1 ? 's' : ''} to the ${this.timeslotValue(this.value)} time slot?`
        } else if (this.value[1] === 'store') {
          message = `Do you want to move ${this.meta.selects.length} user${this.meta.selects.length > 1 ? 's' : ''} to the ${this.storeById(this.value[2])} store?`
        }
      } else if (this.value[0] === 'set_label') {
        message = `Do you want to label ${this.meta.selects.length} user${this.meta.selects.length > 1 ? 's' : ''} as ${this.labelByValue(this.value[1])}?`
      } else if (this.value[0] === 'remove') {
        message = `Do you want to remove ${this.meta.selects.length} user${this.meta.selects.length > 1 ? 's' : ''}?`
      }
      return message
    }
  },
  watch: {
    value (value) {
      this.$emit('on-change', this.value)
    }
  },
  methods: {
    ...mapActions('list', [
      'resetMeta'
    ]),
    timeslotValue (cascade) {
      if (typeof cascade[2] === 'number') {
        let weekdaySlot = this.timeslots[this.value[2]]
        let timeslot = weekdaySlot.children.find(timeslot => timeslot.value === this.value[3])
        return `${weekdaySlot.label} ${timeslot.label}`
      } else {
        let timeslot = this.timeslots.find(timeslot => timeslot.value === this.value[2])
        return `${timeslot.label}`
      }
    },
    storeById (id) {
      return this.list.stores.find(store => store.id === id).name
    },
    submitMessage () {
      this.$validator.validateAll()
        .then(res => {
          if (res) {
            this.saving = true
            sendSMS({
              customers: this.meta.selects,
              message: this.sms
            }).then(res => {
              this.sms_dialog = false
              this.value = []
              return this.resetMeta()
            }).then(() => {
              this.saving = false
            }).catch(() => {
              console.warn('There was an issue submitting message')
            })
          }
        })
    },
    confirm () {
      if (this.value.includes('custom_sms')) {
        this.sms_dialog = true
      } else {
        this.confirm_modal = true
      }
    },
    confirmAction () {
      this.confirm_modal = false
      this.$emit('on-confirm', this.value)
      this.value = []
    }
  }
}
</script>

import { list, create, update, remove } from '@/api/store'

var defaultStore = {
  id: null,
  tab_name: 'New Store',
  name: '',
  timezone: 'EST',
  address: '',
  city: '',
  state: 'NY',
  zip_code: '',
  country: 'US',
  first_time_slot: '',
  last_time_slot: '',
  store_location: '',
  time_slot_interval: 15,
  reg_weekday: 1,
  online_reg_opening: '',
  close_reg_after: 10,
  total_reg_limit: 5000,
  total_slot_available: 5000,
  auto_release: false,
  first_round_time: '',
  first_round_notification: true,
  second_round_time: '',
  not_selected_notify_time: '',
  time_slot_notify_time: '',
  waitlist_first_slot: '',
  waitlist_last_slot: '',
  waitlist_slot_interval: 30
}

const store = {
  namespaced: true,
  state: {
    stores: []
  },
  actions: {
    list ({ commit }) {
      return new Promise((resolve, reject) => {
        list().then(res => {
          const data = res.data
          commit('SET_STORES', data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    create ({ commit }, data) {
      return new Promise((resolve, reject) => {
        create(data).then(res => {
          commit('CREATE_STORE', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    update ({ commit }, data) {
      return new Promise((resolve, reject) => {
        update(data).then(res => {
          commit('UPDATE_STORE', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    remove ({ commit }, id) {
      return new Promise((resolve, reject) => {
        remove(id).then(() => {
          commit('REMOVE_STORE', id)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    add_tab ({ commit }) {
      commit('ADD_DEFAULT_STORE')
    },
    remove_tab ({ commit }) {
      commit('REMOVE_DEFAULT_STORE')
    }
  },
  mutations: {
    SET_STORES: (state, data) => {
      state.stores = data.map(store => Object.assign({}, store, {
        id: store.id.toString()
      }))
      if (state.stores.length === 0) {
        let stores = state.stores
        defaultStore.id = (stores.length + 1).toString()
        state.stores = [...stores, defaultStore]
      }
    },
    CREATE_STORE: (state, data) => {
      let stores = state.stores.filter(store => store.tab_name !== 'New Store')
      data.id = data.id.toString()
      state.stores = [...stores, data]
    },
    UPDATE_STORE: (state, data) => {
      let index = state.stores.findIndex(s => +s.id === +data.id)
      let stores = state.stores
      if (index > -1) {
        data.id = data.id.toString()
        stores[index] = data
        state.stores = stores
      }
    },
    REMOVE_STORE: (state, id) => {
      state.stores = state.stores.filter(store => store.id !== id)
      if (state.stores.length === 0) {
        let stores = state.stores
        defaultStore.id = (stores.length + 1).toString()
        state.stores = [...stores, defaultStore]
      }
    },
    ADD_DEFAULT_STORE: (state) => {
      let stores = state.stores
      defaultStore.id = (stores.length + 1).toString()
      state.stores = [...stores, defaultStore]
    },
    REMOVE_DEFAULT_STORE: (state) => {
      state.stores = state.stores.filter(store => store.tab_name !== 'New Store')
    }
  },
  getters: {
    getStores: (state) => () => {
      return state.stores
    },
    getStoreCounts: (state) => () => {
      return state.stores.length
    }
  }
}

export default store

<template>
  <div class="store-settings">
    <el-row :gutter="20">
      <el-col :span="18">
        <h2 class="main-title">{{$t('settings.store.title')}}</h2>
        <h4 class="sub-title">Stores</h4>
      </el-col>
      <el-col
        :span="6"
        class="add-new-store-wrapper"
        v-if="canManageStore"
      >
        <el-button
          type="default"
          class="float-right"
          icon="el-icon-plus"
          :disabled="can_add_store"
          size="medium"
          @click.prevent="addNewTab"
          data-cy-label="add-store-button"
        >Add a new store</el-button>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      v-loading="loading"
    >
      <el-col :span="24">
        <el-tabs
          type="card"
          v-model="selectedTab"
          @tab-click="tabClick"
        >
          <el-tab-pane
            v-for="(str, index) in stores()"
            :key="index"
            :label="str.tab_name"
            :name="str.id"
          >
            <h2 class="main-title">Settings</h2>
            <p class="text-secondary mt-zero">These settings pre-fill new releases.</p>
            <Form
              :store="store"
              :saving="saving"
              @onRemove="onRemove"
              @clear-region="onClearState"
              @on-submit="submitStore"
              @onCancel="onCancel"
              @on-auto-release="updateAutoRelease"
              @on-store-display-change="updateStoreDisplay"
            />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Form from './components/form'
import { mapGetters } from 'vuex'
import permissionMixin from '@/mixins/permissions'
export default {
  name: 'stores',
  components: {
    Form
  },
  mixins: [ permissionMixin ],
  data () {
    return {
      loading: false,
      selectedTab: null,
      saving: false,
      store: {
        id: null,
        time_slot_interval: 15,
        waitlist_slot_interval: 15,
        week_day: 1,
        country: 'US',
        auto_release: false,
        state: 'NY',
        close_reg_after: 10
      }
    }
  },
  computed: {
    ...mapGetters({
      stores: 'store/getStores'
    }),
    can_add_store () {
      return this.stores().map(store => store.tab_name.toLowerCase()).includes('new store')
    }
  },
  created () {
    this.loadStores()
  },
  methods: {
    loadStores () {
      this.loading = true
      this.$store.dispatch('store/list')
        .then(() => {
          if (this.stores().length > 0) {
            this.store = this.stores()[0]
            this.selectedTab = this.store.id
          }
        }).catch(() => {
        }).then(() => {
          this.loading = false
        })
    },
    tabClick (tab) {
      this.store = this.stores()[Number(tab.index)]
      this.selectedTab = this.store.id
    },
    addNewTab () {
      this.$store.dispatch('store/add_tab')
        .then(() => {
          this.store = this.stores()[this.stores().length - 1]
          this.selectedTab = this.stores()[this.stores().length - 1].id
        })
    },
    onRemove (id) {
      this.$msgbox({
        title: this.$t('activity.confirm.title'),
        message: this.$t('activity.confirm.description'),
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        center: true,
        roundButton: true,
        confirmButtonClass: 'el-button--danger',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            let message = this.$t('activity.deleted', { name: 'store' })
            this.$store.dispatch('store/remove', id)
              .then(() => {
                this.$message({
                  message: message,
                  type: 'success'
                })
                instance.confirmButtonLoading = false
                done()
                this.store = this.stores()[0]
                this.selectedTab = this.stores()[0].id
              }).catch(() => {
                instance.confirmButtonLoading = false
              })
          } else {
            done()
          }
        }
      })
      this.loading = false
    },
    onCancel () {
      this.$store.dispatch('store/remove_tab')
        .then(() => {
          this.store = this.stores()[0]
          this.selectedTab = this.stores()[0].id
          this.loading = false
        })
    },
    onClearState () {
      this.store.state = null
    },
    updateAutoRelease (value) {
      this.store.auto_release = value
    },
    updateStoreDisplay (value) {
      this.store.store_display = value
    },
    submitStore () {
      this.saving = true
      if (this.store.tab_name === 'New Store') {
        this.$store.dispatch('store/create', this.store)
          .then(() => {
            let message = this.$t('activity.created', { name: 'store' })
            this.$message({
              message: message,
              type: 'success'
            })
            this.store = this.stores()[this.stores().length - 1]
            this.selectedTab = this.stores()[this.stores().length - 1].id
          }).catch(() => {
          }).then(() => {
            this.saving = false
          })
      } else {
        this.$store.dispatch('store/update', this.store)
          .then(() => {
            let message = this.$t('activity.updated', { name: 'store' })
            this.$message({
              message: message,
              type: 'success'
            })
          }).catch(() => {
          }).then(() => {
            this.saving = false
          })
      }
    }
  }
}
</script>

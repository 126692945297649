<template>
  <div
    class="release-register"
    v-loading="loading"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <el-link
          class="link-btn"
          :underline="false"
          @click.prevent="$router.push({name: 'season.releases', params: { season_id: season.id }})"
          icon="el-icon-arrow-left"
        >Back to {{season.name}}</el-link>
        <div
          class="float-right"
        >
          <el-button
            size="small"
            type="info"
            @click.prevent="edit_release = true"
            :disabled="release.deleted_at"
          >
            Edit
          </el-button>
          <el-button
            type="danger"
            size="small"
            @click.prevent="removeRelease"
            :disabled="release.deleted_at"
          >
            Delete
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="float-right text-danger" v-if="release.deleted_at">
          <i class="el-icon-delete"></i> {{ release.deleted_at | moment('dddd, MMM D, YYYY')}}
        </p>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      class="header"
    >
      <el-col
        :span="24"
      >
        <p class="h-two">{{release.name}}</p>
        <p>In-Store Release: {{release.release_date | moment("dddd, MMM D, YYYY")}} </p>
      </el-col>
    </el-row>
    <el-row
      v-for="list in lists"
      :key="list.id"
    >
      <el-col>
        <list-item
          :list="list"
          @on-list-update="onListUpdate"
          @onCancel="onCancel"
        ></list-item>
      </el-col>
    </el-row>
    <ReleaseForm
      :release="release"
      :show_form="edit_release"
      :loading="release_saving"
      @on-close-dialog="edit_release = false"
      @on-submit="saveRelease"
    />
  </div>
</template>
<script>
import ListItem from './list-item'
import ReleaseForm from '@/views/admin/releases/components/release-form'
export default {
  name: 'release.register',
  data () {
    return {
      loading: false,
      release: {},
      season: {},
      stores: [],
      lists: [],
      edit_release: false,
      release_saving: false
    }
  },
  components: {
    ListItem,
    ReleaseForm
  },
  computed: {
    season_id () {
      return this.$route.params.season_id
    },
    release_id () {
      return this.$route.params.release_id
    }
  },
  created () {
    this.loadRelease()
  },
  methods: {
    loadRelease () {
      this.loading = true
      let info = {
        season_id: this.season_id,
        release_id: this.release_id
      }
      this.$store.dispatch('release/show', info)
        .then(() => {
          this.release = Object.assign({}, this.$store.state.release.release)
          this.season = Object.assign({}, this.release.season)
          this.lists = this.release.lists.slice(0)
        }).catch(() => {
          this.$router.push({ name: 'seasons' })
        }).then(() => {
          this.loadTestKeys()
          this.loading = false
        })
    },
    loadTestKeys () {
      this.lists
        .filter(({ status = 0 }) => status === 2)
        .map(list => this.$store.dispatch('list/getSignupTestKeys', list))
    },
    saveRelease () {
      this.release_saving = true
      let message = this.$t('activity.updated', { name: 'Release' })
      this.$store.dispatch('release/update', this.release)
        .then(() => {
          this.release_saving = false
          this.edit_release = false
          this.$message({
            message: message,
            type: 'success'
          })
          this.loadRelease()
        }).catch(() => {
          this.release_saving = false
        })
    },
    removeRelease () {
      this.$msgbox({
        title: this.$t('activity.confirm.title'),
        message: this.$t('activity.confirm.description'),
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        center: true,
        roundButton: true,
        confirmButtonClass: 'el-button--danger',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            let message = this.$t('activity.deleted', { name: 'Release' })
            this.$store.dispatch('release/destroy', this.release.id)
              .then(() => {
                instance.confirmButtonLoading = false
                done()
                this.$message({
                  message: message,
                  type: 'success'
                })
                this.$router.push({
                  name: 'season.releases',
                  params: {
                    season_id: this.season.id
                  }
                })
              }).catch(() => {
                instance.confirmButtonLoading = false
              })
          } else {
            done()
          }
        }
      })
    },
    onListUpdate (list) {
      let lists = this.release.lists
      let index = lists.findIndex(l => +l.id === list.id)
      if (index > -1) {
        lists[index] = list
        this.release.lists = lists
      }
      this.saveRelease()
    },
    onCancel (meta) {
      let lists = this.lists
      let index = lists.findIndex(list => +list.id === meta.id)
      this.lists = []
      lists[index] = meta
      this.loading = true
      setTimeout(() => {
        this.lists = lists
        this.loading = false
      }, 1)
    }
  }
}
</script>

import { downloadReport } from '@/api/list'

export default {
  data () {
    return {
      downloading: false,
      downloadPercentage: '0.00'
    }
  },
  methods: {
    checkQueues () {
      setInterval(async () => {
        const queues = this.$localStorage.get('download_queues') || []
        if (!queues.length) {
          return
        }
        queues.forEach(async (queue) => {
          const { data = '' } = await downloadReport(queue.file_name) || {}
          if (data) {
            this.$localStorage.set('download_queues', queues.filter(q => q.file_name !== queue.file_name))
            this.saveFile(data, queue.file_name)
          }
        })
      }, 3000)
    },
    saveFile (data, fileName) {
      const blob = new Blob([data], { type: 'octet/stream' })
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = blobUrl
      link.download = fileName
      link.style = 'display: none'

      // Append link to the body
      document.body.appendChild(link)

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      )
      // Remove link from body
      document.body.removeChild(link)
    }
  }
}

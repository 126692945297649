<template>
  <div
    v-loading="loading"
    class="list-management"
  >
    <waitlist-header
      @on-create-customer="loadWaitlist"
    ></waitlist-header>
    <waitlist-metric
      :waitlistMetrics="waitlistMetrics"
    ></waitlist-metric>
    <div v-if="canScheduleRelease">
      <el-row :gutter="20" type="flex" class="row-bg" justify="end">
        <el-col :span="6">
          <span
            class="text-secondary"
          >Show</span>
          <el-select
            v-model="selectedWaitlistDate"
            :disabled="allPossibleDates.length === 1 || parseInt(filter.selected, 10) === 2"
            class="ml-seven"
            data-vv-name="waitlist-day-selector"
          >
            <el-option
              :value="waitlistDate.value"
              v-for="(waitlistDate, spanIndex) in allPossibleDates"
              :key="spanIndex"
              :label="waitlistDate.label"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <cascade-actions
      v-if="editMode && canEditList"
      @refreshCustomers="loadWaitlist"
    />
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs
          v-model="selectedTab"
          type="card"
          stretch>
          <el-row class="tab-search-sec">
            <el-col :span="24">
              <el-input
                placeholder="Search list..."
                prefix-icon="el-icon-search"
                size="medium"
                v-model="searchText"
                @input="debounceOnChange"
              ></el-input>
            </el-col>
          </el-row>
          <el-tab-pane
            v-for="status in statuses"
            :key="status.value"
            :label="status.label"
            :name="status.value">
            <div
              v-loading="customersLoading"
              class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
            >
              <timeslot-groups
                @refreshCustomers="getCustomers"
              ></timeslot-groups>
            </div>
            <div class="el-table__append-wrapper">
              <el-pagination
                @size-change="handlePageSize"
                :current-page.sync="filter.page"
                :page-sizes="[25, 50, 75, 100]"
                :pager-count="5"
                :page-size="filter.limit"
                layout="sizes, prev, pager, next, jumper"
                :total="totals"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <cascade-actions
      v-if="editMode && canEditList"
      @refreshCustomers="getCustomers" />
    <div v-if="parseInt(filter.selected, 10) === 2">
      <el-button
        @click="clearWaitlist"
        :disabled="isSecurity || isAdvancedSecurity"
        >Clear Waitlist
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { listReleaseTabs } from '@/consts'
import WaitlistMetric from './waitlist-metric'
import WaitlistHeader from './waitlist-header'
import TimeslotGroups from './timeslot-groups'
import CascadeActions from './cascade-actions'
import debounce from 'lodash.debounce'
import permissionMixin from '@/mixins/permissions'

export default {
  name: 'store.waitlist',
  components: {
    WaitlistHeader,
    WaitlistMetric,
    TimeslotGroups,
    CascadeActions
  },
  mixins: [permissionMixin],
  data () {
    return {
      selectedTab: '1',
      searchText: '',
      statuses: listReleaseTabs,
      waitlistDates: [],
      selectedWaitlistDate: '',
      waitlistMetrics: { expected: 0, checkin: 0, entered: 0 }
    }
  },
  watch: {
    'storeId': {
      handler () {
        this.loadWaitlist()
      }
    },
    'filter': {
      handler () {
        this.setCustomersLoading(true)
        this.getCustomers()
      },
      deep: true
    },
    selectedWaitlistDate (newVal, oldVal) {
      const { value = '' } = this.allPossibleDates.find(
        ({ label = '', value = '' }) => label === newVal || value === newVal
      )
      this.setFilterOption({
        option: 'selected_date',
        value
      })
      this.loadWaitlist()
    },
    selectedTab (value) {
      this.setFilterOption({
        option: 'selected',
        value
      })
    }
  },
  computed: {
    ...mapState({
      loading: state => state.waitlist.loading,
      customersLoading: state => state.waitlist.customersLoading,
      store: state => state.waitlist.store,
      customers: state => state.waitlist.customers,
      allPossibleDates: state => state.waitlist.allPossibleDates,
      totals: state => state.waitlist.totals,
      editMode: state => state.waitlist.editMode,
      filter: state => state.waitlist.filter
    }),
    storeId () {
      return this.$route.params.waitlist_id
    },
    debounceOnChange () {
      return debounce(() => {
        this.setFilterOption({
          option: 'search_text',
          value: this.searchText
        })
      }, 700)
    }
  },
  created () {
    this.loadWaitlist()
  },
  methods: {
    ...mapActions('waitlist', [
      'setLoading',
      'setCustomersLoading',
      'setFilterOption',
      'show',
      'getCustomers',
      'clear'
    ]),
    loadWaitlist () {
      this.setLoading(true)
      this.show(this.storeId)
        .then(() => {
          this.getCustomers()
            .then(() => {
              const [ { value = '' } = {} ] = this.allPossibleDates || []
              this.selectedWaitlistDate = this.selectedWaitlistDate || value
              this.updateStats()
              this.setLoading(false)
            })
        })
    },
    handlePageSize (limit) {
      this.setFilterOption({
        option: 'limit',
        value: limit
      })
    },
    updateStats () {
      const filteredCustomers = this.customers.filter(
        ({ booking_date: bookingDate = '' }) => bookingDate === this.selectedWaitlistDate
      ) || []
      this.waitlistMetrics = {
        expected: filteredCustomers.length,
        checkin: filteredCustomers.filter(({ check_in: checkIn = false }) => checkIn).length,
        entered: filteredCustomers.filter(({ entered = false }) => entered).length
      }
    },
    clearWaitlist () {
      this.$msgbox({
        title: this.$t('activity.waitlist-clear.confirm.title'),
        message: this.$t('activity.waitlist-clear.confirm.description'),
        showCancelButton: true,
        confirmButtonText: 'Clear',
        cancelButtonText: 'Cancel',
        center: true,
        roundButton: true,
        confirmButtonClass: 'el-button--danger',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.clear({
              storeId: this.storeId,
              timeslot: this.selectedTab === '1'
            }).then(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <release-open-header
      @showAddCustomer="$emit('showAddCustomer')"
    />
    <release-open-metrics />
    <cascade-actions
      v-if="editMode && canEditList"
      @refreshCustomers="$emit('refreshCustomers')" />
    <el-row v-if="!editMode && list.time_slot_limits.length > 1">
      <el-col :span="24" class="text-right">
        <ReleaseDaySelector
          :disabled="selectedTab === '2'"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs
          v-model="selectedTab"
          type="card"
          :before-leave="switchTab"
          stretch>
          <el-row class="tab-search-sec">
            <el-col :span="24">
              <el-input
                placeholder="Search list..."
                prefix-icon="el-icon-search"
                size="medium"
                v-model="searchText"
                @input="debounceOnChange"
                data-vv-name="release-open-search"
              ></el-input>
            </el-col>
          </el-row>
          <el-tab-pane
            v-for="status in statuses"
            :key="status.value"
            :label="status.label"
            :name="status.value">
            <div
              class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
              v-loading="loading">
              <timeslot-groups
                :status="status.value"
                @refreshCustomers="$emit('refreshCustomers')" />
            </div>
            <div class="el-table__append-wrapper">
              <el-pagination
                @sizeChange="handlePageSize"
                :current-page.sync="filter.page"
                :page-sizes="[25, 50, 75, 100]"
                :pager-count="5"
                :page-size="filter.limit"
                layout="sizes, prev, pager, next, jumper"
                :total="totals"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
   <cascade-actions
      v-if="editMode"
      @refreshCustomers="$emit('refreshCustomers')" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { listReleaseTabs } from '@/consts'
import { listRegistration } from '@/api/list'
import ReleaseOpenHeader from '@/views/admin/lists/release-open-header'
import ReleaseOpenMetrics from '@/views/admin/lists/release-open-metrics'
import CascadeActions from '@/views/admin/lists/cascade-actions'
import TimeslotGroups from '@/views/admin/lists/timeslot-groups'
import calcMixin from '@/mixins/calc'
import listHelpers from '@/mixins/listHelpers'
import permissionMixin from '@/mixins/permissions'
import ElPagination from '@/views/components/pagination'
import ReleaseDaySelector from '@/views/admin/lists/release-day-selector'
import debounce from 'lodash.debounce'

export default {
  name: 'release-open',
  data () {
    return {
      searchText: '',
      selectedStatus: '1',
      statuses: listReleaseTabs,
      selectedTab: '1'
    }
  },
  mixins: [ permissionMixin, calcMixin, listHelpers ],
  components: {
    ReleaseOpenHeader,
    ReleaseOpenMetrics,
    CascadeActions,
    TimeslotGroups,
    ElPagination,
    ReleaseDaySelector
  },
  watch: {
    searchText (value) {
      this.$emit('filterList', value)
    },
    selectedTab (value) {
      this.setFilterOption({
        option: 'selected',
        value
      })
    }
  },
  computed: {
    ...mapState({
      filter: state => state.list.filter,
      loading: state => state.list.isLoading,
      editMode: state => state.list.editMode,
      list: state => state.list.list,
      totals: state => state.list.customers.totals,
      customers: state => {
        return state.list.customers.customers
      }
    }),
    debounceOnChange () {
      return debounce(() => {
        this.$emit('refreshCustomers')
      }, 700)
    }
  },
  methods: {
    ...mapActions('list', [
      'complete',
      'resetMeta',
      'setFilterOption'
    ]),
    updateRegistration (customerId, action, value) {
      listRegistration({
        id: this.list.id,
        customer_id: customerId,
        action: action,
        value: value
      }).then(res => {
        this.$emit('onUpdate')
      }).catch(() => {
        console.warn('Error updating registration')
      })
    },
    updateSearch () {
      this.$emit('onUpdate-search', this.search_text)
    },
    handlePageSize (limit) {
      this.$emit('sizeChange', limit)
    },
    switchTab (oldTab, newTab) {
      this.selectedStatus = newTab
      this.resetMeta()
    }
  }
}
</script>

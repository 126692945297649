import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      seasons: 'season/getSeasons'
    })
  },
  methods: {
    conditionallyRedirect ({
      seasons: [{ id: seasonId = '', ...seasons } = {}] = [],
      latest_list: {
        season_id: latestSeasonId = '',
        id: latestListId = '',
        ...latestList
      } = {}
    }) {
      if (this.$route.name !== 'seasons') {
        return
      }
      if (!seasonId) {
        return this.$router.push({
          name: this.canManageStore ? 'settings.stores' : 'settings.customers'
        })
      }
      if (
        (typeof this.$route.query.store === 'undefined' &&
        this.$route.query.default_store_id === null) ||
        !latestListId
      ) {
        return this.toSeason(seasonId)
      }
      return this.$router.push({
        name: 'list.management',
        params: {
          season_id: latestSeasonId,
          list_id: latestListId
        }
      })
    },
    toSeason (id) {
      if (id !== this.$route.params.season_id) {
        this.$router.push({
          name: 'season.releases',
          params: { season_id: id }
        })
      }
    }
  }
}

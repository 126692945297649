<template>
  <div class="global-settings">
    <el-row :gutter="20"
      v-loading="loading"
    >
      <el-col :md="10">
        <h2 class="main-title">{{$t('settings.global.title')}}</h2>
        <h4 class="small-title">{{$t('settings.global.subtitle')}}</h4>
        <p class="text-secondary">{{$t('settings.global.description')}}</p>
        <el-form
        >
          <el-form-item
            label="US"
          >
            <el-input
              v-model="settings.us_note"
              type="textarea"
              rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="UK"
          >
            <el-input
              v-model="settings.uk_note"
              type="textarea"
              rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="France"
          >
            <el-input
              v-model="settings.fr_note"
              type="textarea"
              rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Germany"
          >
            <el-input
              v-model="settings.de_note"
              type="textarea"
              rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Italy"
          >
            <el-input
              v-model="settings.it_note"
              type="textarea"
              rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              size="medium"
              @click.prevent="cancel"
            >Cancel</el-button>
            <el-button
              @click.prevent="confirm_modal = true"
              :loading="saving"
              type="primary"
              size="medium"
            >Publish</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <ConfirmModule
      :open="confirm_modal"
      title="Do you want to publish these changes?"
      description="The changes will update immediately."
      @on-close="confirm_modal = false"
      @on-confirm="saveSetting"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ConfirmModule from '@/views/components/confirm-module.vue'

export default {
  name: 'global',
  components: {
    ConfirmModule
  },
  computed: {
    ...mapGetters({
      global_setting: 'setting/getSettings'
    })
  },
  data () {
    return {
      loading: false,
      saving: false,
      confirm_modal: false,
      settings: {
        id: null,
        us_note: '',
        uk_note: '',
        fr_note: '',
        de_note: '',
        it_note: ''
      },
      meta: {}
    }
  },
  created () {
    this.loadSettings()
  },
  methods: {
    loadSettings () {
      this.loading = true
      this.$store.dispatch('setting/load')
        .then(() => {
          this.settings = this.global_setting()
          this.meta = Vue.util.extend({}, this.global_setting())
        }).catch(() => {
        }).then(() => {
          this.loading = false
        })
    },
    saveSetting () {
      this.confirm_modal = false
      this.saving = true
      this.$store.dispatch('setting/save', this.settings)
        .then(() => {
          let message = this.$t('activity.saved', { name: 'global setting' })
          this.$message({
            message: message,
            type: 'success'
          })
        }).catch(() => {
        }).then(() => {
          this.saving = false
        })
    },
    cancel () {
      this.settings = this.meta
    }
  }
}
</script>

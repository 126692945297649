const TokenKey = 'authorization'
const ClientKey = 'client'
const UID = 'uid'

export function getToken () {
  return localStorage.getItem(TokenKey)
}

export function setToken (token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken () {
  return localStorage.removeItem(TokenKey)
}

export function getClient () {
  return localStorage.getItem(ClientKey)
}

export function setClient (client) {
  return localStorage.setItem(ClientKey, client)
}

export function removeClient () {
  return localStorage.removeItem(ClientKey)
}
export function getUID () {
  return localStorage.getItem(UID)
}

export function setUID (uid) {
  return localStorage.setItem(UID, uid)
}

export function removeUID () {
  return localStorage.removeItem(UID)
}

<template>
  <div
    class="display-inline float-right"
  >
    <span
      class="text-secondary"
    >Show</span>
    <el-select
      v-model="filter.spanIndex"
      :disabled="disabled"
      class="ml-seven"
      data-vv-name="release-day-selector"
    >
      <el-option
        value="all"
        label="All Time Slots"
      ></el-option>
      <el-option
        :value="spanIndex"
        v-for="(timeslots, spanIndex) in list.time_slot_limits"
        :key="spanIndex"
        :label="`${$options.filters.numeral(spanIndex + 1, 'Oo')} Day Time Slots`"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'release-day-selector',
  props: ['disabled'],
  computed: {
    ...mapState({
      filter: state => state.list.filter,
      list: state => state.list.list
    })
  }
}
</script>

import { login, logout, validateToken, resetPassword, updatePassword } from '@/api/auth'
import { getToken, setToken, removeToken, getClient, setClient, removeClient, getUID, setUID, removeUID } from '@/utils/auth'

const auth = {
  namespaced: true,
  state: {
    token: getToken(),
    client: getClient(),
    uid: getUID(),
    authenticated: false,
    user: {},
    regList: [],
    closedNote: null,
    newReg: {},
    upcomingLists: [],
    viewMode: false
  },
  actions: {
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then((response) => {
          const user = response.data.data

          commit('SET_TOKEN', response.headers['access-token'])
          commit('SET_CLIENT', response.headers['client'])
          commit('SET_UID', response.headers['uid'])
          commit('SET_USER', user)
          commit('SET_AUTHENTICATED', true)

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    validateToken ({ commit, state }) {
      return new Promise((resolve, reject) => {
        validateToken(state.token).then(response => {
          const user = response.data.data
          commit('SET_USER', user)
          commit('SET_AUTHENTICATED', true)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_CLIENT', '')
          commit('SET_UID', '')
          commit('SET_USER', {})
          commit('SET_AUTHENTICATED', false)
          removeToken()
          removeClient()
          removeUID()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_CLIENT', '')
        commit('SET_UID', '')
        removeToken()
        removeClient()
        removeUID()
        resolve()
      })
    },
    FedToken ({ commit }, auth) {
      return new Promise(resolve => {
        commit('SET_TOKEN', auth.token)
        commit('SET_CLIENT', auth.client)
        commit('SET_UID', auth.uid)
        resolve()
      })
    },
    resetPassword ({ commit }, email) {
      return new Promise((resolve, reject) => {
        resetPassword(email).then(res => {
          commit('RESET_PASSWORD')
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updatePassword ({ commit }, auth) {
      return new Promise((resolve, reject) => {
        updatePassword(auth).then(res => {
          commit('PASSWORD_UPDATE')
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    setViewMode ({ commit }, value) {
      commit('SET_VIEW_MODE', value)
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    SET_CLIENT: (state, client) => {
      setClient(client)
      state.client = client
    },
    SET_UID: (state, uid) => {
      setUID(uid)
      state.uid = uid
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_AUTHENTICATED: (state, value) => {
      state.authenticated = value
    },
    RESET_PASSWORD: (state) => {

    },
    PASSWORD_UPDATE: (state) => {

    },
    SET_VIEW_MODE: (state, value) => {
      state.viewMode = value
    }
  },
  getters: {
    currentUser (state) {
      return state.user
    }
  }
}

export default auth

import request from '@/utils/request'

export function load (params) {
  return request({
    url: '/users',
    method: 'get',
    params: params
  })
}

export function update (data) {
  return request({
    url: `/users/${data.id}`,
    method: 'patch',
    data: data
  })
}

export function destroy (id) {
  return request({
    url: `/users/${id}`,
    method: 'delete'
  })
}

export function invite (data) {
  return request({
    url: `/invites/`,
    method: 'post',
    data: data
  })
}

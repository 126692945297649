<template>
  <div>
    <cascade-actions
      v-if="editMode"
      @refreshCustomers="$emit('refreshCustomers')"
    />
    <el-row
      :gutter="20"
      v-if="isAutomaticFlow && isAfterNotSelectedSent"
    >
      <el-col :span="24">
        <el-tabs
          v-model="selectedTab"
          type="card"
          :before-leave="switchTab">
          <el-tab-pane
            v-for="status in statuses"
            :key="status.value"
            :label="status.label"
            :name="status.value">
            <div
              class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
              v-loading="loading">
              <timeslot-groups
                :status="status.value"
                @refreshCustomers="$emit('refreshCustomers')" />
            </div>
            <div class="el-table__append-wrapper">
              <el-pagination
                @sizeChange="handlePageSize"
                :current-page.sync="filter.page"
                :page-sizes="[25, 50, 75, 100]"
                :pager-count="5"
                :page-size="filter.limit"
                layout="sizes, prev, pager, next, jumper"
                :total="totals"
                hide-on-single-page>
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-col>
        <el-col :span="24">
          <div
            class="el-table has-border el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
            v-loading="loading">
            <timeslot-groups
              :status="isAutomaticFlow ? '2' : '1'"
              @showUploadCSV="$emit('showUploadCSV')"
              @refreshCustomers="$emit('refreshCustomers')" />
          </div>
          <div class="el-table__append-wrapper">
            <el-pagination
              @sizeChange="handlePageSize"
              :current-page.sync="filter.page"
              :page-sizes="[25, 50, 75, 100]"
              :pager-count="5"
              :page-size="filter.limit"
              layout="sizes, prev, pager, next, jumper"
              :total="totals"
              hide-on-single-page>
            </el-pagination>
          </div>
        </el-col>
      </el-col>
    </el-row>
    <cascade-actions
      v-if="editMode"
      @refreshCustomers="$emit('refreshCustomers')"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import calcMixin from '@/mixins/calc'
import listHelpers from '@/mixins/listHelpers'
import listStates from '@/mixins/listStates'
import { statuses } from '@/consts'
import CascadeActions from '@/views/admin/lists/cascade-actions'
import TimeslotGroups from '@/views/admin/lists/timeslot-groups'
import ElPagination from '@/views/components/pagination'

export default {
  name: 'physical-signup',
  mixins: [ calcMixin, listHelpers, listStates ],
  components: {
    CascadeActions,
    TimeslotGroups,
    ElPagination
  },
  data () {
    return {
      selectedStatus: '1',
      statuses: statuses,
      selectedTab: '1'
    }
  },
  computed: {
    ...mapState({
      editMode: state => state.list.editMode,
      filter: state => state.list.filter,
      loading: state => state.list.isLoading,
      list: state => state.list.list,
      totals: state => state.list.customers.totals,
      customers: state => state.list.customers.customers
    }),
    hasTickets () {
      return this.customers.map(customer => customer.ticket)
        .some((el) => {
          return el !== null
        })
    }
  },
  watch: {
    selectedTab (value) {
      this.setFilterOption({
        option: 'selected',
        value
      })
    }
  },
  methods: {
    ...mapActions('list', [
      'resetMeta',
      'setFilterOption'
    ]),
    handlePageSize (limit) {
      this.$emit('sizeChange', limit)
    },
    switchTab (oldTab, newTab) {
      this.selectedStatus = newTab
      this.resetMeta()
    }
  }
}
</script>

<template>
  <el-header class="el-admin">
    <div class="sidebar-button" @click="switchSidebar">
      <svg class="icon" v-if="!isOpened" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" viewBox="0 0 448 512">
        <path fill="currentColor" d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z" class=""></path>
      </svg>
      <svg v-if="isOpened" width="17" height="17" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="close-icon">
        <path d="M0.45 1.22098e-05L0 0.450012L8.55 9.00001L9 8.55001L0.45 1.22098e-05Z" fill="black"/>
        <path d="M8.55 1.98002e-05L0 8.55002L0.45 9.00002L9 0.45002L8.55 1.98002e-05Z" fill="black"/>
      </svg>
    </div>
    <div v-if="isOpened && !canSeeSidebar" class="security-mobile-avatar user-avatar-dropdown">
      <account-dropdown></account-dropdown>
    </div>
    <div class="header-left">
      <div v-if="!canSeeSidebar" class="logo">
        <a href="#" @click.prevent="$emit('logo-redirect')">
          <img class="ta-c" src="@/assets/images/logo.png">
        </a>
      </div>
      <div v-if="canSeeSidebar" class="logo logo-mobile">
        <a href="#" @click.prevent="$emit('logo-redirect')">
          <img class="ta-c" src="@/assets/images/logo.png">
        </a>
      </div>
      <div class="app-name">{{$t('app.name')}}</div>
    </div>
    <div class="float-right user-avatar-dropdown">
      <account-dropdown></account-dropdown>
    </div>
    <!-- <div v-if="!isOpened" class="float-right logo-mobile">
      <a href="#" @click.prevent="$emit('logo-redirect')">
        <img class="ta-c" src="@/assets/images/logo.png">
      </a>
    </div> -->
  </el-header>
</template>
<script>
import { mapGetters } from 'vuex'
import AccountDropdown from '../account-dropdown'
import permissionMixin from '@/mixins/permissions'

export default {
  name: 'header-main',
  components: {
    AccountDropdown
  },
  mixins: [ permissionMixin ],
  computed: {
    ...mapGetters({
      sidebarClose: 'layout/isSidebarClose',
      sidebarStatic: 'layout/isSidebarStatic'
    }),
    isOpened () {
      return !this.sidebarClose()
    }
  },
  methods: {
    switchSidebar () {
      this.$store.dispatch('layout/switchSidebar')
    }
  }
}
</script>

<template>
  <el-row :gutter="24">
    <el-col :span="24">
      <div
        class="float-right"
      >
        <el-button
          v-if="canEditList || isSecurity || isAdvancedSecurity"
          type="info"
          class="icon-btn"
          size="small"
          @click.prevent="showForm=true"
        >
          <i class="fa fa-user-plus fa-lg"></i>
        </el-button>
        <el-button
          v-if="canEditList"
          type="info"
          class="icon-btn"
          size="small"
          @click.prevent="setEditMode(!editMode)"
          data-cy-label="edit-list-button"
        >
          <i class="fa fa-pencil fa-lg"></i>
        </el-button>
      </div>
    </el-col>
    <el-col :span="24">
      <p
        class="h-two text-center"
        data-cy-label="waitlist-title"
      >
        Bookings <span v-if="isSecurity || isAdvancedSecurity">| {{currentDate}}</span>
      </p>
      <p class="text-center">{{store.name}}</p>
    </el-col>
    <customer-add-form
      :show_form="showForm"
      :defaultCountry="store.country"
      :store="store"
      :date-picker="hasDatePicker"
      :customer="customer"
      @on-create-customer="onCustomerCreate"
      @on-close-dialog="showForm=false"
    ></customer-add-form>
  </el-row>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import customerAddForm from '@/views/admin/global/customer-add-form'
import permissionMixin from '@/mixins/permissions'
import moment from 'moment-timezone'
export default {
  name: 'waitlist.header',
  mixins: [permissionMixin],
  data () {
    return {
      showForm: false,
      customer: {
        first_name: '',
        last_name: ''
      }
    }
  },
  computed: {
    ...mapState({
      editMode: state => state.waitlist.editMode,
      store: state => state.waitlist.store
    }),
    currentDate () {
      return moment.tz(new Date(), this.store.timezone).format('MM/D/YYYY')
    },
    hasDatePicker () {
      return !(this.isSecurity)
    }
  },
  mounted () {
    if (this.isSecurity || this.isAdvancedSecurity) {
      this.customer.booking_date = new Date()
    }
  },
  components: {
    customerAddForm
  },
  methods: {
    ...mapActions('waitlist', [
      'setEditMode'
    ]),
    onCustomerCreate () {
      this.$emit('on-create-customer')
    }
  }
}
</script>

import Vue from 'vue'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import moment from 'moment-timezone'
import VueMoment from 'vue-moment'

import * as VeeValidate from 'vee-validate'
import { Plugin } from 'vue-fragment'
import ToggleButton from 'vue-js-toggle-button'
import Sortable from 'vue-sortable'
import Storage from 'vue-web-storage'
import VuePapaParse from 'vue-papa-parse'
import VuePhoneNumberInput from 'vue-phone-number-input'
import vueNumeralFilterInstaller from 'vue-numeral-filter'

import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import './styles/main.scss'
import App from './App.vue'
import router from './router'
import store from './store'
import './filters'
import './permissions'
import i18n from './i18n'
import { timezoneStamps } from './consts'

Vue.config.productionTip = false
Vue.use(ElementUI, { locale })
Vue.use(Plugin)
Vue.use(ToggleButton)
Vue.use(Sortable)
Vue.use(Storage)
Vue.use(VuePapaParse)
Vue.use(vueNumeralFilterInstaller)
Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.use(VeeValidate, {
  errorBagName: 'errors',
  events: 'blur',
  fieldsBagName: 'fields-bag'
})

VeeValidate.Validator.extend('verify_password', {
  getMessage: field => 'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character.',
  validate: value => {
    var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@$^&*(),.?:{}|<>])(?=.{8,})')
    return strongRegex.test(value)
  }
})

moment.tz.add(timezoneStamps.pst)
moment.tz.add(timezoneStamps.gmt)

Vue.use(VueMoment, moment)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="auth-signup">
    <div class="logo">
      <img src="@/assets/images/logo.png">
    </div>
    <el-form
      status-icon
      class="auth-form"
      @submit.prevent.native="signUp"
    >
      <h5 class="text-center">{{$t('auth.create-account')}}</h5>
      <el-form-item
        :show-message="errors.has('first name')"
        :error="errors.first('first name')"
      >
        <el-input
          placeholder="First name"
          v-model="user.first_name"
          data-vv-name="first name"
          v-validate="'required|alpha'"
          @keyup.enter.native="signUp"
        ></el-input>
      </el-form-item>
      <el-form-item
        :show-message="errors.has('last name')"
        :error="errors.first('last name')"
      >
        <el-input
          placeholder="Last name"
          v-model="user.last_name"
          data-vv-name="last name"
          v-validate="'required|alpha'"
          @keyup.enter.native="signUp"
        ></el-input>
      </el-form-item>
      <el-form-item
      >
        <el-input
          placeholder="Phone number"
          type="phone"
          v-model="user.phone"
          @keyup.enter.native="signUp"
        ></el-input>
      </el-form-item>
      <el-form-item
        :show-message="errors.has('email')"
        :error="errors.first('email')"
      >
        <el-input
          placeholder="Email"
          type="email"
          v-model="user.email"
          autocomplete="off"
          data-vv-name="email"
          v-validate="'required|email'"
          @keyup.enter.native="signUp"
        ></el-input>
      </el-form-item>
      <el-form-item
        :show-message="errors.has('password')"
        :error="errors.first('password')"
      >
        <el-input
          placeholder="Password"
          type="password"
          v-model="user.password"
          autocomplete="off"
          data-vv-name="password"
          v-validate="'required|min:8|verify_password'"
          @keyup.enter.native="signUp"
          ref="password"
        ></el-input>
      </el-form-item>
      <el-form-item
        :show-message="errors.has('repeat password')"
        :error="errors.first('repeat password')"
      >
        <el-input
          placeholder="Repeat password"
          type="password"
          v-model="user.password_confirmation"
          autocomplete="off"
          data-vv-name="repeat password"
          v-validate="'required|confirmed:password'"
          @keyup.enter.native="signUp"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          @click.prevent="signUp"
          class="full-width"
        >Submit</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          type="info"
          @click.prevent="cancel"
          class="full-width"
          plain
        >
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'signup',
  data () {
    return {
      loading: false,
      ref: this.$route.query.ref,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        role: 'read_only'
      }
    }
  },
  created () {
    if (this.ref) {
      this.loadInvite(this.ref)
    }
    console.log(this)
  },
  methods: {
    loadInvite (inviteToken) {
      this.$store.dispatch('invite/loadByToken', inviteToken)
        .then(res => {
          if (res) {
            this.user = res
          }
        })
    },
    signUp () {
      this.$validator.validateAll()
        .then(res => {
          if (res) {
            this.loading = true
            this.$store.dispatch('invite/accept', this.user)
              .then(() => {
                this.$message({
                  message: this.$t('auth.account-created'),
                  type: 'success'
                })
                this.$router.push({ name: 'auth.signin' })
              }).catch(() => {
                this.loading = false
              })
          }
        })
    },
    cancel () {
      this.$router.push({ name: 'auth.signin' })
    }
  }
}
</script>

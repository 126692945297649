import {
  show,
  getCustomers,
  addCustomer,
  removeWaitlistCustomer,
  clear
} from '@/api/waitlist'
import moment from 'moment-timezone'

const waitlist = {
  namespaced: true,
  state: {
    customers: [],
    totals: 0,
    expectedTotals: 0,
    checkedIns: 0,
    entereds: 0,
    store: {},
    loading: false,
    customersLoading: false,
    meta: {
      movements: [],
      selects: []
    },
    editMode: false,
    filter: {
      search_text: '',
      limit: 25,
      selected: '1',
      id: 0,
      page: 1,
      selected_date: ''
    },
    allPossibleDates: []
  },
  actions: {
    setLoading ({ commit }, flag = true) {
      commit('SET_LOADING', flag)
      return Promise.resolve()
    },
    setCustomersLoading ({ commit }, flag = true) {
      commit('SET_CUSTOMERS_LOADING', flag)
      return Promise.resolve()
    },
    setEditMode ({ commit }, flag = false) {
      commit('SET_EDIT_MODE', flag)
      return Promise.resolve()
    },
    updateMeta ({ commit }, data) {
      commit('UPDATE_META', data)
      return Promise.resolve()
    },
    resetMeta ({ commit }) {
      commit('RESET_META')
      return Promise.resolve()
    },
    setFilterOption ({ commit }, data) {
      commit('SET_FILTER_OPTION', data)
      return Promise.resolve(data)
    },
    show ({ commit }, storeId) {
      return new Promise((resolve, reject) => {
        show(storeId)
          .then(res => {
            commit('SET_WAITLIST', res.data)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    clear ({ commit }, data) {
      return new Promise((resolve, reject) => {
        clear(data)
          .then(res => {
            commit('CLEAR_WAITLIST', res.data)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getCustomers ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        getCustomers(getters.getFilterRequestBody)
          .then(({ data = [] } = {}) => {
            commit('SET_CUSTOMERS', data)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    addCustomer ({ commit }, data) {
      return new Promise((resolve, reject) => {
        addCustomer(data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    removeWaitlistCustomer ({ commit }, data) {
      return new Promise((resolve, reject) => {
        removeWaitlistCustomer(data)
          .then(res => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateMetric ({ commit }, data) {
      commit('UPDATE_METRIC', data)
      return Promise.resolve()
    }
  },
  mutations: {
    SET_LOADING: (state, data) => {
      state.loading = data
    },
    SET_CUSTOMERS_LOADING: (state, data) => {
      state.customersLoading = data
    },
    SET_EDIT_MODE: (state, data) => {
      state.editMode = data
    },
    UPDATE_META: (state, data) => {
      state.meta = Object.assign({}, state.meta, data)
    },
    RESET_META: (state, data) => {
      state.meta = {
        movements: [],
        selects: []
      }
    },
    SET_FILTER_OPTION: (state, data) => {
      state.filter[data.option] = data.value
    },
    SET_WAITLIST: (state, data) => {
      state.store = data.store
      state.filter.id = data.store.id
      state.checkedIns = data.checked_in_counts
      state.expectedTotals = data.expected_totals
      state.entereds = data.entered_counts
    },
    SET_CUSTOMERS: (state, { totals = 0, customers = [] }) => {
      state.customers = customers
      state.totals = totals
      state.customersLoading = false
      state.allPossibleDates = Array.from({ length: 7 }, (v, i) => ({
        value: moment(new Date())
          .add(i, 'days')
          .format('YYYY-MM-DD'),
        label: moment(new Date())
          .add(i, 'days')
          .format('ddd M/DD')
      }))
    },
    CLEAR_WAITLIST: (state, data) => {
      state.checkedIns = data.checked_in_counts
      state.expectedTotals = data.expected_totals
      state.entereds = data.entered_counts
      state.customers = []
      state.totals = 0
    },
    UPDATE_METRIC: (state, data) => {
      if (typeof data.checked_in_counts === 'number') {
        state.checkedIns = data.checked_in_counts
      }

      if (typeof data.entered_counts === 'number') {
        state.entereds = data.entered_counts
      }
    }
  },
  getters: {
    getFilterRequestBody (state) {
      const { ...filter } = state.filter
      return {
        ...filter
      }
    },
    getExpected (state) {
      return state.expectedTotals
    },
    getEntereds (state) {
      return state.entereds
    },
    getCheckins (state) {
      return state.checkedIns
    }
  }
}

export default waitlist

<template>
  <table class="el-table-table">
    <thead class="el-table__header" :data-cy-selected="filter.selected === '1' ? 'true' : 'false'">
      <tr>
        <th style="min-width: 5px;">&nbsp;</th>
        <th style="min-width: 50px;">#</th>
        <th style="min-width: 60px;">Name</th>
        <th style="min-width: 130px;">Phone Number</th>
        <th style="width: 185px;" class="text-right pr-ten">Status</th>
        <th style="min-width: 20px;" v-if="editMode"></th>
      </tr>
    </thead>
    <template
      v-for="(timeslot, index) in customersByTimeslot"
    >
      <draggable
        :key="index"
        tag="tbody"
        v-if="editMode"
        v-model="timeslot.customers"
        handle=".handle"
        class="el-table__body"
        v-bind="dragOptions"
        group="people"
        :move="onDragMove"
        @change="onDragDrop">
        <tr
          slot="header"
          role="group"
          class="el-table__row timeslot-row"
          v-if="!filter.searchText">
          <timeslot-groups-header
            :timeslot="timeslot"
            :weekday="timeslot.weekday"
            :showTimeField="true"
            :isSelectedByTimeSlot="timeSlotSelected(timeslot)"
            @timeslotSelection="handleTimeslotSelection"
          />
        </tr>
        <tr
          class="el-table__row"
          v-for="(waitlist, key) in timeslot.customers"
          :key="key">
          <timeslot-groups-edit-row
            :key="key"
            :customers="customers"
            :waitlist="waitlist"
            :timeslot="timeslot"
            :notPermittedWaitlist="notPermittedWaitlist"
            :timeslotIndex="index"
            @customerSelected="handleCustomerSelection"
            @refreshCustomers="closeAndRefreshCustomers"
          />
        </tr>
      </draggable>
      <tbody
        class="el-table__body"
        :key="index"
        v-if="!editMode"
      >
        <tr class="el-table__row timeslot-row">
          <timeslot-groups-header
            :timeslot="timeslot"
            :weekday="timeslot.weekday"
            :normalizeTime="normalizeTime"
            :isSelectedByTimeSlot="timeSlotSelected(timeslot)"
            @timeslotSelection="handleTimeslotSelection"
          />
        </tr>
        <template v-for="(waitlist, key) in timeslot.customers">
          <timeslot-groups-static-row
            :key="key"
            :editMode="editMode"
            :waitlist="waitlist"
            :notPermittedWaitlist="notPermittedWaitlist"
            @expandCustomer="expandCustomer"
            @refreshCustomers="closeAndRefreshCustomers"
           />
           <tr
            :key="`${waitlist.id}--expanded`"
            v-if="expandedCustomer === waitlist.customer.id && !editMode"
           >
             <td colspan="7" class="el-table__expanded-cell">
               <customer-form
                  :customer="waitlist.customer"
                  :waitlist="waitlist"
                  @cancel="expandedCustomer = -1"
                  :hideLabelForm="true"
                  @customerRemoved="closeAndRefreshCustomers"
                />
             </td>
           </tr>
        </template>
      </tbody>
    </template>
  </table>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import helperMixin from '@/mixins/listHelpers'
import calcMixin from '@/mixins/calc'
import permissionMixin from '@/mixins/permissions'
import draggable from 'vuedraggable'
import TimeslotGroupsHeader from '@/views/admin/lists/timeslot-groups-header'
import CustomerForm from '@/views/admin/global/customer-form'
import TimeslotGroupsStaticRow from './timeslot-groups-static-row'
import TimeslotGroupsEditRow from './timeslot-groups-edit-row'
export default {
  name: 'waitlist.timeslot-groups',
  components: {
    CustomerForm,
    TimeslotGroupsHeader,
    TimeslotGroupsStaticRow,
    TimeslotGroupsEditRow,
    draggable
  },
  data () {
    return {
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      customers: [],
      expandedCustomer: -1
    }
  },
  watch: {
    vuexCustomers (customers) {
      this.refreshLocalCustomers(customers)
    }
  },
  mixins: [helperMixin, calcMixin, permissionMixin],
  computed: {
    ...mapState({
      editMode: state => state.waitlist.editMode,
      store: state => state.waitlist.store,
      meta: state => state.waitlist.meta,
      vuexCustomers: state => state.waitlist.customers,
      filter: state => state.waitlist.filter
    }),
    normalizeTime () {
      return !this.isEuStore(this.store.country)
    },
    notPermittedWaitlist () {
      return this.hasRestrictedStores && !this.current_user.stores.includes(this.storeId)
    },
    storeId () {
      return parseInt(this.$route.params.waitlist_id, 10)
    }
  },
  created () {
    this.timer = setInterval(() => this.refreshCustomers(), 10000)
  },
  mounted () {
    this.refreshLocalCustomers(this.vuexCustomers)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions('waitlist', [
      'show',
      'getCustomers',
      'updateMeta'
    ]),
    refreshLocalCustomers (customers) {
      this.customers = customers.slice(0).map(customer => {
        return {
          ...customer
        }
      })
    },
    closeAndRefreshCustomers () {
      this.refreshCustomers()
    },
    refreshCustomers () {
      this.$nextTick(() => {
        this.getCustomers()
      })
    },
    handleCustomerSelection (id) {
      const selects = (this.meta.selects || []).slice(0)
      if (~selects.indexOf(id)) {
        selects.splice(selects.indexOf(id), 1)
      } else {
        selects.push(id)
      }
      this.updateMeta({
        selects
      })
    },
    handleTimeslotSelection (timeslot) {
      let customers = timeslot.customers.map(slot => slot.customer.id)
      let meta = this.selectedByTimeslot(timeslot.timeslot)
      let selects = this.meta.selects.filter(id => !customers.includes(id))
      if (meta.length < customers.length) {
        selects = [...customers, ...selects]
      }

      this.updateMeta({
        selects
      })
    },
    expandCustomer (customerId) {
      this.expandedCustomer = this.expandedCustomer === customerId ? -1 : customerId
    },
    timeSlotSelected (timeslot) {
      return this.selectedByTimeslot(timeslot.timeslot).length === timeslot.customers.length && timeslot.customers.length > 0
    },
    selectedByTimeslot (timeslot) {
      let customers = this.customers
        .slice(0)
        .filter(customer => customer.timeslot === timeslot)
        .map(slot => slot.customer.id)
      let meta = this.meta.selects.filter(id => customers.includes(id))
      return meta
    }
  }
}
</script>

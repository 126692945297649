import {
  show,
  update,
  getCustomers,
  addCustomer,
  removeCustomer,
  download,
  checkDownloadQueues,
  complete,
  sendNotification,
  loadAudits,
  generateSignupTestKeys,
  getSignupTestKeys
} from '@/api/list'

import moment from 'moment'
import { listDefaultFilter } from '@/consts'

const list = {
  namespaced: true,
  state: {
    editMode: false,
    isLoading: false,
    meta: {
      movements: [],
      selects: []
    },
    filter: listDefaultFilter(),
    list: {
      store: {},
      release: {},
      status: 0,
      stores: []
    },
    customers: {
      customers: [],
      labels: [],
      totals: 0,
      expected: 0,
      checkin: 0,
      entered: 0,
      late: 0,
      no_show: 0
    },
    testKeys: []
  },
  actions: {
    setEditMode ({ commit }, data = true) {
      commit('SET_EDIT_MODE', data)
      return Promise.resolve(data)
    },
    updateMeta ({ commit }, data) {
      commit('UPDATE_META', data)
      return Promise.resolve()
    },
    resetMeta ({ commit }) {
      commit('RESET_META')
      return Promise.resolve()
    },
    setLoading ({ commit }, data = true) {
      commit('SET_LOADING', data)
      return Promise.resolve(data)
    },
    setFilterOption ({ commit }, data) {
      commit('SET_FILTER_OPTION', data)
      if (data.option === 'searchText') {
        commit('SET_FILTER_OPTION', {
          option: 'spanIndex',
          value: 'all'
        })
      }
      return Promise.resolve(data)
    },
    resetFilter ({ commit }, data) {
      commit('RESET_FILTER', data)
      return Promise.resolve()
    },
    show ({ commit }, id) {
      return new Promise((resolve, reject) => {
        show(id).then(res => {
          commit('SET_LIST', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    update ({ commit }, data) {
      return new Promise((resolve, reject) => {
        update(data).then(res => {
          commit('SET_LIST', res.data)
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getCustomers ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        getCustomers(getters.getFilterRequestBody).then(res => {
          commit('SET_CUSTOMERS', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    addCustomer ({ commit }, data) {
      return new Promise((resolve, reject) => {
        addCustomer(data).then(res => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    removeCustomer ({ commit }, data) {
      return new Promise((resolve, reject) => {
        removeCustomer(data).then(res => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    download ({ commit }, id) {
      return new Promise((resolve, reject) => {
        download(id).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    checkDownloadQueues ({ commit }, data) {
      return new Promise((resolve, reject) => {
        checkDownloadQueues(data).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    complete ({ commit }, id) {
      return new Promise((resolve, reject) => {
        complete(id).then(res => {
          commit('SET_LIST', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    sendNotification ({ commit }, data) {
      return new Promise((resolve, reject) => {
        sendNotification(data).then(res => {
          commit('SET_LIST', res.data)
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    loadAudits ({ commit }, listId) {
      return new Promise((resolve, reject) => {
        loadAudits(listId).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    async generateSignupTestKeys ({ commit, dispatch }, { id = '', uuid = '' }) {
      try {
        await generateSignupTestKeys(id)
        dispatch('getSignupTestKeys', { uuid, id })
      } catch ({ message = '', reason = '' }) {
        console.error({ message, reason })
      }
    },
    async getSignupTestKeys ({ commit, state }, { uuid, id }) {
      try {
        const { data: { keys = '' } = {} } = await getSignupTestKeys({ uuid, id }) || {}
        const listKeys = JSON.parse(keys) || {}
        commit('SET_TEST_KEYS', { id, ...listKeys })
      } catch ({ message = '', reason = '' }) {
        console.error({ message, reason })
      }
    }
  },
  mutations: {
    SET_EDIT_MODE: (state, data) => {
      state.editMode = data
    },
    UPDATE_META: (state, data) => {
      state.meta = Object.assign({}, state.meta, data)
    },
    RESET_META: (state, data) => {
      state.meta = {
        movements: [],
        selects: []
      }
    },
    SET_LOADING: (state, data) => {
      state.isLoading = data
    },
    SET_FILTER_OPTION: (state, data) => {
      state.filter[data.option] = data.value
    },
    RESET_FILTER: (state, data) => {
      const defaultFilter = listDefaultFilter(state)
      state.filter = { ...defaultFilter, ...data }
    },
    SET_LIST: (state, data) => {
      const releaseDate = moment(data.release.release_date)
      const diffInDays = moment().diff(releaseDate, 'days')

      if (diffInDays < 0) {
        state.filter.spanIndex = 0
      } else if (diffInDays >= 0 && diffInDays < data.time_slot_limits.length) {
        state.filter.spanIndex = diffInDays
      } else {
        state.filter.spanIndex = data.time_slot_limits.length - 1
      }
      state.list = data
    },
    SET_CUSTOMERS: (state, data) => {
      state.customers.customers = data.customers
      state.customers.labels = data.labels
      state.customers.totals = data.totals
      state.customers.expected = data.total_expected
      state.customers.entered = data.entered
      state.customers.checkin = data.checkin
      state.customers.late = data.late
      state.customers.no_show = data.no_show
    },
    SET_TEST_KEYS: (state, { id, ...entry }) => {
      const prevKeys = state.testKeys.filter(({ id: listId = '' }) => listId !== id)
      state.testKeys = [].concat(prevKeys, [{ id, ...entry }])
    }
  },
  getters: {
    getFilterRequestBody (state) {
      const { limit = 100 } = listDefaultFilter(state)
      const { searchText, orderBy, spanIndex, ...filter } = state.filter
      if (limit !== filter?.limit) {
        state.filter.limit = limit
      }

      return {
        ...filter,
        limit,
        search_text: searchText,
        order_by: orderBy,
        span_index: Number(state.filter.selected) === 1 ? spanIndex : null
      }
    },
    getList: (state) => () => {
      return state.list
    },
    getCustomers: (state) => () => {
      return state.customers.customers
    },
    getTotalCustomerCount: (state) => () => {
      return state.customers.totals
    },
    getCustomerLabels: (state) => () => {
      return state.customers.labels
    },
    getExpedited (state) {
      return state.customers.expected
    },
    getEntered (state) {
      return state.customers.entered
    },
    getCheckin (state) {
      return state.customers.checkin
    },
    getLate (state) {
      return state.customers.late
    },
    getNoShow (state) {
      return state.customers.no_show
    }
  }
}

export default list

<template>
  <el-form
    @sumbmit="submit"
    label-position="top"
  >
    <div class="settings-block">
      <el-row :gutter="20">
        <el-col>
          <p class="text-secondary">{{$t('settings.store.auto-include')}}</p>
          <toggle-button
            :value="store.auto_release"
            :sync="true"
            @input="onAutoReleaseChange"
            :labels="toggleAttributes.labels"
            :color="toggleAttributes.colors"
            :font-size="14"
            :height="22"
            :width="60"
            :disabled="!canManageStore"
          ></toggle-button>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col>
          <p class="text-secondary">Display store for release</p>
          <toggle-button
            :value="store.store_display"
            :sync="true"
            @input="onStoreDisplayChange"
            :labels="toggleAttributes.labels"
            :color="toggleAttributes.colors"
            :font-size="14"
            :height="22"
            :width="60"
            :disabled="!canManageStore"
          ></toggle-button>
        </el-col>
      </el-row>
      <h4 class="small-title">Store Information</h4>
      <p class="text-secondary">&nbsp;</p>
      <el-row
        :gutter="20"
      >
        <el-col
          :xl="12"
          :lg="16"
          :md="20"
        >
          <el-row :gutter="20">
            <el-col :sm="12">
              <el-form-item
                label="Store Name"
                :show-message="errors.has('store name')"
                :error="errors.first('store name')"
              >
                <el-input
                  v-model="store.name"
                  :disabled="!canManageStore"
                  placeholder="Enter a store name"
                  data-vv-name="store name"
                  v-validate="'required'"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12">
              <el-form-item
                label="Store Display Name"
                :show-message="errors.has('store display name')"
                :error="errors.first('store display name')"
              >
                <el-input
                  v-model="store.display_name"
                  :disabled="!canManageStore"
                  placeholder="Enter a store display name"
                  data-vv-name="store display name"
                  v-validate="'required'"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col
              :sm="12"
            >
              <el-form-item
                label="Time Zone"
                :show-message="errors.has('timezone')"
                :error="errors.first('timezone')"
              >
                <el-select
                  placeholder="Timszone"
                  class="full-width"
                  data-vv-name="timezone"
                  v-validate="'required'"
                  v-model="store.timezone"
                  :disabled="!canManageStore"
                  filterable
                >
                  <el-option
                    v-for="(timezone, index) in timezones"
                    :key="index"
                    :label="timezone"
                    :value="timezone">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :span="24"
            >
              <el-form-item
                label="Store Address"
                :show-message="errors.has('store address')"
                :error="errors.first('store address')"
              >
                <el-input
                  v-model="store.address"
                  :disabled="!canManageStore"
                  placeholder="Enter an address"
                  data-vv-name="store address"
                  v-validate="'required'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :sm="12">
              <el-form-item
                label="City"
                :show-message="errors.has('city')"
                :error="errors.first('city')"
              >
                <el-input
                  v-model="store.city"
                  :disabled="!canManageStore"
                  placeholder="City"
                  data-vv-name="city"
                  v-validate="'required'"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col
              :sm="12"
            >
              <el-form-item
                label="State"
                :show-message="errors.has('state')"
                :error="errors.first('state')"
              >
                <el-select
                  v-model="store.state"
                  placeholder="State"
                  :disabled="!canManageStore"
                  data-vv-name="state"
                  filterable
                  class="full-width"
                >
                  <el-option
                    v-for="(region, index) in regions"
                    :key="index"
                    :label="region.name"
                    :value="region.shortCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :sm="12">
              <el-form-item
                label="Postal Code"
              >
                <el-input
                  v-model="store.zip_code"
                  :disabled="!canManageStore"
                  placeholder="Postal Code"
                  data-vv-name="postal code"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col
              :sm="12"
            >
              <el-form-item
                label="Country"
                :show-message="errors.has('country')"
                :error="errors.first('country')"
              >
                <el-select
                  v-model="store.country"
                  placeholder="Country"
                  data-vv-name="country"
                  v-validate="'required'"
                  class="full-width"
                  @change="countryChange"
                  :disabled="!canManageStore"
                  filterable
                >
                  <el-option
                    v-for="(country, index) in countries"
                    :key="index"
                    :value="country.countryShortCode"
                    :label="country.countryName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :sm="24">
              <el-form-item
                label="Frontend Google Map Link"
                :show-message="errors.has('store location')"
                :error="errors.first('store location')"
              >
                <el-input
                  v-model="store.store_location"
                  type="url"
                  :disabled="!canManageStore"
                  placeholder="Add full link here"
                  data-vv-name="store location"
                  v-validate="'required|url'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                label="Line Management Login URL"
                :show-message="errors.has('line management login url')"
                :error="errors.first('line management login url')"
              >
                <el-input
                  v-model="store.line_login_url"
                  :disabled="!canManageStore"
                  data-vv-name="line management login url"
                  v-validate="'required'"
                >
                  <template
                    slot="prepend"
                  >
                    <span class="hidden-xs-only">{{this.admin_host}}/login/</span>
                    <span class="hidden-sm-and-up">/login/</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="settings-block">
      <h4 class="small-title">{{$t("settings.store.release-title")}}</h4>
      <p class="text-secondary">{{$t("settings.store.release-description")}}</p>
      <el-row
        :gutter="20"
      >
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label="Total Slot Available"
            :show-message="errors.has('total slot available')"
            :error="errors.first('total slot available')"
          >
            <el-input-number
              v-model="store.total_slot_available"
              :disabled="!canManageStore"
              :controls="false"
              :precisions="0"
              :min="0"
              data-vv-name="total slot available"
              v-validate="'required'"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label="First Time Slot"
            :show-message="errors.has('first time slot')"
            :error="errors.first('first time slot')"
          >
            <sny-time-select
              v-model="store.first_time_slot"
              :value="store.first_time_slot"
              :disabled="!canManageStore"
              :options="{
                start: '00:00',
                step: `00:${store.time_slot_interval}`,
                end: '24:00',
                timezone: store.timezone
              }"
              :useStandardTime="!isEuStore(store.country)"
              placeholder="Select time"
            ></sny-time-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label="Last Time Slot"
            :show-message="errors.has('last time slot')"
            :error="errors.first('last time slot')"
          >
            <sny-time-select
              v-model="store.last_time_slot"
              :value="store.last_time_slot"
              :disabled="!canManageStore"
              :options="{
                start: store.first_time_slot || '00:00',
                step: `00:${store.time_slot_interval}`,
                end: '24:00',
                timezone: store.timezone
              }"
              :useStandardTime="!isEuStore(store.country)"
              placeholder="Select time"
            ></sny-time-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label="Time Slot Intervals"
            :show-message="errors.has('time slot intervals')"
            :error="errors.first('time slot intervals')"
          >
            <el-select
              placeholder="Interval"
              class="full-width"
              data-vv-name="time slot intervals"
              v-validate="'required'"
              v-model="store.time_slot_interval"
              :disabled="!canManageStore"
            >
              <el-option
                v-for="(interval, index) in time_slot_intervals"
                :key="index"
                :label="`${interval} minutes`"
                :value="interval">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label="Release Duration"
          >
            <el-select
              placeholder="1 Day"
              class="full-width"
              v-model="store.release_duration"
              :disabled="!canManageStore"
            >
              <span slot="-">D</span>
              <el-option
                v-for="(duration, index) in reg_durations"
                :key="index"
                :value="duration"
                :label="`${duration} Day`"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <div class="settings-block">
      <h4 class="small-title">{{$t("settings.store.reg-title")}}</h4>
      <p class="text-secondary">{{$t("settings.store.reg-description")}}</p>
      <el-row
        :gutter="20"
      >
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label="Day of the Week"
            :show-message="errors.has('reg_weekday')"
            :error="errors.first('day of the week')"
          >
            <el-select
              v-model="store.reg_weekday"
              :disabled="!canManageStore"
              placeholder="Tuesdays"
              class="full-width"
              data-vv-name="day of the week"
              v-validate="'required'"
            >
              <el-option
                v-for="(weekday, index) in week_days"
                :key="index"
                :label="`${weekday.label}s`"
                :value="weekday.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label=""
            :show-message="errors.has('registration time')"
            :error="errors.first('registration time')"
          >
            <template slot="label">
              Registration Time
              <el-tooltip class="item" placement="top">
                <div slot="content" class="pre-whitespace">{{$t('tooltip.registration_opening')}}</div>
                <i class="el-icon-warning float-right"></i>
              </el-tooltip>
            </template>
            <sny-time-select
              v-model="store.online_reg_opening"
              :value="store.online_reg_opening"
              :disabled="!canManageStore"
              :weekday="store.reg_weekday"
              :options="{
                start: '00:00',
                step: `00:15`,
                end: '24:00',
                timezone: store.timezone
              }"
              :useStandardTime="!isEuStore(store.country)"
              placeholder="Select time"
            ></sny-time-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            :show-message="errors.has('total-registration-maximum')"
            :error="errors.first('total-registration-maximum')"
          >
            <template slot="label">
              Total Registration Maximum
              <el-tooltip class="item" :content="$t('tooltip.registration_maximum')" placement="top">
                <i class="el-icon-warning float-right"></i>
              </el-tooltip>
            </template>
            <el-input-number
              v-model="store.total_reg_limit"
              :disabled="!canManageStore"
              placeholder="1000"
              data-vv-name="total-registration-maximum"
              v-validate="'required'"
              :controls="false"
              :precisions="0"
              :min="0"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label="Close Registration After"
            :show-message="errors.has('close registration after')"
            :error="errors.first('close registration after')"
          >
            <el-select
              v-model="store.close_reg_after"
              :disabled="!canManageStore"
              placeholder="3 minutes"
              class="full-width"
              data-vv-name="close registration after"
              v-validate="'required'"
            >
              <span slot="-">h</span>
              <el-option
                v-for="(limit, index) in reg_after_arr"
                :key="index"
                :value="limit"
                :label="limit === 0 ? 'N/A' : `${limit} minutes`"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20"
        class="time-slot-settings"
      >
        <el-col :lg="6" :sm="12">
          <div class="time-slot-setting">
            <p class="name small">
              1st Round `Selected`
              <el-tooltip class="item" :content="$t('tooltip.first_round_selected')" placement="top">
                <i class="el-icon-warning float-right"></i>
              </el-tooltip>
            </p>
            <el-form-item
              label="Time"
              :error="!store.first_round_time ? `First round time required` : ''"
              :show-message="!store.first_round_time"
            >
              <sny-time-select
                v-model="store.first_round_time"
                :value="store.first_round_time"
                data-vv-name="first round time"
                :weekday="store.reg_weekday"
                :disabled="!canManageStore"
                v-validate="'required'"
                :options="{
                  start: '00:00',
                  step: `00:15`,
                  end: '24:00',
                  timezone: store.timezone
                }"
                :useStandardTime="!isEuStore(store.country)"
                placeholder="Select time"
              ></sny-time-select>
            </el-form-item>
            <el-form-item
              label="Invites to be sent"
              :show-message="errors.has('invites to be sent')"
              :error="errors.first('invites to be sent')"
            >
              <el-input
                data-vv-name="invites to be sent"
                v-model="store.first_round_limit"
                :disabled="!canManageStore"
                v-validate="'required|min_value:10|integer'"
              >
                <template slot="prefix">%</template>
                <template slot="suffix">({{firstRoundLimit}} slots)</template>
              </el-input>
            </el-form-item>
            <el-form-item
              class="mb-7"
            >
              <el-checkbox
                disabled
                v-model="store.first_round_notification"
              >Send Notifications</el-checkbox>
            </el-form-item>
          </div>
        </el-col>
        <el-col :lg="6" :sm="12">
          <div class="time-slot-setting left-bordered sm-border-none">
            <p class="name small">
              2nd Round `Selected`
              <el-tooltip class="item" placement="top">
                <div slot="content" class="pre-whitespace">{{$t('tooltip.second_round_selected')}}</div>
                <i class="el-icon-warning float-right"></i>
              </el-tooltip>
            </p>
            <el-form-item
              label="Time"
              :error="!store.second_round_time ? `Second round time required` : ''"
              :show-message="!store.second_round_time"
            >
              <sny-time-select
                v-model="store.second_round_time"
                :disabled="!canManageStore"
                :value="store.second_round_time"
                data-vv-name="second round time"
                :weekday="store.reg_weekday"
                v-validate="'required'"
                :options="{
                  start: '00:00',
                  step: `00:15`,
                  end: '24:00',
                  timezone: store.timezone
                }"
                :useStandardTime="!isEuStore(store.country)"
                placeholder="Select time"
              ></sny-time-select>
            </el-form-item>
            <el-form-item
              :show-message="errors.has('remaining percent')"
              :error="errors.first('remaining percent')"
            >
              <template slot="label">
                % of remaining time slots
                <el-tooltip class="item" content="Tool tip text" placement="top">
                  <i class="el-icon-warning float-right"></i>
                </el-tooltip>
              </template>
              <el-input
                v-model="store.second_round_percentage"
                :disabled="!canManageStore"
                data-vv-name="remaining percent"
                v-validate="'required|min_value:10|integer'"
              >
                <template slot="prefix">%</template>
                <template slot="suffix">({{secondRoundLimit}} slots)</template>
              </el-input>
            </el-form-item>
            <el-form-item
              class="mb-7"
            >
              <el-checkbox
                v-model="store.second_round_notification"
                :disabled="!canManageStore"
              >Send Notifications</el-checkbox>
            </el-form-item>
          </div>
        </el-col>
        <el-col :lg="6" :sm="12">
          <div class="time-slot-setting left-bordered lg-border-none sm-border-none">
            <p class="name small">
              Not `Selected`
              <el-tooltip class="item" placement="top">
                <div slot="content" class="pre-whitespace">{{$t('tooltip.not_selected')}}</div>
                <i class="el-icon-warning float-right"></i>
              </el-tooltip>
            </p>
            <el-form-item
              label="Time"
              :error="!store.not_selected_notify_time ? `Not selected time required` : ''"
              :show-message="!store.not_selected_notify_time"
            >
              <sny-time-select
                v-model="store.not_selected_notify_time"
                :value="store.not_selected_notify_time"
                :disabled="!canManageStore"
                data-vv-name="not selected time"
                v-validate="'required'"
                :weekday="store.reg_weekday"
                :options="{
                  start: '00:00',
                  step: `00:15`,
                  end: '24:00',
                  timezone: store.timezone
                }"
                :useStandardTime="!isEuStore(store.country)"
                placeholder="Select time"
              ></sny-time-select>
            </el-form-item>
            <el-form-item
              class="hidden-md-and-down"
            >
              <div class="empty-form-item"></div>
            </el-form-item>
            <el-form-item
              class="mb-7"
            >
              <el-checkbox
                v-model="store.not_selected_notification"
                :disabled="!canManageStore"
              >Send Notifications</el-checkbox>
            </el-form-item>
          </div>
        </el-col>
        <el-col :lg="6" :sm="12">
          <div class="time-slot-setting left-bordered sm-border-none">
            <p class="name small">
              Time Slot Details
              <el-tooltip class="item" placement="top">
                <div slot="content" class="pre-whitespace">{{$t('tooltip.timeslot_details')}}</div>
                <i class="el-icon-warning float-right"></i>
              </el-tooltip>
            </p>
            <el-form-item
              :error="!store.time_slot_notify_time ? `Time slot detail required` : ''"
              :show-message="!store.time_slot_notify_time"
            >
              <template slot="label">
                Time
              </template>
              <sny-time-select
                v-model="store.time_slot_notify_time"
                :disabled="!canManageStore"
                :value="store.time_slot_notify_time"
                data-vv-name="time slot detail"
                :weekday="store.reg_weekday + 1"
                v-validate="'required'"
                :options="{
                  start: '00:00',
                  step: `00:15`,
                  end: '24:00',
                  timezone: store.timezone
                }"
                :useStandardTime="!isEuStore(store.country)"
                placeholder="Select time"
              ></sny-time-select>
            </el-form-item>
            <el-form-item
              class="hidden-md-and-down"
            >
              <div class="empty-form-item"></div>
            </el-form-item>
            <el-form-item
              class="mb-7"
            >
              <el-checkbox
                v-model="store.time_slot_notification"
                :disabled="!canManageStore"
              >Send Notifications</el-checkbox>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="settings-block">
      <h4 class="small-title">{{$t("settings.store.waitlist-title")}}</h4>
      <p class="text-secondary">{{$t("settings.store.waitlist-description")}}</p>
      <el-row :gutter="20">
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label="First Time Slot"
            :error="!store.waitlist_first_slot ? `Time slot detail required` : ''"
            :show-message="!store.waitlist_first_slot"
          >
            <sny-time-select
              v-model="store.waitlist_first_slot"
              :disabled="!canManageStore"
              :value="store.waitlist_first_slot"
              data-vv-name="waitlist first slot"
              v-validate="'required'"
              :options="{
                start: '00:00',
                step: `00:15`,
                end: '24:00',
                timezone: store.timezone
              }"
              :useStandardTime="!isEuStore(store.country)"
              placeholder="Select time"
            ></sny-time-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label="Last Time Slot"
          >
            <sny-time-select
              v-model="store.waitlist_last_slot"
              :disabled="!canManageStore"
              :value="store.waitlist_last_slot"
              :options="{
                start: store.waitlist_first_slot || '00:00',
                step: `00:15`,
                end: '24:00',
                timezone: store.timezone
              }"
              :useStandardTime="!isEuStore(store.country)"
              placeholder="Select time"
            ></sny-time-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="5" :lg="6" :md="8" :sm="12"
        >
          <el-form-item
            label="Time Slot Intervals"
            :show-message="errors.has('waitlist slot interval')"
            :error="errors.first('waitlist slot interval')"
          >
            <el-select
              placeholder="Interval"
              class="full-width"
              v-model="store.waitlist_slot_interval"
              :disabled="!canManageStore"
              data-vv-name="waitlist slot interval"
            >
              <el-option
                v-for="(interval, index) in time_slot_intervals"
                :key="index"
                :label="`${interval} minutes`"
                :value="interval">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <el-form-item v-if="canManageStore">
      <el-button
        type="primary"
        class="float-left"
        :loading="saving"
        @click.prevent="submit"
      >Save</el-button>
      <el-button type="info" class="float-right"
        plain
        v-if="store.tab_name !== 'New Store'"
        @click.prevent="remove"
      >Delete this Store</el-button>
      <el-button
        type="info" class="float-right"
        plain
        v-if="store.tab_name === 'New Store' && store_counts() !== 1"
        @click.prevent="$emit('onCancel')"
      >Cancel</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { mapGetters } from 'vuex'
import { timeslotIntervals, weekDays, whitelistCountries, timezones, closeRegAfterArr, toggleAttributes } from '@/consts'
import SnyTimeSelect from '@/views/components/sny-time-select'
import countries from 'country-region-data'
import calcMixins from '@/mixins/calc'
import permissionMixins from '@/mixins/permissions'

export default {
  name: 'store-form',
  props: [ 'store', 'saving' ],
  mixins: [ calcMixins, permissionMixins ],
  data () {
    return {
      toggleAttributes,
      time_slot_intervals: timeslotIntervals,
      week_days: weekDays,
      countries: countries.filter(country => whitelistCountries.includes(country.countryShortCode)),
      reg_after_arr: closeRegAfterArr,
      reg_durations: this.rangeArray(1, 6),
      admin_host: [(process.env.VUE_APP_HOST || '').replace(/\/$/, ''), (process.env.VUE_APP_PREFIX_ADMIN_ROUTE || '')].join('/'),
      timezones: timezones
    }
  },
  components: {
    SnyTimeSelect
  },
  computed: {
    ...mapGetters({
      store_counts: 'store/getStoreCounts'
    }),
    regions () {
      let country = this.countries.find(c => c.countryShortCode === this.store.country)
      if (country) {
        return country.regions
      } else {
        return []
      }
    },
    firstRoundLimit () {
      let limit = this.store.total_slot_available * this.store.first_round_limit / 100
      if (limit < 0) {
        limit = 0
      }
      return Math.round(limit)
    },
    secondRoundLimit () {
      let limit = this.store.total_slot_available * this.store.second_round_percentage / 100
      if (limit < 0) {
        limit = 0
      }
      return Math.round(limit)
    }
  },
  methods: {
    submit () {
      this.$validator.validateAll()
        .then(res => {
          if (res) {
            if (!this.store.first_round_time || !this.store.second_round_time || !this.store.not_selected_notify_time || !this.store.time_slot_notify_time) {
              return false
            }
            this.$emit('on-submit')
          }
        })
    },
    remove () {
      this.$emit('onRemove', this.store.id)
    },
    onAutoReleaseChange (value) {
      this.$emit('on-auto-release', value)
    },
    onStoreDisplayChange (value) {
      this.$emit('on-store-display-change', value)
    },
    countryChange () {
      let region = this.regions.find(r => r.shortCode === this.store.state)
      if (!region) {
        this.$emit('clear-region')
      }
    }
  }
}
</script>

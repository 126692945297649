<template>
  <el-dialog
    :visible.sync="show"
    width="360px"
    class="confirm-module"
    center>
    <p class="module-title text-center">{{title}}</p>
    <p class="module-description text-center">{{description}}</p>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('on-close')" round>Cancel</el-button>
      <el-button type="primary" @click="$emit('on-confirm')" round>Confirm</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'confirm-module',
  props: ['title', 'open', 'description'],
  computed: {
    show: {
      get () {
        return this.open
      },
      set () {
        this.$emit('on-close')
      }
    }
  }
}
</script>

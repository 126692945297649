<template>
  <div class="users-settings">
    <el-row :gutter="20">
      <el-col :sm="18" :xs="24">
        <h2 class="main-title">{{$t('settings.users')}}</h2>
      </el-col>
      <el-col :sm="6" :xs="24">
        <el-input
          placeholder="Search users..."
          size="medium"
          v-model="filter.search_text"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :sm="12" :xs="24" class="add-user-wrapper">
        <el-button
          type="info" icon="el-icon-plus"
          size="medium"
          @click="user_modal = true"
          plain
        >Add User</el-button>
      </el-col>
      <el-col :sm="12" :xs="24">
        <div
          class="display-inline float-right"
        >
          <span
            class="text-secondary"
          >Sort by</span>
          <el-select
            v-model="filter.order_by"
            class="ml-seven"
            size="medium"
            placeholder="Select"
          >
            <el-option label="Created At" value="created_at"></el-option>
            <el-option label="Name A-Z" value="name"></el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-table
          v-loading="loading"
          :data="users"
          style="width: 100%;"
          ref="userstb"
          class="has-border"
          @current-change="expandRow"
        >
          <el-table-column
            label="Name"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.full_name | capitalize }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Permissions"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.role | humanize }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Phone"
            prop="phone">
          </el-table-column>
          <el-table-column
            label="Email"
            prop="email">
          </el-table-column>
          <el-table-column
            type="expand"
            class-name="hide-expand-icon"
          >
            <template slot-scope="props">
              <UserEditForm
                :user="props.row"
                @set-loader="setLoader"
                @onCancel="cancelEdit"
                @onUpdate="refreshUsers"
              />
            </template>
          </el-table-column>
          <template slot="append">
            <el-pagination
              @sizeChange="handlePageSize"
              @current-change="handleCurrentPage"
              :current-page.sync="filter.page"
              :page-sizes="[25, 50, 75, 100]"
              :page-size="filter.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="counts"
              auto-scroll="false"
              hide-on-single-page>
            </el-pagination>
          </template>
        </el-table>
      </el-col>
    </el-row>
    <UserCreateForm
      :user_modal="user_modal"
      :user="user"
      :loading="loading"
      @create-user="createUser"
      @close-modal="user_modal = false"
    />
  </div>
</template>
<script>
import ElPagination from '@/views/components/pagination'
import UserEditForm from './components/user-edit-form'
import UserCreateForm from './components/user-create-form'
import { mapGetters } from 'vuex'

export default {
  name: 'global',
  components: {
    UserEditForm,
    UserCreateForm,
    ElPagination
  },
  data () {
    return {
      loading: true,
      user_modal: false,
      expanded_row: null,
      users: [],
      counts: 0,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        role: null,
        stores: []
      },
      filter: {
        order_by: 'created_at',
        search_text: '',
        page: 1,
        limit: 25
      }
    }
  },
  computed: {
    ...mapGetters({
      getUsers: 'user/getUsers',
      getCounts: 'user/getTotalCount'
    })
  },
  created () {
    this.loadUsers()
  },
  watch: {
    filter: {
      handler () {
        this.refreshUsers()
      },
      deep: true
    }
  },
  methods: {
    loadUsers () {
      this.loading = true
      this.$store.dispatch('user/load')
        .then(() => {
          this.refreshUsers()
        }).catch(() => {
        }).then(() => {
          this.loading = false
        })
    },
    refreshUsers () {
      this.users = this.getUsers(this.filter)
      this.counts = this.getCounts(this.filter)
    },
    setLoader (loading) {
      this.loading = loading
    },
    // create invitation
    createUser () {
      this.loading = true
      this.$store.dispatch('user/invite', this.user)
        .then(() => {
          this.user_modal = false
          this.loading = false
          this.user = {
            first_name: '',
            last_name: '',
            email: '',
            role: null,
            stores: []
          }
          this.$message({
            message: 'Account activation email sent to new user',
            type: 'success'
          })
        }).catch(() => {
          this.loading = false
        })
    },
    cancelEdit (data) {
      this.$store.dispatch('user/revert', data)
        .then(() => {
          this.refreshUsers()
        })
    },
    expandRow (row) {
      this.refreshUsers()
      this.$store.dispatch('user/revert', row)
        .then(() => {
          this.$refs.userstb.toggleRowExpansion(row)
        })
    },
    handleCurrentPage (page) {
      this.filter.page = page
    },
    handlePageSize (size) {
      this.filter.limit = size
    }
  }
}
</script>

<template>
   <el-autocomplete
    class="full-width"
    v-model="snyTime"
    select-when-unmatched
    :fetch-suggestions="querySearch"
    :placeholder="placeholder"
    :disabled="disabled"
    :value="time"
    @select="handleSelect"
    @input="handleSelect"
    @blur="handleBlur"
  >
    <span
      slot="suffix"
      class="flex-wrapper vertical-align-center full-height"
    >
      <span
        v-if="weekday"
        class="mr-five"
      >{{weekdayDisplay}}</span>
      <i
        class="el-icon-time"
      >
      </i>
    </span>

    <template slot-scope="{ item }">
      <div class="value">
        <span v-if="useStandardTime">
          {{ item.value | standard_time }}
        </span>
        <span v-else>
          {{ item.value }}
        </span>
      </div>
    </template>
  </el-autocomplete>
</template>
<script>
import { weekDays } from '@/consts'
export default {
  name: 'sny-time-select',
  props: [ 'options', 'placeholder', 'value', 'disabled', 'useStandardTime', 'weekday' ],
  data () {
    return {
      time: null
    }
  },
  watch: {
    options: {
      handler () {
        this.time = this.time ? `${this.time.split(' ')[0]} ${this.timezone}`.trim() : null
      },
      deep: true
    },
    value: {
      handler () {
        this.time = `${this.value} ${this.timezone}`.trim()
      },
      deep: true
    }
  },
  computed: {
    snyTime: {
      get () {
        if (this.useStandardTime) {
          return `${this.$options.filters.standard_time(this.time)}`
        } else {
          return `${this.time}`
        }
      },
      set () {
        return this.time
      }
    },
    items () {
      const start = this.options.start
      const end = this.options.end
      const step = this.options.step
      const result = []

      if (start && end && step) {
        let current = start
        while (this.compareTime(current, end) <= 0) {
          result.push({
            value: `${current} ${this.timezone}`,
            disabled: this.compareTime(current, this.minTime || '-1:-1') <= 0 ||
              this.compareTime(current, this.maxTime || '100:100') >= 0
          })
          current = this.nextTime(current, step)
        }
      }

      return result
    },
    timezone () {
      return this.options.timezone ? this.$options.filters.abbr_timezone(this.options.timezone) : ''
    },
    weekdayDisplay () {
      const { label = 'x' } = weekDays.find(e => e.value === this.weekday) || {}
      return label
    }
  },
  created () {
    this.time = this.value ? `${this.value.split(' ')[0]} ${this.timezone}`.trim() : null
  },
  methods: {
    compareTime (time1, time2) {
      const value1 = this.parseTime(time1)
      const value2 = this.parseTime(time2)

      const minutes1 = value1.minutes + value1.hours * 60
      const minutes2 = value2.minutes + value2.hours * 60

      if (minutes1 === minutes2) {
        return 0
      }

      return minutes1 > minutes2 ? 1 : -1
    },
    nextTime (time, step) {
      const timeValue = this.parseTime(time)
      const stepValue = this.parseTime(step)

      const next = {
        hours: timeValue.hours,
        minutes: timeValue.minutes
      }

      next.minutes += stepValue.minutes
      next.hours += stepValue.hours

      next.hours += Math.floor(next.minutes / 60)
      next.minutes = next.minutes % 60

      return this.formatTime(next)
    },
    parseTime (time) {
      const values = (time || '').split(':')
      if (values.length >= 2) {
        const hours = parseInt(values[0], 10)
        const minutes = parseInt(values[1], 10)

        return {
          hours,
          minutes
        }
      }
      return null
    },
    formatTime (time) {
      return (time.hours < 10 ? '0' + time.hours : time.hours) + ':' + (time.minutes < 10 ? '0' + time.minutes : time.minutes)
    },
    querySearch (queryString, cb) {
      let items = this.items
      cb(items)
    },
    handleSelect (timeslot) {
      let time = timeslot.value ? timeslot.value.split(' ')[0] : timeslot.split(' ')[0]
      if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)) {
        this.$emit('input', time)
      }
    },
    handleBlur () {
      if (!this.time) {
        this.$emit('input', '')
      }
    }
  }
}
</script>

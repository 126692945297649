<template>
  <div>
    <h2 class="main-title mb-twenty-five">Audit logs</h2>
    <div v-if="loaded">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          :to="{ path: `/admin/seasons/${release.season.id}` }"
        >{{release.season.name}}</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{ path: `/admin/seasons/${release.season.id}/releases/${release.id}` }"
        >{{release.name}} • {{ release.release_date | date_time_string(list.timezone, normalizeTime) }}</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{ path: `/admin/seasons/${release.season.id}/lists/${list.id}/management` }"
        >{{list.name}}</el-breadcrumb-item>
      </el-breadcrumb>

      <el-divider></el-divider>

      <el-timeline>
        <el-timeline-item
          placement="top"
          v-for="(audit, index) in audits"
          :timestamp="$options.filters.date_time_string(audit.created_at, list.timezone, normalizeTime)"
          :key="index"
        >
          <el-card>
            <div slot="header" class="clearfix">
              <span>list {{audit.action}}</span>
              <el-button
                class="float-right" style="padding: 3px 0" type="text"
                @click.prevent="toggleRequest(audit.id)"
              >
                <span v-if="audit.id === viewRequest">Hide request</span>
                <span v-else>View request</span>
              </el-button>
            </div>
            <p>{{audit.user_name | capitalize}}</p>
            <p>{{audit.remote_address}}</p>

            <el-collapse-transition>
              <div
                class="audit-change"
                v-if="audit.id === viewRequest"
              >
                <vue-json-pretty :data="audit.audited_changes"></vue-json-pretty>
              </div>
            </el-collapse-transition>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import calcMixin from '@/mixins/calc'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: 'audits',
  mixins: [ calcMixin ],
  components: {
    VueJsonPretty
  },
  data () {
    return {
      loaded: false,
      viewRequest: false,
      list: {},
      release: {},
      audits: []
    }
  },
  computed: {
    listId () {
      return this.$route.params.list_id
    },
    normalizeTime () {
      return this.isEuStore(this.list.country)
    }
  },
  mounted () {
    this.loadAudits(this.listId)
      .then(res => {
        this.list = res.list
        this.release = res.release
        this.audits = res.audits
        this.loaded = true
      })
  },
  methods: {
    ...mapActions('list', [
      'loadAudits'
    ]),
    toggleRequest (auditId) {
      if (this.viewRequest) {
        this.viewRequest = false
      } else {
        this.viewRequest = auditId
      }
    }
  }
}
</script>

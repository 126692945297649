<template>
  <el-card shadow="never">
    <template slot="header">
      <div
        class="heading"
      >
        <span class="title">{{list.store.name}}</span>
        <RegistrationStatus
          :status="status"
          :is_physical="list.is_physical_signup"
        />
        <transition name="el-fade-in">
          <el-tag
            class="round ml-seven"
            type="warning"
            effect="dark"
            size="mini"
            v-show="list.is_physical_signup"
          >
            Physical Signup
            <span v-if="isAutomaticFlow">(A)</span>
            <span v-else>(M)</span>
          </el-tag>
        </transition>
      </div>
      <div
        class="actions"
        :data-status="list.status"
        :data-list="list.id"
      >
        <el-tooltip content="View audit log" placement="top">
          <el-button
            size="small"
            plain
            icon="el-icon-share"
            @click.prevent="viewAudit"
          ></el-button>
        </el-tooltip>
        <el-button
          size="small"
          type="danger"
          v-if="isOpen && !list.is_physical_signup"
          @click.prevent="closeRegistration"
        >Close Registration</el-button>
        <el-button
          size="small"
          v-if="basicSetting && hasChanged || updatedTimeslots"
          @click.prevent="onCancel()"
        >Cancel</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="saving"
          v-if="basicSetting && hasChanged || updatedTimeslots"
          @click.prevent="submit"
        >Save</el-button>
        <el-button
          size="small"
          type="info"
          @click.prevent="testSignup"
          v-if="list.status === 2"
        >Test Signup</el-button>
        <el-button
          size="small"
          type="info"
          @click.prevent="manageList"
          v-if="list.status !== 1"
        >View List</el-button>
        <el-button
          size="small"
          type="info"
          icon="el-icon-s-tools"
          @click.prevent="toggleBasicSetting"
        ></el-button>
      </div>
    </template>
    <el-collapse-transition>
      <div class="el-card__section" v-if="list.is_included">
        <ListSummary :list="list" />
      </div>
    </el-collapse-transition>
    <el-form
      label-position="top"
    >
      <el-collapse-transition>
        <div
          class="el-card__section"
          v-if="basicSetting"
        >
          <el-row :gutter="20">
            <el-col>
              <p class="h-two mt-zero small float-left">Settings</p>
              <el-button
                size="small"
                type="info"
                icon="el-icon-s-tools"
                class="float-right"
                v-if="list.is_included"
                @click.prevent="basicSetting = !basicSetting"
              >
                <span v-if="basicSetting">Hide Advanced Settings</span>
                <span v-else>Show Advanced Settings</span>
              </el-button>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            class="mb-fifteen"
          >
            <el-col :md="6">
              <p>Include store for this release</p>
              <toggle-button
                v-model="list.is_included"
                data-vv-name="is-included"
                :disabled="list.status >= listStatusObj.open || notPermittedStore"
                :labels="toggleAttributes.labels"
                :color="toggleAttributes.colors"
                :font-size="14"
                :height="22"
                :width="60"
              />
            </el-col>
            <el-col :md="6" v-if="list.is_included">
              <p>This is a physical Sign Up</p>
              <toggle-button
                v-model="list.is_physical_signup"
                data-vv-name="is-physical-signup"
                :labels="toggleAttributes.labels"
                :color="toggleAttributes.colors"
                :disabled="!list.physical_switchable || notPermittedStore"
                :font-size="14"
                :height="22"
                :width="60"
              />
            </el-col>
            <el-col :md="6" v-if="list.is_included">
              <p>Display store for release</p>
              <toggle-button
                v-model="list.store_display"
                :labels="toggleAttributes.labels"
                :color="toggleAttributes.colors"
                :disabled="list.status >= listStatusObj.open || notPermittedStore"
                :font-size="14"
                :height="22"
                :width="60"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col>
              <p class="name">Registration Details</p>
            </el-col>
          </el-row>
          <list-detail-settings
            :list="list"
            :editable="editable"
            :meta="meta"
            :hasChangedTimeslots="hasChangedTimeslots"
            v-if="list.is_included"
          ></list-detail-settings>
          <el-row :gutter="20"
            v-if="basicSetting && hasChanged || updatedTimeslots"
          >
            <el-col
              class="text-right"
              data-cy-tag="actions"
              :data-list="list.id"
              :data-status="list.status"
            >
              <el-button
                size="medium"
                @click.prevent="onCancel()"
              >Cancel</el-button>
              <el-button
                type="primary"
                size="medium"
                :loading="saving"
                @click.prevent="submit"
              >Save</el-button>
            </el-col>
          </el-row>
        </div>
      </el-collapse-transition>
    </el-form>
  </el-card>
</template>
<script>
import Vue from 'vue'
import RegistrationStatus from '@/views/admin/releases/components/registration-status'
import ListSummary from '@/views/admin/releases/components/list-summary'
import { redistributeList } from '@/api/list'

import listStates from '@/mixins/listStates.js'
import calcMixins from '@/mixins/calc'
import permissionMixins from '@/mixins/permissions'
import { listStatusObj, toggleAttributes } from '@/consts'
import ListDetailSettings from './list-detail-settings'
import isEqual from 'lodash.isequal'

export default {
  name: 'register-shop-slot',
  props: ['list'],
  components: {
    RegistrationStatus,
    ListSummary,
    ListDetailSettings
  },
  mixins: [
    listStates,
    calcMixins,
    permissionMixins
  ],
  data () {
    return {
      toggleAttributes,
      saving: false,
      basicSetting: false,
      showCloseReg: false,
      listStatusObj: listStatusObj,
      reg_durations: this.rangeArray(1, 6),
      status: 0,
      meta: {},
      updatedTimeslots: false
    }
  },
  created () {
    this.meta = Vue.util.extend({}, this.list)
    this.status = this.list.status
  },
  computed: {
    season_id () {
      return this.$route.params.season_id
    },
    editable () {
      return this.list.is_included && this.list.status < this.listStatusObj.open
    },
    hasChanged () {
      let meta = this.meta
      let list = Object.assign({}, this.list)
      delete meta.registration_open_date
      delete list.registration_open_date
      return !isEqual(meta, list)
    }
  },
  watch: {
    'list.is_included': {
      handler () {
        if (this.list.is_included) {
          this.status = 2
        } else {
          this.status = 1
        }
      }
    },
    'list.status': {
      handler () {
        this.status = this.list.status
      }
    }
  },
  methods: {
    submit () {
      this.$validator.validateAll()
        .then(res => {
          if (res) {
            if (!this.list.first_round_time || !this.list.second_round_time || !this.list.not_selected_notify_time || !this.list.time_slot_notify_time) {
              return false
            }
            this.saving = true
            this.$store.dispatch('list/update', this.list)
              .then(list => {
                this.meta = list
              })
              .then(() => {
                if (this.list.status === 8) {
                  return this.list.time_slot_limits.map((d, idx) =>
                    redistributeList(this.list.id, { day: idx })
                  )
                }
              })
              .then(() => {
                let message = this.$t('activity.updated', { name: 'list' })
                this.$message({
                  message: message,
                  type: 'success'
                })
                this.$emit('on-list-update', this.meta)
              })
              .catch((err) => {
                console.error('error', err)
              }).finally(() => {
                this.saving = false
              })
          }
        })
    },
    toggleBasicSetting () {
      this.basicSetting = !this.basicSetting
    },
    closeRegistration () {
      this.$msgbox({
        title: this.$t('releases.close'),
        message: this.$t('activity.confirm.description'),
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        center: true,
        roundButton: true,
        confirmButtonClass: 'el-button--danger',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$store.dispatch('list/update', { ...this.list, status: 4 }).then(list => {
              this.$emit('on-list-update', list)
            }).catch(() => {
            }).then(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      })
    },
    testSignup () {
      this.$store.dispatch('list/generateSignupTestKeys', { ...this.list })
    },
    manageList () {
      this.$router.push({
        name: 'list.management',
        params: {
          list_id: this.list.id,
          season_id: this.season_id
        }
      })
    },
    onCancel () {
      this.basicSetting = false
      this.$emit('onCancel', this.meta)
    },
    viewAudit () {
      this.$router.push({
        name: 'audits',
        params: {
          list_id: this.list.id
        }
      })
    },
    hasChangedTimeslots () {
      this.updatedTimeslots = true
    }
  }
}
</script>

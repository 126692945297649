<template>
  <el-row :gutter="24">
    <el-col :span="24"
      class="text-right"
      v-if="!isReadOnly && !notPermittedStore">
      <transition name="el-fade-in">
        <el-button
          type="primary"
          size="small"
          v-if="isInProgress && editMode && !isAdvancedSecurity"
          @click.prevent="confirmBeforeCompletion"
          data-cy-label="close-checkin-button">
          Close Check-in
        </el-button>
      </transition>
      <el-button
        type="info"
        class="icon-btn"
        size="small"
        @click.prevent="$emit('showAddCustomer')"
        v-if="canEditList && canManageCustomer"
        :disabled="isReleaseComplete"
        data-cy-label="add-customer-button">
        <i class="fa fa-user-plus fa-lg"></i>
      </el-button>

      <el-button
        v-if="isSecurity"
        type="info"
        class="icon-btn"
        size="small"
        @click.prevent="$emit('showAddCustomer')"
        :disabled="isReleaseComplete"
      >
        <i class="fa fa-user-plus fa-lg"></i>
      </el-button>

      <el-button
        type="info"
        class="icon-btn"
        size="small"
        :disabled="isReleaseComplete"
         v-if="canEditList"
        @click.prevent="setEditMode(!editMode)"
        data-cy-label="edit-list-button">
        <i class="fa fa-pencil fa-lg"></i>
      </el-button>
    </el-col>
    <el-col :span="24">
      <p
        class="h-two text-center"
        data-cy-label="list-title">
        {{list.release.name}} |
        {{list.release.release_date | moment('MM/D/YYYY')}}
      </p>
      <p class="text-center">{{list.store.name}}</p>
      <p class="text-center"
        v-if="isPhysicalSignup">
        <el-tag
          class="round mr-five"
          type="warning"
          effect="dark"
          size="mini">
          Physical Signup
          <span v-if="isAutomaticFlow">(A)</span>
          <span v-else>(M)</span>
        </el-tag>
      </p>
      <p class="text-center">
        <RegistrationStatus
          :status="list.status"
        />
      </p>
    </el-col>
  </el-row>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import listStates from '@/mixins/listStates'
import permissionMixin from '@/mixins/permissions'
import RegistrationStatus from '@/views/admin/releases/components/registration-status'
export default {
  name: 'pre-release-header',
  mixins: [ listStates, permissionMixin ],
  components: {
    RegistrationStatus
  },
  data () {
    return {
      searchText: ''
    }
  },
  watch: {
    searchText (value) {
      this.$emit('filterList', value)
    }
  },
  computed: {
    ...mapState({
      editMode: state => state.list.editMode,
      list: state => state.list.list
    })
  },
  methods: {
    ...mapActions('list', [
      'setEditMode',
      'complete'
    ]),
    confirmBeforeCompletion () {
      this.$msgbox({
        title: this.$t('list.complete'),
        message: this.$t('activity.confirm.description'),
        showCancelButton: true,
        confirmButtonText: 'Complete',
        cancelButtonText: 'Cancel',
        center: true,
        roundButton: true,
        confirmButtonClass: 'el-button--primary',
        beforeClose: this.completeRelease
      })
    },
    completeRelease (action, instance, done) {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true
        this.complete(this.list.id)
          .then(() => {
            instance.confirmButtonLoading = false
            done()
          }).catch(() => {
            instance.confirmButtonLoading = false
          })
      } else {
        done()
      }
    }
  }
}
</script>

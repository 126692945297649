<template>
  <fragment>
    <el-row :gutter="20">
      <el-col :span="24">
        <p class="small h-two text-center">Overview</p>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="text-center">
      <el-col
        :lg="{span: 4, offset: 6}"
        :sm="{span: 5, offset: 5}"
        :xs="{span: 6, offset: 2}"
      >
        <p class="name text-left">Total Expected</p>
        <p class="value text-left">{{waitlistMetrics.expected}}</p>
      </el-col>
      <el-col
        :lg="4" :sm="5"
        :xs="{span: 6, offset: 2}"
      >
        <p class="name text-left">Checked-in</p>
        <p class="value text-left">{{waitlistMetrics.checkin}} {{checkedInPercent | percent}}</p>
      </el-col>
      <el-col
        :lg="4" :sm="5"
        :xs="{span: 6, offset: 2}"
      >
        <p class="name text-left">Entered</p>
        <p class="value text-left">{{waitlistMetrics.entered}} {{enteredPercent | percent}}</p>
      </el-col>
    </el-row>
  </fragment>
</template>
<script>
import calcMixins from '@/mixins/calc'
export default {
  name: 'waitlist.metric',
  props: [ 'waitlistMetrics' ],
  mixins: [ calcMixins ],
  computed: {
    checkedInPercent () {
      return this.percent(this.waitlistMetrics.checkin, this.waitlistMetrics.expected)
    },
    enteredPercent () {
      return this.percent(this.waitlistMetrics.entered, this.waitlistMetrics.expected)
    }
  }
}
</script>

<template>
  <fragment>
    <template>
      <td class="el-check">
        <el-checkbox
          :value="meta.selects.includes(waitlist.customer.id)"
          @input="$emit('customerSelected', waitlist.customer.id)"
        />
      </td>
      <td>{{waitlist.index}}</td>
      <td>
        {{waitlist.customer.full_name}}
        <span v-if="waitlist.customer.note" class="asterisks">*</span>
        <span v-if="waitlist.customer.label" class="asterisks" :class="`label-${waitlist.customer.label}`">*</span>
      </td>
      <td>{{waitlist.phone}}</td>
      <td class="text-right pr-ten">
        <customer-checkin-actions
          v-if="!notPermittedWaitlist"
          :customer="waitlist"
          actionType="waitlist"
          :timeslot="waitlist.timeslot"
          @checkinAction="$emit('refreshCustomers')"
        />
      </td>
      <td class="handle-wrapper">
        <div class="handle">
          <i class="fa fa-navicon"></i>
        </div>
      </td>
    </template>
  </fragment>
</template>
<script>
import { mapState } from 'vuex'
import CustomerCheckinActions from '@/views/admin/lists/customer-checkin-actions'

export default {
  name: 'timeslot-group-edit-row',
  props: ['customers', 'waitlist', 'timeslot', 'timeslotIndex', 'showSelected', 'notPermittedWaitlist'],
  components: {
    CustomerCheckinActions
  },
  computed: {
    ...mapState({
      meta: state => state.waitlist.meta
    })
  }
}
</script>

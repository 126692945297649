<template>
  <el-dialog
    :title="value && value.id ? 'Edit Season' : 'Create a New Season'"
    :visible.sync="show"
    width="360px"
    center
    @open="init"
    >
    <el-form
    >
      <el-form-item
        :show-message="errors.has('season name')"
        :error="errors.first('season name')"
      >
        <el-input
          v-model="season.name"
          data-vv-name="season name"
          v-validate="'required'"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('on-close')" round>Cancel</el-button>
      <el-button type="primary" @click="create" round
        :loading="loading"
      >Save</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'create-new-season',
  props: ['open', 'value'],
  data () {
    return {
      season: {
        name: ''
      },
      loading: false
    }
  },
  computed: {
    show: {
      get () {
        return this.open
      },
      set () {
        this.$emit('on-close')
      }
    }
  },
  created () {

  },
  methods: {
    init () {
      if (this.value && this.value.id) {
        this.season = this.value
      } else {
        this.season.name = ''
        this.loading = false
      }
    },
    create () {
      this.$validator.validateAll()
        .then(res => {
          if (res) {
            this.loading = true
            let action = this.season.id ? 'season/update' : 'season/create'
            this.$store.dispatch(action, this.season)
              .then(season => {
                this.loading = false
                this.$emit('on-close')
                this.$router.push({
                  name: 'season.releases',
                  params: {
                    season_id: season.id
                  }
                })
              })
          }
        })
    }
  }
}
</script>

import request from '@/utils/request'
import store from '@/store'

export function show (id) {
  return request({
    url: `/lists/${id}?view_all=${store.getters.viewAll}`,
    method: 'get'
  })
}

export function getCustomers (filter) {
  return request({
    url: `/lists/${filter.id}/customers`,
    method: 'get',
    params: filter
  })
}

export function update (data) {
  return request({
    url: `/lists/${data.id}`,
    method: 'patch',
    data: data
  })
}

export function management (data) {
  return request({
    url: `/lists/management`,
    method: 'post',
    data: data
  })
}

export function listRegistration (data) {
  return request({
    url: `/lists/${data.id}/registration`,
    method: 'post',
    data: data
  })
}
export function timeslotListMovement (data) {
  return request({
    url: `/lists/${data.id}/movement`,
    method: 'post',
    data: data
  })
}

export function addCustomer (data) {
  return request({
    url: `/lists/${data.id}/customers`,
    method: 'post',
    data: data.customer
  })
}

export function removeCustomer (data) {
  return request({
    url: `/lists/${data.id}/customers`,
    method: 'delete',
    data: {
      ...data.customer,
      customer_id: data.customer.id
    }
  })
}

export function download (id) {
  return request({
    url: `/lists/${id}/download`,
    method: 'get'
  })
}

export function downloadReport (filename) {
  return request({
    url: `/events/download?file_name=${filename}`,
    method: 'get'
  })
}

export function checkDownloadQueues (data) {
  return request({
    url: `/lists/download/queues`,
    method: 'get',
    params: {
      files: data
    }
  })
}

export function complete (id) {
  return request({
    url: `/lists/${id}/complete`,
    method: 'post',
    data: {
      id
    }
  })
}

export function sendNotification (data) {
  return request({
    url: `/lists/${data.id}/notification`,
    method: 'post',
    data: data
  })
}

export function loadAudits (id) {
  return request({
    url: `/lists/${id}/audits`
  })
}

export function getSignupTestKeys ({ id, uuid }) {
  return request({
    url: `/lists/${id}/test-signup`,
    method: 'get',
    params: { id, uuid }
  })
}

export function generateSignupTestKeys (id) {
  return request({
    url: `/lists/${id}/generate-test-keys`,
    method: 'post',
    data: { id }
  })
}

export function redistributeList (id, { day }) {
  return request({
    url: `/lists/${id}/re-distribute`,
    params: { day }
  })
}

<template>
  <fragment>
    <div class="td-overlay">
      <div
        style="display: inline-block;"
        v-if="customer.timeslot"
        class="check-in-actions"
      >
        <el-checkbox-button
          :value="customer.check_in"
          @input="updateRegistration(customer.id, 'check-in', !customer.check_in)"
          class="el-checkbox-button--small"
          :disabled="(customer.check_in && (notPermittedStore || isSecurity)) || disabledUpdateTimeslot"
          data-cy-label="checkin-button">
          <span class="hidden-xs-only">Check-in</span>
          <i class="el-icon-check hidden-sm-and-up"></i>
        </el-checkbox-button>
        <el-checkbox-button
          :value="customer.entered"
          @input="updateRegistration(customer.id, 'entered', !customer.entered)"
          class="el-checkbox-button--small"
          :disabled="(customer.entered && (notPermittedStore || isSecurity)) || disabledUpdateTimeslot"
          data-cy-label="enter-button">
          <span class="hidden-xs-only">Entered</span>
          <i class="el-icon-location-information hidden-sm-and-up"></i>
        </el-checkbox-button>
      </div>
    </div>
  </fragment>
</template>
<script>
import { mapState } from 'vuex'
import { listRegistration } from '@/api/list'
import { waitlistRegistration } from '@/api/waitlist'
import listStates from '@/mixins/listStates'
import listHelpers from '@/mixins/listHelpers'
import permissionHelpers from '@/mixins/permissions'
import calc from '@/mixins/calc'

export default {
  name: 'customer-status',
  props: [ 'customer', 'actionType' ],
  mixins: [ listStates, listHelpers, permissionHelpers, calc ],
  data () {
    return {
      checkedIn: this.customer['check_in'],
      entered: this.customer['entered']
    }
  },
  computed: {
    ...mapState({
      list: state => state.list.list
    }),
    isWaistlistAction () {
      return this.actionType === 'waitlist'
    },
    disabledUpdateTimeslot () {
      const {
        list: { status = 0 } = {},
        isReadOnly = false,
        isReleaseComplete = false,
        canSeeSidebar = false,
        isAdvancedSecurity = false,
        isWaistlistAction = false,
        customer: { booking_date: bookingDate = '' } = {}
      } = this || {}

      return (!canSeeSidebar && status <= 9) ||
      isReadOnly ||
      isReleaseComplete ||
      (isWaistlistAction && this.$moment(new Date(bookingDate)).diff(new Date(), 'days') !== 0) ||
      (isAdvancedSecurity && isWaistlistAction)
    }
  },
  methods: {
    updateRegistration (id, action, value) {
      if (this.isWaistlistAction) {
        this.waitlistRegistration(id, action, value)
      } else {
        this.listRegistration(id, action, value)
      }
    },
    listRegistration (customerId, action, value) {
      listRegistration({
        id: this.list.id,
        customer_id: customerId,
        action: action,
        value: value
      }).then(res => {
        if (action === 'entered') {
          this.checkedIn = value
        }
        this.$emit('checkinAction')
      }).catch(() => {
        console.warn('Error updating registration')
      })
    },
    waitlistRegistration (id, action, value) {
      waitlistRegistration({
        id: id,
        action: action,
        value: value
      }).then(res => {
        if (action === 'entered') {
          this.checkedIn = value
        }
        this.$store.dispatch('waitlist/updateMetric', res.data)
        this.$emit('checkinAction')
      }).catch(() => {
        console.warn('Error updating registration')
      })
    }
  }
}
</script>

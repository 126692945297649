import isScreen from '@/utils/screen-helper'

const layout = {
  namespaced: true,
  state: {
    sidebarClose: false,
    sidebarStatic: true,
    limit: 4
  },
  actions: {
    toggleSidebar ({ commit }) {
      commit('TOGGLE_SIDEBAR')
    },
    switchSidebar ({ commit }, value) {
      commit('SWITCH_SIDEBAR', value)
    },
    updateSeasonLimit ({ commit }, value) {
      commit('UPDATE_SEASON_LIMIT', value)
    }
  },
  mutations: {
    TOGGLE_SIDEBAR: (state) => {
      if ((isScreen('lg') || isScreen('xl'))) {
        state.sidebarStatic = true
      } else {
        state.sidebarStatic = false
      }
      if (state.sidebarStatic) {
        state.sidebarClose = false
      } else {
        state.sidebarClose = true
      }
    },
    SWITCH_SIDEBAR: (state, value) => {
      if (value) {
        state.sidebarClose = value
      } else {
        state.sidebarClose = !state.sidebarClose
      }
    },
    UPDATE_SEASON_LIMIT: (state, value) => {
      state.limit = value
    }
  },
  getters: {
    isSidebarClose: (state) => () => {
      return state.sidebarClose
    },
    isSidebarStatic: (state) => () => {
      return state.sidebarStatic
    }
  }
}

export default layout

import Vue from 'vue'
import Router from 'vue-router'

/* start of admin elements and pages */
import AdminLayout from '@/views/admin/layout'

// page 404 admin
import AdminPage404 from '@/views/admin/errors/404'

// auth
import Signin from '@/views/admin/auth/signin'
import Signup from '@/views/admin/auth/signup'
import Reset from '@/views/admin/auth/reset'
import NewPassword from '@/views/admin/auth/new-password'

// Settings
import Settings from '@/views/admin/settings'
import Stores from '@/views/admin/settings/stores'
import Global from '@/views/admin/settings/global'
import Customers from '@/views/admin/settings/customers'
import Users from '@/views/admin/settings/users'

import Releases from '@/views/admin/releases'
import ReleasesList from '@/views/admin/releases/list'
import ReleaseRegister from '@/views/admin/releases/register'

import Lists from '@/views/admin/lists/wrapper'
import ListManagement from '@/views/admin/lists'
import Audits from '@/views/admin/audits'

import Waitlists from '@/views/admin/waitlists'
import Waitlist from '@/views/admin/waitlists/waitlist'
/* end of admin elements and pages */

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landing',
      redirect: '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE
    },
    {
      path: '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/login',
      name: 'auth.signin',
      component: Signin,
      meta: {
        auth: false
      }
    },
    {
      path:
        '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/login/:store_handle',
      name: 'auth.signin.store',
      component: Signin,
      meta: {
        auth: false
      }
    },
    {
      path: '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/signup',
      name: 'auth.signup',
      component: Signup
    },
    {
      path: '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/reset',
      name: 'auth.reset',
      component: Reset
    },
    {
      path: '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/new-password',
      name: 'auth.new_password',
      component: NewPassword
    },
    {
      path: '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE,
      name: 'main',
      component: AdminLayout,
      redirect: '/' + process.env.VUE_APP_PREFIX_ADMIN_ROUTE + '/seasons',
      meta: {
        auth: true
      },
      children: [
        {
          path: '404',
          name: 'admin.404',
          component: AdminPage404
        },
        {
          path: 'seasons',
          name: 'seasons',
          component: Releases,
          children: [
            {
              path: ':season_id',
              name: 'season.releases',
              component: ReleasesList
            },
            {
              path: ':season_id/releases/:release_id',
              name: 'release.register',
              component: ReleaseRegister
            },
            {
              path: ':season_id',
              component: Lists,
              children: [
                {
                  path: 'lists/:list_id/management',
                  name: 'list.management',
                  component: ListManagement
                }
              ]
            }
          ]
        },
        {
          path: 'audits/:list_id',
          name: 'audits',
          component: Audits
        },
        {
          path: 'settings',
          component: Settings,
          children: [
            {
              path: 'stores',
              name: 'settings.stores',
              component: Stores
            },
            {
              path: 'global',
              name: 'settings.global',
              component: Global
            },
            {
              path: 'customers',
              name: 'settings.customers',
              component: Customers
            },
            {
              path: 'users',
              name: 'settings.users',
              component: Users
            }
          ]
        },
        {
          path: 'waitlists',
          component: Waitlists,
          children: [
            {
              path: ':waitlist_id',
              name: 'store.waitlist',
              component: Waitlist
            }
          ]
        }
      ]
    },
    {
      path: '*',
      redirect: '/admin/404'
    }
  ]
})

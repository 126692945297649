<template>
  <el-aside
    v-if="canSeeSidebar"
    :class="sidebarClass"
    data-cy-label="sidebar"
  >
    <div class="logo">
      <div class="float-left user-avatar-dropdown">
        <account-dropdown></account-dropdown>
      </div>
      <a href="#" @click.prevent="$emit('logo-redirect')" class="logo-link">
        <img class="ta-c" src="@/assets/images/logo.png">
      </a>
    </div>
    <div class="logo-mini">
      <el-link
        @click.prevent="switchSidebar"
        :underline="false"
      >
        <img class="ta-c" src="@/assets/images/mini-logo.png">
      </el-link>
    </div>
    <el-menu>
      <li class="nav-item" v-if="canManageSeasons">
        <div class="nav-item-title">
          <a>Release</a>
          <el-link
            icon="el-icon-circle-plus"
            class="float-right"
            :underline="false"
            v-if="canManageRelease"
            @click.prevent="openSeasonModal"
            data-cy-label="add-season"
          ></el-link>
        </div>
        <ul class="menuitem-group"
         v-loading="loading"
        >
          <el-menu-item
            v-for="season in seasons()"
            :key="season.id"
            :class="!isNaN(seasonId) && season.id === +seasonId ? 'active' : ''"
            :data-season="season.id"
            data-cy-label="season-link"
            @click="toSeason(season.id)"
          >{{season.name}}</el-menu-item>
          <el-menu-item
            v-if="hasMore()"
            @click="loadMoreSeasons"
            data-cy-label="view-more-seasons"
          >...</el-menu-item>
        </ul>
      </li>
      <li class="nav-item"
        v-if="canSeeWaitlists"
      >
        <div class="nav-item-title"
          v-loading="storeLoading"
        >
          <a>Bookings</a>
        </div>
        <ul class="menuitem-group">
          <el-menu-item
            v-for="(store, index) in stores()"
            :key="index"
            :class="!isNaN(waitlistId) && +store.id === +waitlistId ? 'active' : ''"
            @click="toWaitlist(store.id)"
            data-cy-label="waitlist-link"
          >{{store.name}}</el-menu-item>
        </ul>
      </li>
      <li class="nav-item"
        v-if="menus.length > 0"
      >
        <div class="nav-item-title">
          <a>Settings</a>
        </div>
        <ul class="menuitem-group">
          <el-menu-item
            v-for="(nav, index) in menus"
            :key="index"
            :class="{'active': nav.name === route}"
            @click="navTo(nav.name)"
            :data-cy-label="`${nav.name}-link`"
          >{{nav.label}}</el-menu-item>
        </ul>
        <div class="sidebar-toggle show-deleted">
          <el-tooltip class="item" effect="dark" content="Show Deleted data" placement="bottom">
            <toggle-button
              class="mr-five"
              :value="viewMode"
              :sync="true"
              @input="onViewUpdate"
              :labels="{checked: 'Show Deleted', unchecked: 'Hide Deleted'}"
              :color="toggleAttributes.colors"
              :font-size="14"
              :height="22"
              :width="140"
              v-if="canManageSettings"
            ></toggle-button>
          </el-tooltip>
        </div>
      </li>
    </el-menu>
    <season-form
      :open="releaseModal"
      @on-close="releaseModal = false"
    ></season-form>
  </el-aside>
</template>
<script>
import { navs, toggleAttributes } from '@/consts'
import { mapGetters, mapState, mapActions } from 'vuex'
import SeasonForm from './season-form'
import AccountDropdown from '../account-dropdown'
import permissionMixin from '@/mixins/permissions'
export default {
  name: 'sidebar-left',
  components: {
    SeasonForm,
    AccountDropdown
  },
  mixins: [permissionMixin],
  data () {
    return {
      toggleAttributes,
      navs,
      releaseModal: false,
      loading: false,
      storeLoading: false
    }
  },
  computed: {
    ...mapState({
      limit: state => state.layout.limit,
      viewMode: state => state.auth.viewMode
    }),
    ...mapGetters({
      sidebarClose: 'layout/isSidebarClose',
      sidebarStatic: 'layout/isSidebarStatic',
      seasons: 'season/getSeasons',
      stores: 'store/getStores',
      hasMore: 'season/hasMoreSeasons'
    }),
    sidebarClass () {
      return this.sidebarStatic()
        ? 'sidebar-static'
        : this.sidebarClose()
          ? 'sidebar-closed'
          : 'sidebar-opened'
    },
    route () {
      return this.$route.name
    },
    seasonId () {
      return this.$route.params.season_id
    },
    fullName () {
      return this.current_user.first_name + ' ' + this.current_user.last_name
    },
    role () {
      return this.current_user.role
    },
    waitlistId () {
      return this.$route.params.waitlist_id
    },
    menus () {
      let navs = this.navs
      if (!this.canAccessStore) {
        navs = navs.filter(nav => nav.name !== 'settings.stores')
      }
      if (!this.canManageUsers) {
        navs = navs.filter(nav => nav.name !== 'settings.users')
      }
      if (!this.canManageSettings) {
        navs = navs.filter(nav => nav.name !== 'settings.global')
      }
      if (!this.canReadCustomers) {
        navs = navs.filter(nav => nav.name !== 'settings.customers')
      }
      return navs
    }
  },
  methods: {
    ...mapActions('layout', [
      'updateSeasonLimit'
    ]),
    ...mapActions('auth', [
      'setViewMode'
    ]),
    navTo (name) {
      if (this.route !== name) {
        this.$router.push({ name: name })
      }
    },
    canNavigateSeason (id) {
      return id !== this.$route.params.season_id || this.$route.name !== 'season.releases'
    },
    switchSidebar () {
      this.$store.dispatch('layout/switchSidebar')
    },
    openSeasonModal () {
      this.releaseModal = true
    },
    loadMoreSeasons () {
      this.updateSeasonLimit(this.limit + 4)
      this.loadSeasons()
    },
    loadSeasons () {
      this.loading = true
      this.$store.dispatch('season/list', {
        limit: this.limit
      }).then((data) => {
        this.loading = false
      })
    },
    toSeason (id) {
      if (this.canNavigateSeason(id)) {
        this.switchSidebar()
        this.$router.push({
          name: 'season.releases',
          params: { season_id: id }
        })
      }
    },
    toWaitlist (id) {
      if (id !== this.waitlistId) {
        this.switchSidebar()
        this.$router.push({
          name: 'store.waitlist',
          params: {
            waitlist_id: id
          }
        })
      }
    },
    onViewUpdate (mode) {
      this.setViewMode(mode)
      this.$root.$emit('set-view-mode', mode)
    }
  }
}
</script>

import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { getToken, getClient, getUID } from './auth'
import { Message } from 'element-ui'

const getBaseUrl = (request) => {
  const base = (
    /^\/register\/?$/.test(request) ||
    !process.env.VUE_APP_API_HOST_ADMIN
  )
    ? process.env.VUE_APP_API_HOST
    : process.env.VUE_APP_API_HOST_ADMIN

  return `${[base, process.env.VUE_APP_API_NAMESPACE].join('/')}`
}

// create an axios instance
const service = axios.create({
  // timeout: 5000, // request timeout,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    config['baseURL'] = getBaseUrl(config.url)

    if (~config.url.indexOf('/register/')) {
      config.headers['access-token'] = store.getters.publicToken
    } else if (store.getters.token) {
      config.headers['access-token'] = getToken()
    }

    if (store.getters.client) {
      config.headers['client'] = getClient()
    }

    if (store.getters.uid) {
      config.headers['uid'] = getUID()
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    return response
  },
  error => {
    let errorMsg = error.response.data && error.response.data.error ? error.response.data.error : 'There was an error with your request. Please try again shortly.'

    if (error.response.status === 404) {
      router.push({ name: '404' })
    }

    let errMsgType = typeof errorMsg

    if (errMsgType === 'object') {
      if (errorMsg.length > 1) {
        errorMsg = `<ul>${errorMsg.map(msg => `<li>${msg}</li>`).join('')}</ul>`
      } else {
        errorMsg = errorMsg[0]
      }
    }

    Message({
      message: errorMsg,
      type: 'error',
      dangerouslyUseHTMLString: errMsgType === 'object',
      customClass: 'error-message'
    })

    if (error.response.status === 401) {
      store.dispatch('auth/FedLogOut').then(() => {
        console.log('Verification failed, please login again')
      })
      router.push({ name: 'auth.signin' })
    }
    console.log('err ' + error) // for debug
    console.log(error.response.statusText)
    return Promise.reject(error)
  }
)

export default service

<template>
  <div class="el-pagination">
    <button
      type="button"
      class="btn-prev"
      :disabled="disabled || internalCurrentPage <= 1"
      @click="prev"
    >
      <span v-if="prevText">{{ prevText }}</span>
      <i v-if="!prevText" class="el-icon el-icon-arrow-left"></i>
    </button>
    <span class="el-pagination__jump">
      Go to
      <el-input
        class="el-pagination__editor is-in-pagination"
        :min="1"
        :max="internalPageCount"
        :value="userInput !== null ? userInput : internalCurrentPage"
        type="number"
        :disabled="disabled"
        @input="handleInput"
        @change="handleChange"/>
    </span>
    <pager :currentPage="internalCurrentPage" :pageCount="internalPageCount" :pagerCount="pagerCount" @change="handleCurrentChange" :disabled="disabled"></pager>
    <button
      type="button"
      class="btn-next"
      :disabled="disabled || internalCurrentPage === internalPageCount || internalPageCount === 0"
      @click="next"
    >
      <span v-if="nextText">{{ nextText }}</span>
      <i v-if="!nextText" class="el-icon el-icon-arrow-right"></i>
    </button>
    <span class="el-pagination__sizes">
      <el-select
        :value="internalPageSize"
        :popperClass="popperClass || ''"
        size="mini"
        @input="handleSizeChange"
        :disabled="disabled"
      >
        <el-option
          v-for="item in pageSizes"
          :key="item"
          :value="item"
          :label="`${item}/page`">
        </el-option>
      </el-select>
    </span>
  </div>
</template>
<script>
import Pager from './pager.vue'
import Locale from 'element-ui/src/mixins/locale'

export default {
  name: 'ElPagination',

  props: {
    pageSize: {
      type: Number,
      default: 10
    },

    small: Boolean,

    total: Number,

    pageCount: Number,

    pagerCount: {
      type: Number,
      validator (value) {
        return (value | 0) === value && value > 4 && value < 22 && (value % 2) === 1
      },
      default: 7
    },

    currentPage: {
      type: Number,
      default: 1
    },

    layout: {
      default: 'prev, pager, next, jumper, ->, total'
    },

    pageSizes: {
      type: Array,
      default () {
        return [10, 20, 30, 40, 50, 100]
      }
    },

    popperClass: String,

    prevText: String,

    nextText: String,

    background: Boolean,

    disabled: Boolean,

    hideOnSinglePage: Boolean
  },

  watch: {
    currentPage: {
      immediate: true,
      handler (val) {
        this.internalCurrentPage = this.getValidCurrentPage(val)
      }
    },

    pageSize: {
      immediate: true,
      handler (val) {
        this.internalPageSize = isNaN(val) ? 10 : val
      }
    },

    internalCurrentPage: {
      immediate: true,
      handler (newVal) {
        this.$emit('update:currentPage', newVal)
        this.userInput = null
        this.lastEmittedPage = -1
      }
    },

    internalPageCount (newVal) {
      const oldPage = this.internalCurrentPage
      if (newVal > 0 && oldPage === 0) {
        this.internalCurrentPage = 1
      } else if (oldPage > newVal) {
        this.internalCurrentPage = newVal === 0 ? 1 : newVal
        this.userChangePageSize && this.emitChange()
      }
      this.userChangePageSize = false
    }
  },

  data () {
    return {
      internalCurrentPage: 1,
      internalPageSize: 0,
      lastEmittedPage: -1,
      userInput: null,
      userChangePageSize: false
    }
  },

  computed: {
    internalPageCount () {
      if (typeof this.total === 'number') {
        return Math.max(1, Math.ceil(this.total / this.internalPageSize))
      } else if (typeof this.pageCount === 'number') {
        return Math.max(1, this.pageCount)
      }
      return null
    }
  },

  mixins: [Locale],

  methods: {
    handleCurrentChange (val) {
      this.internalCurrentPage = this.getValidCurrentPage(val)
      this.userChangePageSize = true
      this.emitChange()
    },

    prev () {
      if (this.disabled) return
      const newVal = this.internalCurrentPage - 1
      this.internalCurrentPage = this.getValidCurrentPage(newVal)
      this.$emit('prev-click', this.internalCurrentPage)
      this.emitChange()
    },

    next () {
      if (this.disabled) return
      const newVal = this.internalCurrentPage + 1
      this.internalCurrentPage = this.getValidCurrentPage(newVal)
      this.$emit('next-click', this.internalCurrentPage)
      this.emitChange()
    },

    getValidCurrentPage (value) {
      value = parseInt(value, 10)

      const havePageCount = typeof this.internalPageCount === 'number'

      let resetValue
      if (!havePageCount) {
        if (isNaN(value) || value < 1) resetValue = 1
      } else {
        if (value < 1) {
          resetValue = 1
        } else if (value > this.internalPageCount) {
          resetValue = this.internalPageCount
        }
      }

      if (resetValue === undefined && isNaN(value)) {
        resetValue = 1
      } else if (resetValue === 0) {
        resetValue = 1
      }

      return resetValue === undefined ? value : resetValue
    },

    emitChange () {
      this.$nextTick(() => {
        if (this.internalCurrentPage !== this.lastEmittedPage || this.userChangePageSize) {
          this.$emit('current-change', this.internalCurrentPage)
          this.lastEmittedPage = this.internalCurrentPage
          this.userChangePageSize = false
        }
      })
    },
    handleSizeChange (val) {
      if (val !== this.internalPageSize) {
        this.internalPageSize = val = parseInt(val, 10)
        this.userChangePageSize = true
        this.$emit('update:pageSize', val)
        this.$emit('sizeChange', val)
      }
    },
    handleInput (value) {
      this.userInput = value
    },
    handleChange (value) {
      this.internalCurrentPage = this.getValidCurrentPage(value)
      this.emitChange()
      this.userInput = null
    }
  },

  components: {
    Pager
  }
}
</script>

<template>
  <div class="auth-signin">
    <div class="logo">
      <img src="@/assets/images/logo.png">
    </div>
    <el-form
      status-icon
      class="auth-form"
      :rules="rules"
      :model="auth"
      ref="authForm"
      @submit.prevent.native="signIn"
    >
      <h5 class="text-center">Login</h5>
      <el-form-item prop="email">
        <el-input
          type="email"
          v-model="auth.email"
          autocomplete="off"
          placeholder="Enter your Email"
          @keyup.enter.native="signIn"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          placeholder="Enter your password"
          v-model="auth.password"
          autocomplete="off"
          @keyup.enter.native="signIn"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          @click.prevent="signIn"
          class="full-width"
        >
          Login
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-link
          type="info"
          :underline="false"
          @click.prevent="forgotPassword"
        >
          {{$t('auth.forgot-password')}}
        </el-link>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import permissionMixin from '@/mixins/permissions'
export default {
  name: 'signin',
  mixins: [permissionMixin],
  data () {
    return {
      loading: false,
      auth: {
        email: '',
        password: ''
      },
      rules: {
        email: [{
          required: true,
          message: 'Email required.',
          trigger: 'blur'
        }, {
          type: 'email',
          message: 'Please enter a valid email address',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: 'Password required.',
          trigger: 'blur'
        }]
      },
      store: null
    }
  },
  created () {
    this.store = this.$route.params.store_handle
    localStorage.setItem('store_handle', this.store)
  },
  methods: {
    signIn () {
      let store = this.store
      this.$refs.authForm.validate(res => {
        if (res) {
          let routerParams = {
            name: 'seasons'
          }
          this.loading = true
          this.$store.dispatch('auth/Login', this.auth)
            .then(() => {
              routerParams['query'] = {
                store: store,
                default_store_id: this.current_user.stores.length === 1 ? this.current_user.stores[0] : null
              }
              this.$router.push(routerParams)
            }).catch(err => {
              this.loading = false
              this.$message({
                message: err.response.data.errors.join('\n'),
                type: 'error'
              })
            })
        }
      })
    },
    forgotPassword () {
      this.$router.push({ name: 'auth.reset' })
    }
  }
}
</script>

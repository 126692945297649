<template>
  <el-dropdown
    @command="onCommandDropdown"
    trigger="click"
  >
    <div class="el-dropdown-link">
      <el-avatar> {{ full_name | initials }} </el-avatar>
      <div class="account">
        <p class="user-name">
          {{ full_name }}
        </p>
        <p class="user-permission">{{role | humanize}}</p>
      </div>
      <i class="el-icon-arrow-down el-icon--right"></i>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        icon="el-icon-s-custom"
        command="logout"
      >Logout</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'account.dropdown',
  data () {
    return {
      current_user: this.$store.state.auth.user
    }
  },
  computed: {
    full_name () {
      return this.current_user.first_name + ' ' + this.current_user.last_name
    },
    role () {
      return this.current_user.role
    }
  },
  methods: {
    onCommandDropdown (command) {
      if (command === 'logout') {
        this.$store.dispatch('auth/LogOut')
          .then(() => {
            this.$router.push({ name: 'auth.signin' })
          }).catch(() => {

          })
      }
    }
  }
}
</script>

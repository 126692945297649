<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    width="360px"
    center>
    <el-form
    >
      <el-form-item
        :show-message="errors.has('name')"
        :error="errors.first('name')"
      >
        <el-input
          v-model="release.name"
          placeholder="Week 9"
          data-vv-name="name"
          :disabled="disabled"
          v-validate="'required'"
        ></el-input>
      </el-form-item>
      <el-form-item
        :show-message="errors.has('date')"
        :error="errors.first('date')"
      >
        <el-date-picker
          v-model="release.release_date"
          type="date"
          :picker-options="datePickerOptions"
          :disabled="disabled"
          format="MM-dd-yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Pick a Release Date"
          data-vv-name="date"
          v-validate="'required'"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="$emit('on-close-dialog')"
        size="medium"
        round
      >Cancel</el-button>
      <el-button type="primary"
        round
        size="medium"
        :loading="loading"
        @click.prevent="validateRelease"
      >Save</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { listStatusObj } from '@/consts'
import { mapActions } from 'vuex'
export default {
  name: 'release-form',
  props: ['release', 'show_form', 'loading'],
  computed: {
    show: {
      get () {
        return this.show_form
      },
      set () {
        this.$emit('on-close-dialog')
      }
    },
    title () {
      return this.release.id ? 'Edit This Release' : 'Schedule a Release'
    },
    disabled () {
      return this.release && this.release.lists && this.release.lists.some(list => list.status === listStatusObj.complete)
    }
  },
  data () {
    return {
      datePickerOptions: {
        disabledDate (date) {
          return date <= new Date()
        }
      }
    }
  },
  methods: {
    ...mapActions('release', [
      'validate'
    ]),
    validateRelease () {
      this.$validator.validateAll()
        .then(valid => {
          if (valid) {
            this.validate(this.release)
              .then(res => {
                this.submitRelease(res)
              })
          }
        })
    },
    submitRelease (res) {
      if (res.release_exist && !res?.releases.includes(this.release?.id)) {
        this.$confirm(this.$t('releases.duplicated_release', { date: this.release.release_date }), this.$t('releases.confirm'), {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          center: true,
          roundButton: true
        }).then(() => {
          this.$emit('on-submit')
        }).catch(() => {
        })
      } else {
        this.$emit('on-submit')
      }
    }
  }
}
</script>

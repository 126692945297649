<template>
  <el-form
    label-position="top"
    @sumbit="updateUser"
  >
    <el-row :gutter="20">
      <el-col :lg="12" :md="24">
        <el-row :gutter="20">
          <el-col :span="12" >
            <el-form-item label="First Name"
              :show-message="errors.has('first name')"
              :error="errors.first('first name')"
            >
              <el-input
                data-vv-name="first name"
                v-validate="'required|alpha'"
                v-model="user.first_name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Last Name"
              :show-message="errors.has('last name')"
              :error="errors.first('last name')"
            >
              <el-input
                data-vv-name="last name"
                v-validate="'required|alpha'"
                v-model="user.last_name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24" >
            <el-form-item label="Email"
              :show-message="errors.has('email')"
              :error="errors.first('email')"
            >
              <el-input
                v-model="user.email"
                data-vv-name="email"
                v-validate="'required|email'"
              ></el-input>
            </el-form-item>
            <el-form-item label="Phone Number">
              <vue-tel-input
                v-model="user.phone"
                placeholder="Enter phone number"
              ></vue-tel-input>
            </el-form-item>

            <el-form-item
              label="Permitted stores"
              v-if="shouldHavePermittedStore"
              :show-message="errors.has('assigned stores')"
              :error="errors.first('assigned stores')"
            >
              <el-select
                placeholder="Select stores"
                class="full-width"
                data-vv-name="assigned stores"
                v-model="user.stores"
                v-validate.disable="'required'"
                :multiple-limit="maxAssignedStores"
                multiple
              >
                <el-option
                  v-for="store in stores"
                  :key="store.id"
                  :value="store.id"
                  :label="store.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col :lg="11" :offset="1" :md="24">
        <el-form-item label="Permissons">
          <el-radio-group
            v-model="user.role"
          >
            <el-radio
              v-for="(role, index) in roles"
              :key="index"
              :label="role"
              class="display-block mb-seven"
            >{{role | humanize}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item>
          <el-button
            type="danger"
            v-if="currentUser.id !== user.id"
            @click.prevent="removeUser"
          >Remove User</el-button>
          <el-button
            class="float-right"
            @click="cancel"
          >Cancel</el-button>
          <el-button
            type="primary"
            class="float-right"
            :loading="saving"
            @click="updateUser"
          >Save</el-button>
        </el-form-item>
      </el-col>
    </el-row>

  </el-form>
</template>
<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { roles } from '@/consts'
import { VueTelInput } from 'vue-tel-input'
export default {
  name: 'user-form',
  props: ['user'],
  data () {
    return {
      roles: roles,
      saving: false,
      meta: Vue.util.extend({}, this.user)
    }
  },
  computed: {
    ...mapState({
      stores: state => state.store.stores
    }),
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),
    shouldHavePermittedStore () {
      return ['security', 'manager', 'advanced_security'].includes(this.user.role)
    },
    maxAssignedStores () {
      return ['security', 'advanced_security'].includes(this.user.role) ? 1 : 0
    }
  },
  components: {
    VueTelInput
  },
  methods: {
    removeUser () {
      this.$msgbox({
        title: this.$t('activity.confirm.title'),
        message: this.$t('activity.confirm.description'),
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        center: true,
        roundButton: true,
        confirmButtonClass: 'el-button--danger',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            let message = this.$t('activity.deleted', { name: 'user' })
            this.$store.dispatch('user/destroy', this.user.id)
              .then(() => {
                instance.confirmButtonLoading = false
                done()
                this.$message({
                  message: message,
                  type: 'success'
                })
                this.$emit('onUpdate')
              }).catch(() => {
                instance.confirmButtonLoading = false
              })
          } else {
            done()
          }
        }
      })
    },
    updateUser () {
      let message = this.$t('activity.updated', { name: 'user' })

      this.$validator.validateAll()
        .then(res => {
          if (res) {
            this.saving = true
            this.$store.dispatch('user/update', this.user)
              .then(() => {
                this.$message({
                  message: message,
                  type: 'success'
                })
                this.$emit('onUpdate')
              }).catch(() => {
              }).then(() => {
                this.saving = false
              })
          }
        })
    },
    cancel () {
      this.$emit('onCancel', this.meta)
    }
  }
}
</script>

<template>
  <table class="el-table-table">
    <post-finalization-header />
    <fragment v-if="customers.length">
      <template
        v-for="(timeslot, index) in customersByTimeslot">
        <draggable
          :key="index"
          tag="tbody"
          v-if="editMode"
          v-model="timeslot.customers"
          handle=".handle"
          class="el-table__body"
          v-bind="dragOptions"
          group="people"
          :move="onDragMove"
          @change="onDragDrop">
          <tr
            slot="header"
            role="group"
            class="el-table__row timeslot-row"
            v-if="selectedTabInFocus && showSelected">
            <timeslot-groups-header
              :timeslot="timeslot"
              :normalizeTime="normalizeTime"
              :weekday="timeslot.weekday"
              :isSelectedByTimeSlot="timeSlotSelected(timeslot)"
              @timeslotSelection="handleTimeslotSelection" />
          </tr>
          <tr
            class="el-table__row"
            v-for="(customer, key) in timeslot.customers"
            :key="key">
            <timeslot-groups-edit-row
              :key="key"
              :customer="customer"
              :customers="customers"
              :list="list"
              :timeslot="timeslot"
              :timeslotIndex="index"
              :selectedTabInFocus="selectedTabInFocus"
              @customerSelected="handleCustomerSelection"
              @refreshCustomers="closeAndRefreshCustomers" />
          </tr>
        </draggable>
        <tbody
          v-else
          :key="index"
          class="el-table__body">
          <tr
            class="el-table__row timeslot-row"
            v-if="selectedTabInFocus && showSelected">
            <timeslot-groups-header
              :timeslot="timeslot"
              :normalizeTime="normalizeTime"
              :weekday="timeslot.weekday"
              :isSelectedByTimeSlot="timeSlotSelected(timeslot)"
              @timeslotSelection="handleTimeslotSelection" />
          </tr>
          <template v-for="(customer, key) in timeslot.customers">
            <timeslot-groups-static-row
              :key="key"
              :editMode="editMode"
              :customer="customer"
              :customers="customers"
              :list="list"
              :timeslot="timeslot"
              :timeslotIndex="index"
              :selectedTabInFocus="selectedTabInFocus"
              :showSelected="showSelected"
              :expandedCustomer="expandedCustomer"
              @expandCustomer="expandCustomer"
              @refreshCustomers="closeAndRefreshCustomers" />
          </template>
        </tbody>
      </template>
    </fragment>
    <empty-list
      v-else
      :showSelected="showSelected"
      @showUploadCSV="$emit('showUploadCSV')" />
    <div v-if="!editMode && displayCustomer">
      <el-dialog
        :title="getExpandedCustomer.full_name + ' - ' + getExpandedCustomer.timeslot"
        :visible.sync="displayCustomer"
        width="700px"
        center>
        <customer-form
          :customer="getExpandedCustomer"
          :list="list"
          :hideLabelForm="hideLabelForm"
          @updated="updateCustomers"
          @cancel="cancel"
          @customerRemoved="closeAndRefreshCustomers"
          />
      </el-dialog>
    </div>
  </table>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import PostFinalizationHeader from '@/views/admin/lists/post-finalization-header'
import EmptyList from '@/views/admin/lists/empty-list'
import TimeslotGroupsHeader from '@/views/admin/lists/timeslot-groups-header'
import TimeslotGroupsEditRow from '@/views/admin/lists/timeslot-groups-edit-row'
import TimeslotGroupsStaticRow from '@/views/admin/lists/timeslot-groups-static-row'
import CustomerForm from '@/views/admin/global/customer-form'
import draggable from 'vuedraggable'
import listStates from '@/mixins/listStates'
import listHelpers from '@/mixins/listHelpers'
import calcMixin from '@/mixins/calc'
import permissionMixin from '@/mixins/permissions'

export default {
  name: 'timeslot-groups',
  props: [
    'status'
  ],
  components: {
    PostFinalizationHeader,
    TimeslotGroupsHeader,
    TimeslotGroupsEditRow,
    TimeslotGroupsStaticRow,
    CustomerForm,
    EmptyList,
    draggable
  },
  mixins: [ listStates, listHelpers, calcMixin, permissionMixin ],
  data () {
    return {
      expandedCustomer: -1,
      customers: [],
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      getExpandedCustomer: {
        full_name: '',
        timeslot: ''
      }
    }
  },
  watch: {
    editMode () {
      this.expandedCustomer = -1
    },
    vuexCustomers (customers) {
      this.refreshLocalCustomers(customers)
    }
  },
  mounted () {
    this.refreshLocalCustomers(this.vuexCustomers)
  },
  computed: {
    ...mapState({
      vuexCustomers: state => state.list.customers.customers,
      list: state => state.list.list,
      meta: state => state.list.meta,
      filter: state => state.list.filter,
      editMode: state => state.list.editMode,
      label: state => state.list.customers.label
    }),
    showSelected () {
      return this.filter.selected === '1'
    },
    displayCustomer: {
      get () {
        return Boolean(this.expandedCustomer !== -1)
      },
      set () {
        this.expandedCustomer = -1
        this.getExpandedCustomer = {
          full_name: '',
          timeslot: ''
        }
      }
    },
    hideLabelForm () {
      return this.isReleaseOpen || !this.canSeeAsterisks
    },
    selectedTabInFocus () {
      return this.status === '1'
    },
    normalizeTime () {
      return !this.isEuStore(this.list.store.country)
    }
  },
  methods: {
    ...mapActions('list', [
      'updateMeta',
      'getCustomers'
    ]),
    ...mapActions('customer', [
      'refresh'
    ]),
    refreshLocalCustomers (customers) {
      this.customers = customers.slice(0).map(customer => {
        return {
          ...customer
        }
      })
    },
    closeAndRefreshCustomers () {
      this.expandedCustomer = -1
      this.refreshCustomers()
    },
    refreshCustomers () {
      this.$nextTick(() => {
        this.getCustomers(this.list.id)
      })
    },
    cancel (customer) {
      this.refresh(customer)
        .then(() => {
          this.displayCustomer = false
        })
    },
    updateCustomers () {
      this.refreshCustomers()
    },
    timeSlotSelected (timeslot) {
      return this.selectedByTimeslot(timeslot.timeslot).length === timeslot.customers.length && timeslot.customers.length > 0
    },
    expandCustomer (customerId) {
      this.expandedCustomer = this.expandedCustomer === customerId ? -1 : customerId
      this.getExpandedCustomer = this.customers.find(({ id }) => id === customerId) || null
    },
    handleCustomerSelection (id) {
      const selects = (this.meta.selects || []).slice(0)
      if (~selects.indexOf(id)) {
        selects.splice(selects.indexOf(id), 1)
      } else {
        selects.push(id)
      }
      this.updateMeta({
        selects
      })
    },
    handleTimeslotSelection (timeslot) {
      let customers = timeslot.customers.map(customer => customer.id)
      let meta = this.selectedByTimeslot(timeslot.timeslot)
      let selects = this.meta.selects.filter(id => !customers.includes(id))
      if (meta.length < customers.length) {
        selects = [...customers, ...selects]
      }
      this.updateMeta({
        selects
      })
    },
    selectedByTimeslot (timeslot) {
      let customers = this.customers
        .slice(0)
        .filter(customer => customer.timeslot === timeslot)
        .map(customer => customer.id)
      let meta = this.meta.selects.filter(id => customers.includes(id))
      return meta
    }
  }
}
</script>

import { load, accept } from '@/api/invite'

const invite = {
  namespaced: true,
  state: {
    invite: {}
  },
  actions: {
    loadByToken ({ commit }, inviteToken) {
      return new Promise((resolve, reject) => {
        load(inviteToken).then((res) => {
          const data = res.data
          commit('SET_INVITE', data)
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    accept ({ commit }, data) {
      return new Promise((resolve, reject) => {
        accept(data).then(res => {
          commit('CREATE_INVITE', res.data)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  mutations: {
    SET_INVITE: (state, data) => {
      state.invite = data
    },
    CREATE_INVITE: (state, data) => {
      state.invite = data
    }
  },
  getters: {

  }
}

export default invite

<template>
  <el-dialog
    title="Upload CSV"
    :visible.sync="show"
    width="385px"
    class="csv-uploader"
    center
    @open="onOpen"
    @close="onClose"
  >
    <div class="validation-error text-center"
      v-if="validationError && !file"
    >
      <p>{{ $t('list.csv.incompatible') }}</p>
      <p> {{ error || $t('list.csv.csv_guideline') }}</p>
    </div>
    <xlsx-read
      :file="file"
      @parsed="setSheets"
    >
      <xlsx-json :sheet="selectedSheet" @parsed="setCollection"/>
    </xlsx-read>
    <el-upload
      v-show="!file"
      class="text-center"
      ref="upload"
      :headers="auth"
      :data="{
        id: list.id
      }"
      :action="`${baseUrl}/lists/csv_upload`"
      drag
      accept=".csv,.xlsx,.xls"
      :show-file-list="false"
      :on-change="onChange"
      :auto-upload="false"
      :on-error="onError"
      :on-success="onSuccess"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
      <div
        class="el-upload__tip" slot="tip"
      >
        <span>
          <el-link
            :underline="false"
            :href="`${publicPath}files/${csv}`"
            download
          >{{$t("list.csv.download")}}</el-link>
        </span>
      </div>
    </el-upload>
    <div class="attached-csv" v-if="file">
      <ul class="el-upload-list el-upload-list--text">
        <li tabindex="0" class="el-upload-list__item is-success">
          <span class="el-upload-list__item-name">
            <i class="el-icon-document"></i>
            {{file.name}}
          </span>
          <label class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-success"></i></label>
      </li>
      </ul>
    </div>
    <span slot="footer" class="dialog-footer"
      v-if="file">
      <el-button
        @click="$emit('closeDialog')"
        size="medium"
        round>Cancel</el-button>
      <el-button type="primary"
        round
        size="medium"
        :loading="loading"
        @click.prevent="addCustomers">Add</el-button>
    </span>
    <el-dialog
      width="360px"
      title="Caution"
      :visible.sync="innerVisible"
      append-to-body
      center>
      {{$t("list.csv.override")}}
      <span slot="footer" class="dialog-footer">
        <el-button
          size="medium"
          @click.prevent="innerVisible = false"
          round>Cancel</el-button>
        <el-button
          type="danger"
          round
          size="medium"
          @click.prevent="submitUpload">Continue</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { getToken, getClient, getUID } from '@/utils/auth'
import listStates from '@/mixins/listStates'
import { XlsxRead, XlsxJson } from 'vue-xlsx'

export default {
  name: 'csv-upload-form',
  props: ['show_form', 'list'],
  mixins: [ listStates ],
  components: {
    XlsxRead,
    XlsxJson
  },
  computed: {
    show: {
      get () {
        return this.show_form
      },
      set () {
        this.$emit('closeDialog')
      }
    },
    auth () {
      return {
        'Accept': 'application/json',
        'access-token': getToken(),
        'client': getClient(),
        'uid': getUID()
      }
    },
    csv () {
      if (this.list.is_physical_signup) {
        return 'template-physical-signup.csv'
      } else {
        return 'template-non-physical-signup.csv'
      }
    }
  },
  data () {
    return {
      loading: false,
      validationError: false,
      error: '',
      innerVisible: false,
      baseUrl: [process.env.VUE_APP_API_HOST_ADMIN || process.env.VUE_APP_API_HOST, process.env.VUE_APP_API_NAMESPACE].join('/'),
      file: null,
      tmpFile: null,
      selectedSheet: 'Sheet1',
      sheets: [],
      publicPath: process.env.BASE_URL
    }
  },
  methods: {
    onChange (file, fileList) {
      this.file = file.raw
    },
    onOpen () {
      this.file = null
      this.validationError = false
    },
    onClose () {
      this.file = null
      this.validationError = false
    },
    addCustomers () {
      if (this.isCSVUploaded) {
        this.innerVisible = true
      } else {
        this.submitUpload()
      }
    },
    submitUpload () {
      this.loading = true
      this.innerVisible = false
      this.$refs.upload.submit()
    },
    onSuccess () {
      this.loading = false
      this.$emit('closeDialog')
      this.$emit('refreshCustomers')

      let message = this.$t('list.csv.success')
      this.$message({
        message: message,
        type: 'success'
      })
    },
    onError () {
      this.loading = false
    },
    setCollection (data) {
      this.validateCSV(data)
    },
    setSheets (data) {
      this.sheets = data.SheetNames
      this.selectedSheet = this.sheets[0]
    },
    validateCSV (rows) {
      this.error = ''
      return rows.forEach(this.validateRow)
    },
    validateRow (row) {
      if (!row) {
        return true
      }

      if (this.isFalsy(row['First Name'])) {
        this.validationError = true
        this.error = this.$t('list.csv.first_name_missing', { row: row['__rowNum__'] })
        this.file = null
      }

      if (this.isFalsy(row['Last Name'])) {
        this.validationError = true
        this.error = this.$t('list.csv.last_name_missing', { row: row['__rowNum__'] })
        this.file = null
      }

      if (this.isFalsy(row['Time Slot']) && this.isManualFlow) {
        this.validationError = true
        this.error = this.$t('list.csv.timeslot_required', { row: row['__rowNum__'] })
        this.file = null
      }

      if (!this.isFalsy(row['Time Slot']) && !this.isManualFlow && this.isPreFinalization) {
        this.validationError = true
        this.error = this.$t('list.csv.timeslot_incompatible', { row: row['__rowNum__'] })
        this.file = null
      }

      return true
    },
    isFalsy (value) {
      return typeof value === 'undefined' || !value
    }
  }
}
</script>

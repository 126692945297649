<template>
  <tr
    :key="waitlist.id"
    class="el-table__row"
  >
    <td class="el-check"></td>
    <td
      @click.prevent="$emit('expandCustomer', waitlist.customer.id)"
    >{{waitlist.index}}</td>
    <td
      @click.prevent="$emit('expandCustomer', waitlist.customer.id)"
    >
      {{waitlist.customer.full_name}}
      <span v-if="waitlist.customer.note" class="asterisks">*</span>
      <span
        v-if="waitlist.customer.label"
        class="asterisks"
        :class="`label-${waitlist.customer.label}`"
      >*</span>
    </td>
    <td
      @click.prevent="$emit('expandCustomer', waitlist.customer.id)"
    >{{waitlist.phone}}</td>
    <td class="text-right pr-ten">
      <customer-checkin-actions
        v-if="!notPermittedWaitlist"
        :customer="waitlist"
        actionType="waitlist"
        :timeslot="waitlist.timeslot"
        @checkinAction="$emit('refreshCustomers')"
      />
    </td>
  </tr>
</template>
<script>
import CustomerCheckinActions from '@/views/admin/lists/customer-checkin-actions'
export default {
  name: 'waitlist.timeslotgroups.static-row',
  props: ['waitlist', 'notPermittedWaitlist'],
  components: {
    CustomerCheckinActions
  }
}
</script>

<template>
  <div class="releases-list"
    v-loading="loading"
  >
    <el-row :gutter="20">
      <el-col :sm="12">
        <h2 class="main-title"> {{season().name | capitalize}} In-Store Releases </h2>
      </el-col>
      <el-col :sm="12">
        <div class="schedule-release-wrapper text-right">
          <el-button
            v-if="canScheduleRelease"
            size="small"
            @click.prevent="show_release_reg=true"
          >Schedule a Release</el-button>
          <el-button
            v-if="canManageRelease"
            size="small"
            @click.prevent="season_modal = true"
          >Edit</el-button>
          <el-button
            v-if="canManageRelease"
            size="small"
            type="danger"
            @click.prevent="removeSeason"
          >Delete</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :span="24"
      >
        <el-tabs
          type="card"
          v-model="selectedTab"
        >
          <el-tab-pane
            v-for="(type, index) in release_types"
            :key="index"
            :label="type.label"
            :name="`${index + 1}`"
          >
          <div class="releases"
            v-if="releases(type.upcoming).length > 0 && canScheduleRelease"
          >
            <release
              v-for="release in releases(type.upcoming)"
              :key="release.id"
              :release="release"
            ></release>
          </div>
          <div class="no-release"
            v-if="releases(type.upcoming).length === 0 || !canScheduleRelease"
          >
            <div class="info text-center">
              <p>{{$t("releases.empty", {timeline: type.label.toLowerCase()})}}</p>
              <el-button
                size="sm"
                v-if="type.upcoming && canManageRelease"
                @click.prevent="show_release_reg = true"
              >Schedule a Release</el-button>
            </div>
          </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <ReleaseForm
      :release="release"
      :show_form="show_release_reg"
      :loading="release_saving"
      @on-close-dialog="show_release_reg = false"
      @on-submit="createRelease"
    />
    <season-form
      :open="season_modal"
      :value="season()"
      @on-close="season_modal = false"
    ></season-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { releaseTypes } from '@/consts'
import Release from './components/release'
import ReleaseForm from '@/views/admin/releases/components/release-form'
import permissionMixin from '@/mixins/permissions'
import SeasonForm from '@/views/admin/layout/sidebar-left/season-form'
export default {
  name: 'releases-list',
  data () {
    return {
      intervalid: null,
      loading: false,
      release_types: releaseTypes,
      selectedTab: '1',
      show_release_reg: false,
      release_saving: false,
      season_modal: false,
      release: {
        name: null,
        release_date: null,
        season_id: null
      }
    }
  },
  components: {
    Release,
    ReleaseForm,
    SeasonForm
  },
  mixins: [permissionMixin],
  computed: {
    season_id () {
      return this.$route.params.season_id
    },
    ...mapGetters({
      season: 'release/getSeason',
      seasons: 'season/getSeasons',
      releases: 'release/getReleases',
      activeList: 'release/getActiveList',
      bookings: 'release/getBookings'
    })
  },
  watch: {
    season_id: {
      handler () {
        this.loadReleases(true)
      },
      deep: true
    }
  },
  created () {
    this.release.season_id = Number(this.season_id)
    this.loadReleases(true)

    this.$root.$on('set-view-mode', mode => {
      this.loadReleases(true)
    })
  },
  mounted () {
    if (this.isSecurity || this.isAdvancedSecurity) {
      this.startInterval()
    }
  },
  methods: {
    loadReleases (activeLoading) {
      if (activeLoading) {
        this.loading = true
      }

      this.$store.dispatch('release/list', { season_id: this.season_id })
        .then(() => {
        }).catch(() => {
          this.$router.push({ name: 'seasons' })
        }).then(() => {
          this.loading = false
          if (this.activeList && (this.isSecurity || this.isAdvancedSecurity)) {
            this.$router.push({ name: 'list.management',
              params: {
                list_id: this.activeList.id,
                season_id: this.season_id
              }
            })
          }
          if (this.bookings && (this.isSecurity || this.isAdvancedSecurity)) {
            this.$router.push({ name: 'store.waitlist',
              params: {
                waitlist_id: this.bookings
              }
            })
          }
        })
    },
    startInterval () {
      this.intervalid = setInterval(() => {
        this.loadReleases(false)
      }, 5000)
    },
    createRelease () {
      this.release_saving = true
      this.$store.dispatch('release/create', this.release)
        .then(() => {
          this.show_release_reg = false
          let message = this.$t('activity.created', { name: 'release' })
          this.$message({
            message: message,
            type: 'success'
          })
          this.release.name = null
          this.release.release_date = null
        }).catch(() => {
        }).then(() => {
          this.release_saving = false
        })
    },
    removeSeason () {
      this.$msgbox({
        title: this.$t('seasons.activity.confirm.title'),
        message: this.$t('seasons.activity.confirm.description'),
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        center: true,
        roundButton: true,
        confirmButtonClass: 'el-button--danger',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            let message = this.$t('activity.deleted', { name: 'Season' })
            this.$store.dispatch('season/remove', this.season().id)
              .then(() => {
                instance.confirmButtonLoading = false
                done()
                this.$message({
                  message: message,
                  type: 'success'
                })
                this.afterRemove()
              }).catch(() => {
                instance.confirmButtonLoading = false
              })
          } else {
            done()
          }
        }
      })
    },
    afterRemove () {
      if (this.seasons().length > 0) {
        this.$router.push({
          name: 'season.releases',
          params: {
            season_id: this.seasons()[0].id
          }
        })
      } else {
        this.$router.push({
          name: 'settings.stores'
        })
      }
    }
  },
  beforeDestroy () {
    if (this.intervalid) {
      clearInterval(this.intervalid)
    }
  }
}
</script>

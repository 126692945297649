const getters = {
  token: state => state.auth.token,
  publicToken: state => state.register.accessToken,
  client: state => state.auth.client,
  uid: state => state.auth.uid,
  authenticated: state => state.auth.authenticated,
  user: state => state.auth.user,
  viewAll: state => state.auth.viewMode
}

export default getters

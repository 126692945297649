import { load, save } from '@/api/setting'

const setting = {
  namespaced: true,
  state: {
    data: {
      us_note: '',
      uk_note: '',
      fr_note: '',
      de_note: '',
      it_note: ''
    }
  },
  actions: {
    load ({ commit }) {
      return new Promise((resolve, reject) => {
        load()
          .then(res => {
            const data = res.data
            commit('SET_SETTINGS', data)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    save ({ commit }, data) {
      return new Promise((resolve, reject) => {
        save(data)
          .then(res => {
            commit('SET_SETTINGS', res.data)
            resolve(res.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  mutations: {
    SET_SETTINGS: (state, data) => {
      state.data = data
    }
  },
  getters: {
    getSettings: state => () => {
      return state.data
    }
  }
}

export default setting

<template>
  <div>
    <el-row>
      <el-col
        :span="24"
      >
        <h1>Error 404</h1>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'page404'
}
</script>

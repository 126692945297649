<template>
  <span>
    <el-tag
      class="round"
      :class="{
        'black': rstatus.value === 0,
        'blue' : rstatus.value === 2
      }"
      :type="badgeType(rstatus.value)"
      :effect="badgeEffect(rstatus.value)"
      size="mini"
      v-if="badgeType(rstatus.value)"
    >
      <strong>{{rstatus.label}}</strong>
    </el-tag>
    <el-tag
      type="info"
      size="mini"
      class="round"
      v-if="!badgeType(rstatus.value)"
    >
      <strong
        :class="{
          'text-success': badgeTextStatus(rstatus.value)
        }"
      >{{rstatus.label}}</strong>
    </el-tag>
  </span>
</template>
<script>
import { listStatuses, physicalStatuses } from '@/consts'
import listStates from '@/mixins/listStates'
export default {
  name: 'registration-status',
  props: ['status', 'is_physical'],
  mixins: [listStates],
  computed: {
    rstatus () {
      let statuses = listStatuses
      if (this.is_physical) {
        statuses = physicalStatuses
      }
      let registrationStatus = statuses.find(s => s.value === this.status)
      if (!registrationStatus) {
        registrationStatus = {
          value: 0,
          label: ''
        }
      }
      return registrationStatus
    }
  }
}
</script>

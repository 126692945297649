<template>
  <div class="release" :class="release.deleted_at ? 'archived' : ''">
    <el-row>
      <el-col>
        <p class="float-right text-danger" v-if="release.deleted_at">
          <i class="el-icon-delete"></i> {{ release.deleted_at | moment('dddd, MMM D, YYYY')}}
        </p>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      class="release-heading"
    >
      <el-col
        :md="12"
        :sm="24"
      >
        <div class="title">
          <p class="name">{{release.name}}</p>
          <p class="date">{{release.release_date | moment('dddd, MMM D, YYYY')}}</p>
        </div>
      </el-col>
      <el-col
        :md="12"
        :sm="24"
      >
        <el-link
          :underline="false"
          class="float-right link-btn mb-seven"
          @click.prevent="regRelease"
        >
          View Release Details
          <i class="el-icon-arrow-right"></i>
        </el-link>
      </el-col>
    </el-row>
    <div
      v-for="list in release.lists"
      :key="list.id"
      class="release-store"
      data-cy-label="release-list"
    >
      <el-row>
        <el-col
          :sm="12"
          :xs="8"
        >{{list.store.name}}</el-col>
        <el-col
          :sm="12"
          :xs="16"
        >
          <RegistrationStatus
            :status="list.status"
            :is_physical="list.is_physical_signup"
            class="float-right mb-seven"
          />
          <el-tag
            class="round float-right mb-seven"
            type="warning"
            effect="dark"
            size="mini"
            v-if="list.is_physical_signup"
          >
            Physical Signup
            <span v-if="list.is_automatic_physical_flow">(A)</span>
            <span v-else>(M)</span>
          </el-tag>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import RegistrationStatus from '@/views/admin/releases/components/registration-status'
import listStates from '@/mixins/listStates'
export default {
  name: 'release',
  props: ['release'],
  mixins: [listStates],
  components: {
    RegistrationStatus
  },
  computed: {
    ...mapGetters({
      stores: 'release/getStores'
    }),
    season_id () {
      return this.$route.params.season_id
    }
  },
  data () {
    return {

    }
  },
  methods: {
    regRelease () {
      this.$router.push({
        name: 'release.register',
        params: {
          season_id: this.season_id,
          release_id: this.release.id
        }
      })
    }
  }
}
</script>

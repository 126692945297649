<template>
  <el-row :gutter="20">
    <el-col
      :sm="12"
      :xs="24"
    >
      <cascade
        ref="cascade"
        :loading="loading"
        :meta="meta"
        :filter="filter"
        :list="list"
        @on-change="handleCascadeChange"
        @on-confirm="saveChanges"
      ></cascade>
    </el-col>
    <el-col
      :sm="12"
      :xs="24"
    >
      <div class="actions">
        <el-button
          size="medium"
          @click.prevent="cancelEditMode"
          data-cy-label="cancel"
        >Cancel</el-button>
      </div>
    </el-col>
  </el-row>
</template>
<script>

import { mapState, mapActions } from 'vuex'
import Cascade from '@/views/admin/global/cascade'
import { management } from '@/api/list'

export default {
  name: 'cascade-actions',
  components: {
    Cascade
  },
  data () {
    return {
      cascade: []
    }
  },
  computed: {
    ...mapState({
      meta: state => state.list.meta,
      filter: state => state.list.filter,
      loading: state => state.list.loading,
      list: state => state.list.list
    })
  },
  methods: {
    ...mapActions('list', [
      'setEditMode',
      'setLoading',
      'resetMeta'
    ]),
    handleCascadeChange (value) {
      this.cascade = value
    },
    saveChanges () {
      let data = {
        meta: this.meta,
        cascade: this.cascade,
        selected: this.filter.selected,
        id: this.list.id
      }
      if (this.cascade.includes('custom_sms')) {
        this.$refs.cascade.confirm()
      } else {
        this.setLoading(true)
        management(data)
          .then(res => {
            return this.resetMeta()
          }).then(() => {
            this.$emit('refreshCustomers')
            this.setLoading(false)
            this.setEditMode(false)
          }).catch(() => {
            console.warn('There was an issue with management')
          })
      }
    },
    cancelEditMode () {
      this.setEditMode(false)
      this.resetMeta()
    }
  }
}
</script>

<template>
  <div class="list-management">
    <el-row v-if="canManageCustomer" :gutter="20">
      <el-col
        :span="24"
        class="text-right mt-ten">
        <el-button
          icon="el-icon-download"
          size="small"
          class="mb-fifteen mt-ten"
          type="info"
          :loading="downloading"
          @click.prevent="downloadCSV"
        >
          <span>Export (CSV)</span>
        </el-button>
      </el-col>
    </el-row>
    <div v-if="isReleaseOpen">
      <release-open
        @showAddCustomer="showAddCustomer = true"
        @sizeChange="handlePageSize"
        @refreshCustomers="loadCustomers"
        @filterList="updateSearchFilter" />
    </div>
    <div v-else>
      <pre-release-header
        @showAddCustomer="showAddCustomer = true"
        @showUploadCSV="showUploadCSV = true"
        @triggerEditMode="setEditMode"
        @redistribute="toggleRedistributeList"
        @refreshCustomers="loadCustomers"
        @refreshList="loadList"
        @filterList="updateSearchFilter" />
      <pre-finalization
        v-if="isPreFinalization && !isPhysicalSignup"
        @refreshCustomers="loadCustomers"
        @sizeChange="handlePageSize" />
      <post-finalization
        v-if="!isPreFinalization"
        @sizeChange="handlePageSize"
        @refreshCustomers="loadCustomers"
        @onUpdate="onUpdate" />
      <physical-signup
        v-if="isPhysicalSignup && isPreFinalization"
        @sizeChange="handlePageSize"
        @onUpdate="onUpdate"
        @showUploadCSV="showUploadCSV = true"
        @refreshCustomers="loadCustomers"
        @filterList="updateSearchFilter" />
      <el-row :gutter="20" v-if="!notPermittedStore">
        <el-col
          :span="24"
          class="text-right mt-ten">
          <el-button
            v-if="!isPreFinalization && customers.length > 0 && !editMode"
            type="info"
            size="small"
            @click.prevent="setEditMode">Edit List</el-button>
        </el-col>
      </el-row>
    </div>
    <CustomerAddForm
      :show_form="showAddCustomer"
      :list="list"
      :customer="customer"
      :defaultCountry="list.store.country"
      @on-init="initCustomer"
      @on-create-customer="onCustomerCreate"
      @on-close-dialog="showAddCustomer = false"
    />
    <CSVUploader
      v-if="showUploadCSV"
      :list="list"
      :show_form="showUploadCSV"
      @closeDialog="showUploadCSV = false"
      @refreshCustomers="loadList" />

    <ConfirmModule
      :open="confirm_modal"
      title="Are you sure?"
      description="This action will evenly redistribute the confirmed users across all available time slots"
      @on-close="confirm_modal = false"
      @on-confirm="confirmRedistribution"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { redistributeList } from '@/api/list'
import PreReleaseHeader from '@/views/admin/lists/pre-release-header'
import ReleaseOpen from '@/views/admin/lists/listTypes/release-open'
import PhysicalSignup from '@/views/admin/lists/listTypes/physical-signup'
import PreFinalization from '@/views/admin/lists/listTypes/pre-finalization'
import PostFinalization from '@/views/admin/lists/listTypes/post-finalization'
import CustomerAddForm from '@/views/admin/global/customer-add-form.vue'
import CSVUploader from '@/views/admin/lists/csv-uploader.vue'
import calcMixin from '@/mixins/calc'
import listStates from '@/mixins/listStates'
import permissionMixin from '@/mixins/permissions'
import downloadMixin from '@/mixins/download'
import ConfirmModule from '@/views/components/confirm-module.vue'

export default {
  name: 'list.index',
  components: {
    PreReleaseHeader,
    ReleaseOpen,
    PhysicalSignup,
    PreFinalization,
    PostFinalization,
    CustomerAddForm,
    CSVUploader,
    ConfirmModule
  },
  mixins: [
    calcMixin,
    listStates,
    permissionMixin,
    downloadMixin
  ],
  data () {
    return {
      showAddCustomer: false,
      showUploadCSV: false,
      customer: {
        first_name: '',
        last_name: '',
        phone: '',
        timeslot: ''
      },
      timer: '',
      intervalid: null,
      confirm_modal: false
    }
  },
  created () {
    this.setEditMode(false)
    this.resetFilter({
      id: this.$route.params['list_id']
    }).then(() => {
      this.loadList()
    })
    this.timer = setInterval(() => this.loadCustomers(false), 10000)
  },
  mounted () {
    this.checkQueues()

    if (this.isSecurity || this.isAdvancedSecurity) {
      this.startInterval()
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)

    if (this.intervalid) {
      clearInterval(this.intervalid)
    }
  },
  watch: {
    'filter.limit': {
      handler () {
        this.loadCustomers()
      }
    },
    'filter.selected': {
      handler () {
        this.loadCustomers()
      }
    },
    'filter.spanIndex': {
      handler () {
        this.loadCustomers()
      }
    },
    'filter.page': {
      handler () {
        this.loadCustomers()
      }
    },
    'filter.orderBy': {
      handler () {
        this.loadCustomers()
      }
    }
  },
  computed: {
    ...mapState({
      filter: state => state.list.filter,
      loading: state => state.list.isLoading,
      editMode: state => state.list.editMode,
      list: state => state.list.list,
      customers: state => state.list.customers.customers
    }),
    ...mapGetters({
      activeList: 'release/getActiveList'
    }),
    id () {
      return this.$route.params['list_id']
    },
    seasonId () {
      return this.$route.params['season_id']
    }
  },
  methods: {
    ...mapActions('list', [
      'show',
      'setLoading',
      'getCustomers',
      'setEditMode',
      'setFilterOption',
      'resetFilter',
      'download'
    ]),
    loadList () {
      this.show(this.id)
        .then(() => {
          let filterOption = this.filter.selected
          if (!this.isPhysicalSignup && this.isPreFinalization) {
            filterOption = null
          }
          if (this.isAutomaticFlow && !this.isAfterNotSelectedSent) {
            filterOption = null
          }
          return this.setFilterOption({
            option: 'selected',
            value: filterOption
          })
        }).then(() => {
          if (!this.shouldBeRedirectedToWaitlist) {
            this.loadCustomers()
          } else {
            this.$router.push({
              name: 'store.waitlist',
              params: {
                waitlist_id: this.list.store_id
              }
            })
          }
        }).catch(() => {
          this.$router.push({ name: 'seasons' })
        })
    },
    async loadCustomers (setLoading = true) {
      try {
        if (setLoading) this.setLoading(true)
        await this.getCustomers()
        this.setLoading(false)
      } catch (err) {
        console.error('Error loading customers')
      }
    },
    handlePageSize (limit) {
      this.setFilterOption({
        option: 'limit',
        value: limit
      })
    },
    updateSearchFilter (value) {
      this.setFilterOption({
        option: 'searchText',
        value
      })
    },
    onUpdate () {
      this.editMode = false
      this.loadCustomers()
    },
    onUpdateRelease () {
      this.loadCustomers()
    },
    onCustomerCreate () {
      this.showAddCustomer = false
      if (this.isScheduled) {
        this.loadList()
      } else {
        this.loadCustomers()
      }
    },
    uploadCSV () {
      this.showUploadCSV = true
    },
    downloadCSV () {
      this.download(this.list.id)
        .then(res => {
          let queues = this.$localStorage.get('download_queues') || []
          queues.push(res)
          this.$localStorage.set('download_queues', queues)
          this.$message({
            message: 'The file will be downloaded once it is generated.',
            type: 'success'
          })
        })
    },
    initCustomer () {
      this.customer = {
        first_name: '',
        last_name: '',
        phone: '',
        timeslot: ''
      }
    },
    startInterval () {
      this.intervalid = setInterval(() => {
        this.$store.dispatch('release/list', { season_id: this.seasonId })
          .then(() => {
            this.validateRedirect()
          }).catch(() => {
            this.$router.push({
              name: 'season.releases',
              params: {
                season_id: this.seasonId
              }
            })
          })
      }, 5000)
    },
    validateRedirect () {
      if (!this.activeList || (this.activeList && this.activeList.id !== parseInt(this.id, 10))) {
        this.$router.push({
          name: 'season.releases',
          params: {
            season_id: this.seasonId
          }
        })
      }
    },
    async confirmRedistribution () {
      this.setLoading(true)
      this.confirm_modal = false
      try {
        await redistributeList(this.list.id, {
          day: this.filter.spanIndex
        })
        await this.loadCustomers()
      } catch (err) {
        console.error(err)
      }
    },
    toggleRedistributeList () {
      this.confirm_modal = true
    }
  }
}
</script>

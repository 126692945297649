import request from '@/utils/request'

export function load (params) {
  return request({
    url: '/customers',
    method: 'get',
    params: params
  })
}

export function search (params) {
  return request({
    url: '/customers/search',
    method: 'get',
    params: params
  })
}

export function create (data) {
  return request({
    url: `/customers/`,
    method: 'post',
    data: data
  })
}

export function update (data) {
  return request({
    url: `/customers/${data.id}`,
    method: 'patch',
    data: data
  })
}

export function destroy (id) {
  return request({
    url: `/customers/${id}`,
    method: 'delete'
  })
}

export function sendSMS (data) {
  return request({
    url: `/customers/send_sms`,
    method: 'post',
    data: data
  })
}

export function loadEvents (params) {
  return request({
    url: `/customers/${params.id}/histories`,
    method: 'get',
    params: params
  })
}

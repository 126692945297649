import request from '@/utils/request'

export function list (params) {
  return request({
    url: `/releases`,
    method: 'get',
    params: params
  })
}

export function validate (data) {
  return request({
    url: `/releases/validate`,
    method: 'post',
    data: data
  })
}

export function create (data) {
  return request({
    url: `/releases`,
    method: 'post',
    data: data
  })
}

export function show (data) {
  return request({
    url: `/releases/${data.release_id}`,
    method: 'get',
    params: data
  })
}

export function update (data) {
  return request({
    url: `/releases/${data.id}`,
    method: 'patch',
    data: data
  })
}

export function destroy (id) {
  return request({
    url: `/releases/${id}`,
    method: 'delete'
  })
}

import request from '@/utils/request'

export function load (inviteToken) {
  return request({
    url: `/invites/${inviteToken}`,
    method: 'get'
  })
}

export function accept (data) {
  return request({
    url: `/invites/accept`,
    method: 'post',
    data: data
  })
}

<template>
  <el-form
    class="customer-edit-form"
    @submit="updateCustomer">
    <el-row :gutter="20">
      <el-col
        :lx="6"
        :lg="8"
        :md="12"
        :sm="24">
        <el-row :gutter="20">
          <el-col
            :lg="24"
            :md="24"
            :sm="8"
            :xs="5"
          >
            <el-form-item
              label="Label"
              class="label-list"
              v-if="!hideLabelForm"
            >
              <el-radio-group v-model="model.label">
                <el-radio
                  v-for="label in labels"
                  :key="label.value"
                  :label="label.value"
                  class="display-block mb-seven"
                  :disabled="isReadOnly || notPermittedStore"
                >{{label.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col
            :lg="24"
            :md="24"
            :sm="14"
            :xs="14"
          >
            <el-form-item label="Contact Information">
              <ul class="info-list">
                <li v-if="model.e164_phone">{{customer.e164_phone}}</li>
                <li v-if="model.email">{{customer.email}}</li>
              </ul>
            </el-form-item>
          </el-col>
          <el-col
            :lg="24"
            :md="24"
            :sm="8"
            :xs="14"
          >
            <el-form-item label="Metrics">
              <ul class="info-list">
                <li>{{model.history.check_in}} Check-ins</li>
                <li>{{model.history.entered}} Entries</li>
                <li>{{model.history.no_show}} No-shows</li>
                <li>{{model.history.late}} Late</li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col
        :lx="18"
        :lg="16"
        :md="12"
        :sm="24"
      >
        <el-form-item
          label="Note"
          :show-message="errors.has('note')"
          :error="errors.first('note')">
          <el-input
            rows="5"
            type="textarea"
            v-model="model.note"
            data-vv-name="note"
            :disabled="isReadOnly || notPermittedStore"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="History"
          v-if="loadedHistoryCount > 0"
        >
          <el-collapse-transition>
            <el-button
              size="small"
              @click.prevent="showHistory=true"
              v-if="!showHistory"
            >Show History</el-button>
          </el-collapse-transition>
          <el-collapse-transition>
            <div
              class="action-events"
              v-if="showHistory"
            >
              <div class="action-event"
                v-for="(actionsByDate, date) in customerActions"
                :key="date">
                <p class="event-date">{{date | moment("MMMM D")}}</p>
                <div class="event-content"
                  v-for="(action, key) in actionsByDate"
                  :key="key">{{action.message}}
                  <span class="event-time">{{action.created_at | moment("LT z")}}</span>
                  <div class="divider"></div>
                </div>
              </div>
              <el-link
                v-if="model.action_count > loadedHistoryCount"
                icon="el-icon-plus"
                class="load-more"
                :underline="false"
                @click.prevent="triggerLoadMoreEvents(model.id, loadedHistoryCount + 3)">
                View More History
              </el-link>
            </div>
          </el-collapse-transition>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item v-if="!(isReadOnly || notPermittedStore)">
      <el-button
        type="danger"
        class="float-left"
        @click.prevent="deleteModal = true"
        size="medium"
        v-if="!customerIndex && canRemoveCustomerFromList"
        data-cy-label="remove-expanded-customer-button"
      >
        <span class="hidden-xs-only">Remove from List</span>
        <i class="hidden-sm-and-up el-icon-delete"></i>
      </el-button>
      <el-button
        type="primary"
        class="float-right  m-l-10"
        :loading="loading"
        size="medium"
        @click.prevent="updateCustomer"
        data-cy-label="save-expanded-customer-button"
      >
        <span class="hidden-xs-only">Save</span>
        <i class="hidden-sm-and-up el-icon-check"></i>
      </el-button>
      <el-button
        type="default"
        class="float-right"
        size="medium"
        @click="$emit('cancel')"
        data-cy-label="cancel-expanded-customer-button"
      >
        <span class="hidden-xs-only">Cancel</span>
        <i class="hidden-sm-and-up el-icon-close"></i>
      </el-button>
    </el-form-item>
    <DeleteModule
      :open="deleteModal"
      title="Do you want to remove this customer from this list?"
      description="You cannot undo this action"
      @on-close="deleteModal = false"
      @onRemove="handleRemoveCustomer" />
  </el-form>
</template>
<script>
import { mapActions } from 'vuex'
import DeleteModule from '@/views/components/delete-module.vue'
import permissionMixin from '@/mixins/permissions'
import moment from 'moment'
import { labels } from '@/consts'

export default {
  name: 'customer-form',
  components: {
    DeleteModule
  },
  props: [
    'customer',
    'customerIndex',
    'list',
    'waitlist',
    'hideLabelForm'
  ],
  mixins: [ permissionMixin ],
  data () {
    return {
      loading: false,
      labels: labels,
      model: Object.assign({}, this.customer),
      deleteModal: false,
      showHistory: false
    }
  },
  computed: {
    customerActions () {
      return this.model.actions.reduce((cleaned, action) => {
        const date = moment(action.created_at).format('YYYY-MM-D')
        if (!cleaned[date]) {
          cleaned[date] = []
        }
        cleaned[date].push({
          ...action,
          message: action.message.replace('{customer}', this.customer.full_name)
        })
        return cleaned
      }, {})
    },
    loadedHistoryCount () {
      return this.model.actions.length
    }
  },
  methods: {
    ...mapActions('customer', [
      'refresh',
      'remove',
      'update',
      'loadMoreEvents'
    ]),
    ...mapActions('list', [
      'getCustomers',
      'removeCustomer',
      'setLoading'
    ]),
    ...mapActions('waitlist', [
      'removeWaitlistCustomer'
    ]),
    updateCustomer () {
      this.$validator.validateAll()
        .then(res => {
          if (!res) {
            return
          }
          this.loading = true
          this.update(this.model)
            .then(data => {
              this.model = Object.assign({}, data)
              this.loading = false
              this.customerUpdated()
            }).catch(() => {
              console.warn('Error updating customer')
            })
        })
    },
    customerUpdated () {
      let message = this.$t('activity.updated', { name: 'customer' })
      this.$message({
        message: message,
        type: 'success'
      })
      this.$emit('updated', this.customer)
    },
    async handleRemoveCustomer () {
      this.deleteModal = false
      this.setLoading(true)
      try {
        if (this.list) {
          await this.removeCustomer({
            id: this.list.id,
            customer: Object.assign({}, this.model)
          })
        } else if (this.waitlist) {
          await this.removeWaitlistCustomer({
            id: this.waitlist.id
          })
        } else {
          await this.remove(this.model.id)
        }

        this.$message({
          message: this.$t('activity.deleted', { name: 'customer' }),
          type: 'success'
        })
        this.$emit('customerRemoved')
      } catch (err) {
        console.error(err)
      } finally {
        this.setLoading(false)
      }
    },
    triggerLoadMoreEvents (id, limit) {
      this.loadMoreEvents({
        id,
        limit
      }).then(actions => {
        this.model.actions = actions.slice(0)
      })
    }
  }
}
</script>

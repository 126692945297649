<template>
  <el-dialog
    title="Add a New Customer"
    class="el-dialog-new-customer"
    :visible.sync="show"
    @open="initDialog"
    center
  >
    <el-row :gutter="20">
      <el-col
        :sm="12"
        :xs="24"
      >
        <el-form
          label-position="top"
        >
          <el-row
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                :show-message="errors.has('phone number')"
                :error="errors.first('phone number')"
              >
                <vue-phone-number-input
                  v-model="customerPhone"
                  :default-country-code="defaultCountry"
                  :translations="{
                    countrySelectorLabel: null,
                    countrySelectorError: null,
                    phoneNumberLabel: null
                  }"
                  :loader="searching"
                  :no-example="true"
                  @update="onPhoneUpdate"
                ></vue-phone-number-input>
                <div class="el-asterisks">
                  <span v-if="customer.note" class="has-note">*</span>
                  <span v-if="customer.label" class="has-label" :class="`label-${customer.label}`">*</span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                :show-message="errors.has('first name')"
                :error="errors.first('first name') ? 'First Name Required.' : ''"
              >
                <el-input
                  v-model="customer.first_name"
                  placeholder="First Name"
                  data-vv-name="first name"
                  v-validate="'required'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                :show-message="errors.has('last name')"
                :error="errors.first('last name') ? 'Last Name Required.' : ''"
              >
                <el-input
                  v-model="customer.last_name"
                  placeholder="Last Name"
                  data-vv-name="last name"
                  v-validate="'required'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row
            :gutter="20"
            v-if="datePicker"
          >
            <el-col :span="24">
              <el-form-item
              >
                <template slot="label">
                  <strong>Date</strong>
                </template>
                <el-date-picker
                  v-model="customer.booking_date"
                  :picker-options="pickerOptions"
                  type="date"
                  placeholder="Pick a day">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
              >
                <template slot="label">
                  <strong>Timeslot</strong>
                </template>
                <el-select
                  class="full-width"
                  v-model="customer.timeslot"
                  :placeholder="isSecurity ? 'Waitlist' : 'Select a time slot'"
                  :disabled="timeslotDisabled || isSecurity"
                >
                  <el-option value="waitlist" label="Waitlist"></el-option>
                  <template v-if="list">
                    <fragment v-for="(time_slot_limit, span_index) in list.time_slot_limits" :key="span_index">
                      <el-option
                        v-for="(limit, timeslot) in time_slot_limit"
                        :key="timeslot"
                        :label="`${releaseDay(list.release.release_date, span_index)} ${isEuStore(list.store.country) ? timeslot : $options.filters.standard_time(timeslot)}`"
                        :value="`${span_index}-${timeslot}`"
                      ></el-option>
                    </fragment>
                  </template>
                  <template v-else>
                    <el-option
                      v-for="(timeslot, index) in timeslots"
                      :key="index"
                      :label="`${isEuStore(store.country) ? timeslot : $options.filters.standard_time(timeslot)}`"
                      :value="timeslot"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            v-if="canAssignTicket"
          >
            <el-col :span="24">
              <el-form-item
              >
                <template slot="label">
                  <strong>Ticket Number</strong> (optional)
                </template>
                <el-input
                  v-model="customer.ticket"
                  placeholder="Add a Ticket Number"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
      <el-col
        :sm="12"
        :xs="24"
      >
        <el-row :gutter="20">
          <el-col :span="24" v-if="searching">
            <h4>Searching customer database ...</h4>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="id && !searching">
          <el-col :span="24">
            <h3>Existing customer found!</h3>
            <p>Replacing will delete the existing customer.</p>
            <el-button
              type="info"
              size="small"
              @click.prevent="replaceCustomer"
            >Replace Customer</el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <template slot="footer" class="dialog-footer">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-button
            @click="$emit('on-close-dialog')"
            class="float-left"
          >Cancel</el-button>
          <el-button type="primary"
            class="float-right"
            :loading="loading"
            @click.prevent="createCustomer"
            :disabled="!canAddCustomer"
          >Add</el-button>
        </el-col>
        <el-col :span="12">
          &nbsp;
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import calcMixin from '@/mixins/calc'
import listStates from '@/mixins/listStates'
import permissionMixin from '@/mixins/permissions'
import moment from 'moment'
export default {
  name: 'customer-physical-flow-add-form',
  props: ['show_form', 'list', 'customer', 'store', 'datePicker'],
  mixins: [ calcMixin, listStates, permissionMixin ],
  computed: {
    show: {
      get () {
        return this.show_form
      },
      set () {
        this.$emit('on-close-dialog')
      }
    },
    timeslots () {
      if (this.list) {
        return this.getTimeSlotList(this.list.first_time_slot, this.list.last_time_slot, this.list.time_slot_interval)
      } else if (this.store) {
        return this.getTimeSlotList(this.store.waitlist_first_slot, this.store.waitlist_last_slot, this.store.waitlist_slot_interval)
      } else {
        return []
      }
    },
    timeslotDisabled () {
      return this.list &&
        ((!this.isPhysicalSignup && this.isPreFinalization) ||
        (this.isAutomaticFlow && this.isBeforeClose))
    },
    canAssignTicket () {
      return this.list && this.isManualFlow
    },
    customerFieldsInputted () {
      return this.customer.first_name && this.customer.last_name && this.customer.phone
    },
    canAddCustomer () {
      if (this.timeslotDisabled || this.isSecurity) {
        return this.customerFieldsInputted
      } else {
        return this.customerFieldsInputted && this.customer.timeslot
      }
    }
  },
  data () {
    return {
      loading: false,
      id: null,
      searching: false,
      customerPhone: '',
      defaultCountry: '',
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > moment().add(6, 'days').toDate() || time.getTime() < moment().subtract(1, 'days').toDate()
        }
      }
    }
  },
  methods: {
    searchCustomers () {
      this.searching = true
      this.$store.dispatch('customer/search', this.customer)
        .then(res => {
          if (res.id) {
            this.id = res.id
            this.customer.id = res.id
            this.customer.first_name = res.first_name
            this.customer.last_name = res.last_name
            this.customer.label = res.label
            this.customer.note = res.note
          }
        }).catch(err => {
          console.log(err)
        }).then(() => {
          this.searching = false
        })
    },
    createCustomer () {
      this.$validator.validateAll()
        .then(res => {
          if (res) {
            this.loading = true
            if (this.list) {
              this.addCustomerToList()
            } else {
              this.addCustomerToStore()
            }
          }
        })
    },
    addCustomerToList () {
      this.$store.dispatch('list/addCustomer', {
        id: this.list.id,
        customer: this.customer
      }).then(() => {
        let message = this.$t('activity.created', { name: 'customer' })
        this.$message({
          message: message,
          type: 'success'
        })
        this.$emit('on-create-customer')
        this.initDialog()
      }).catch(() => {
      }).then(() => {
        this.loading = false
      })
    },
    replaceCustomer () {
      this.$store.dispatch('customer/update', this.customer)
        .then(() => {
          let message = this.$t('activity.updated', { name: 'Customer' })
          this.$message({
            message: message,
            type: 'success'
          })
        }).catch(err => {
          console.log(err)
        })
    },
    addCustomerToStore () {
      this.$store.dispatch('waitlist/addCustomer', {
        id: this.store.id,
        customer: this.customer
      }).then(() => {
        let message = this.$t('activity.created', { name: 'customer' })
        this.$message({
          message: message,
          type: 'success'
        })
        this.$emit('on-create-customer')
        this.initDialog()
      }).catch(() => {
      }).then(() => {
        this.loading = false
      })
    },
    onPhoneUpdate (phone) {
      this.customer.phone = phone.e164
      if (phone.isValid) {
        this.searchCustomers()
      } else {
        this.customer.id = null
        this.id = null
      }
    },
    initDialog () {
      this.$emit('on-init')
      this.defaultCountry = this.list ? this.list.store.country : this.store.country
      this.customer.first_name = null
      this.customer.last_name = null
      this.customer.id = null
      this.customer.phone = null
      this.customerPhone = null
      this.customer.ticket = null
      this.customer.note = null
      this.customer.label = null
    }
  }
}
</script>

import request from '@/utils/request'

export function list () {
  return request({
    url: `/stores`,
    method: 'get'
  })
}

export function create (data) {
  return request({
    url: `/stores`,
    method: 'post',
    data: data
  })
}

export function update (data) {
  return request({
    url: `/stores/${data.id}`,
    method: 'patch',
    data: data
  })
}

export function remove (id) {
  return request({
    url: `/stores/${id}`,
    method: 'delete'
  })
}

import request from '@/utils/request'

export function load () {
  return request({
    url: `/settings`,
    method: 'get'
  })
}

export function save (data) {
  return request({
    url: `/settings`,
    method: 'post',
    data: data
  })
}

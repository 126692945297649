import Vue from 'vue'
import humanizeString from 'humanize-string'
import moment from 'moment-timezone'

Vue.filter('humanize', function (value) {
  if (value) {
    try {
      return humanizeString(value)
    } catch (e) {
      console.error('[filter#humanize] Error:', e)
    }
  }
})

Vue.filter('initials', function (value) {
  if (value) {
    try {
      let initials = value.match(/\b\w/g) || []
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    } catch (e) {
      console.error('[filter#initials] Error:', e)
    }
  }
})

Vue.filter('capitalize', function (value) {
  if (value) {
    try {
      return value.replace(/\b\w/g, l => l.toUpperCase())
    } catch (e) {
      console.error('[filter#capitalize] Error:', e)
    }
  }
})

Vue.filter('percent', function (value) {
  if (value) {
    try {
      if (value === '-') {
        value = 0
      }
      return `(${value}%)`
    } catch (e) {
      console.error('[filter#percent] Error:', e)
    }
  }
})

Vue.filter('date_time_string', function (timestamp, timezone = 'GMT', normalize = true) {
  if (timestamp) {
    try {
      const formatTime = normalize ? `hh:mm A z` : `HH:mm z`
      return moment.tz(timestamp, timezone).format(`dddd, MMM D [at] ${formatTime}`)
    } catch (e) {
      console.error('[filter#percent] Error:', e)
    }
  }
})

Vue.filter('standard_time', function (time = '', normalize = true) {
  const format = normalize ? 'hh:mm A' : 'HH:mm'
  return time ? moment(time, 'HH:mm').format(format) : ''
})

Vue.filter('abbr_timezone', function (timezone) {
  if (timezone) {
    try {
      return moment.tz(new Date(), timezone).format('z')
    } catch (e) {
      console.error('[filter#standard_time] Error:', e)
    }
  }
})

<template>
  <el-row :gutter="20">
    <el-col :span="24" class="text-right">
      <div class="display-inline" v-if="customers.length">
        <span class="text-secondary">Sort by</span>
        <el-select
          v-model="filter.orderBy"
          class="ml-seven"
          size="medium"
          placeholder="Select">
          <el-option label="Created At" value="created_at desc"></el-option>
          <el-option label="Name A-Z" value="first_name asc"></el-option>
          <el-option label="Name Z-A" value="first_name desc"></el-option>
        </el-select>
      </div>
    </el-col>
    <el-col :span="24" class="el-table-wrapper has-border">
      <el-table
        v-if="customers.length"
        v-loading="loading"
        :data="customers || []"
        :row-key="row => row.id"
        :expand-row-keys="expandRowKeys"
        ref="customerstb"
        @row-click="expandRow">
        <el-table-column
          label="Name"
          min-width="180"
        >
          <template
            slot-scope="scope"
          >
            {{scope.row.full_name}}
            <span v-if="scope.row.note" class="asterisks">*</span>
            <span
              v-if="scope.row.label"
              class="asterisks"
              :class="`label-${scope.row.label}`"
            >*</span>
          </template>
        </el-table-column>
        <el-table-column
          label="CC"
          min-width="60"
          prop="last_4" />
        <el-table-column
          label="Status"
          min-width="100"
          prop="status">
          <template slot-scope="scope">
            <customer-status :customer="scope.row" />
          </template>
        </el-table-column>
      </el-table>
      <div v-else class="el-table el-table--fit el-table--enable-row-hover">
        <table width="100%">
          <empty-list :showSelected="true" />
        </table>
      </div>
      <div v-if="displayCustomer">
        <el-dialog
          :title="getExpandedCustomer.full_name"
          :visible.sync="displayCustomer"
          width="700px"
          center>
          <customer-form
            :list="list"
            :hideLabelForm="!canSeeAsterisks"
            @customerRemoved="$emit('refreshCustomers')"
            :labels="labels()"
            @updated="getCustomers"
            @cancel="cancel"
            :customer="getExpandedCustomer"
            />
        </el-dialog>
      </div>
      <div class="el-table__append-wrapper">
        <el-pagination
          @sizeChange="handlePageSize"
          :current-page.sync="filter.page"
          :page-sizes="[25, 50, 75, 100]"
          :pager-count="5"
          :page-size="filter.limit"
          layout="sizes, prev, pager, next, jumper"
          :total="totals"
          hide-on-single-page>
        </el-pagination>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import CustomerForm from '@/views/admin/global/customer-form'
import CustomerStatus from '@/views/admin/lists/customer-status'
import ElPagination from '@/views/components/pagination'
import EmptyList from '@/views/admin/lists/empty-list'
import permissionMixins from '@/mixins/permissions'

export default {
  name: 'pre-finalization',
  components: {
    CustomerForm,
    CustomerStatus,
    ElPagination,
    EmptyList
  },
  mixins: [ permissionMixins ],
  computed: {
    ...mapState({
      loading: state => state.list.isLoading,
      filter: state => state.list.filter,
      list: state => state.list.list,
      totals: state => state.list.customers.totals,
      customers: state => state.list.customers.customers
    }),
    ...mapGetters({
      labels: 'list/getCustomerLabels'
    }),
    id () {
      return this.$route.params.list_id
    },
    season_id () {
      return this.$route.params.season_id
    },
    displayCustomer: {
      get () {
        return this.expandRowKeys.length
      },
      set () {
        this.expandRowKeys = []
        this.getExpandedCustomer = { full_name: '' }
      }
    }
  },
  data () {
    return {
      expandRowKeys: [],
      getExpandedCustomer: {
        full_name: ''
      }
    }
  },
  methods: {
    ...mapActions('customer', [
      'refresh'
    ]),
    ...mapActions('list', [
      'getCustomers'
    ]),
    handlePageSize (limit) {
      this.$emit('sizeChange', limit)
    },
    expandRow (row) {
      const lastId = this.expandRowKeys[0]
      this.expandRowKeys = row.id === lastId ? [] : [row.id]
      this.getExpandedCustomer = this.customers.find(({ id }) => id === row.id)
    },
    cancel (customer) {
      this.refresh(customer)
        .then(() => {
          this.expandRowKeys = []
        })
    }
  }
}
</script>

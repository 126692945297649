<template>
  <fragment>
    <td v-if="editMode" class="el-check">
      <el-checkbox
        :value="isSelectedByTimeSlot"
        @input="$emit('timeslotSelection', timeslot)" />
    </td>
    <td v-else class="el-check" />
    <template v-if="isReleaseOpen">
      <td colspan="1" class="hidden-xs-only">
        <span v-if="weekday">{{ weekday }}</span> {{slotTime | standard_time(normalizeTime) }}
        <span>
          ({{timeslot.customers.length}})
        </span>
      </td>
      <td colspan="6" class="hidden-sm-and-up">
        <span v-if="weekday">{{ weekday }}</span> {{slotTime | standard_time(normalizeTime)}}
        <span>
          ({{timeslot.customers.length}})
        </span>
      </td>
      <td colspan="5" class="text-right pr-ten hidden-xs-only">
        <span class="mr-fifteen" v-if="!filter.searchText">
          <span class="text-light">Expected:</span> {{timeslot.customers.length}}
        </span>
        <span class="mr-fifteen" v-if="!filter.searchText">
          <span class="text-light">Checked-in:</span> {{timeslotCheckin(timeslot.customers).checkin}} {{timeslotCheckin(timeslot.customers).percent | percent}}
        </span>
        <span v-if="!filter.searchText">
          <span class="text-light">Entered:</span> {{timeslotEntered(timeslot.customers).entered}} {{timeslotEntered(timeslot.customers).percent | percent}}
        </span>
      </td>
    </template>
    <template v-else>
      <td :colspan="isReleaseOpen || !isPhysicalSignup ? 6 : 7">
        <span v-if="weekday">{{ weekday }}</span> {{slotTime | standard_time(normalizeTime)}}
        <span v-if="list.status >= 8">
          ({{timeslot.customers.length}}/{{timeslotLimit}})
        </span>
      </td>
    </template>
  </fragment>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import listStates from '@/mixins/listStates'

export default {
  name: 'timeslot-groups-header',
  props: [ 'timeslot', 'isSelectedByTimeSlot', 'weekday', 'normalizeTime' ],
  mixins: [ listStates ],
  computed: {
    ...mapState({
      editMode: state => (state.waitlist.editMode || state.list.editMode),
      list: state => state.list.list,
      filter: state => state.list.filter
    }),
    slotTime () {
      return this.timeslot.timeslot.split('->').pop()
    },
    spanIndex () {
      return this.filter.spanIndex
    },
    timeslotLimit () {
      return this.spanIndex === 'all'
        ? this.list.time_slot_limits.map(limit => limit[this.slotTime]).reduce((a, b) => a + b, 0)
        : this.list.time_slot_limits[this.spanIndex][this.slotTime]
    }
  },
  methods: {
    ...mapActions('customer', [
      'refresh'
    ]),
    timeslotCheckin (customers) {
      let expected = customers.length
      let checkin = customers.filter(customer => customer.check_in).length
      let percent = expected > 0 ? (checkin / expected * 100).toFixed(1) : 0
      return {
        checkin: checkin,
        percent: percent
      }
    },
    timeslotEntered (customers) {
      let expected = customers.length
      let entered = customers.filter(customer => customer.entered).length
      let percent = expected > 0 ? (entered / expected * 100).toFixed(1) : 0
      return {
        entered: entered,
        percent: percent
      }
    }
  }
}
</script>

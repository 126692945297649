import moment from 'moment'
import { labels } from '@/consts'
export default {
  methods: {
    rangeArray (start, stop, step = 1) {
      return Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)
    },
    getTimeSlotList (start, end, step) {
      let times = []
      if (start && end && step) {
        for (var m = moment(`2000-01-01 ${start}`); m.isBefore(`2000-01-01 ${end}`); m.add(step, 'minutes')) {
          times.push(m.format('HH:mm'))
        }
        times.push(end)
      }
      return times
    },
    percent (value1 = 0, value2) {
      if (value2 && value2 !== 0) {
        return ((value1 / value2) * 100).toFixed(2)
      } else {
        return '-'
      }
    },
    labelByValue (value) {
      return labels.find(label => label.value === value).label
    },
    releaseDay (date, spanIndex) {
      return moment(date).add(spanIndex, 'days').format('dddd')
    },
    isEuStore (country) {
      return ['fr', 'gb', 'de', 'it', 'nl', 'es'].includes(country.toLowerCase())
    },
    sumValues (obj) {
      return Object.values(obj).reduce((a, b) => a + b)
    }
  }
}

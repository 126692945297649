import { list, create, update, remove } from '@/api/season'

const season = {
  namespaced: true,
  state: {
    seasons: [],
    has_more: false
  },
  actions: {
    list ({ commit }, params) {
      return new Promise((resolve, reject) => {
        list(params).then(res => {
          const data = res.data
          commit('SET_SEASONS', data)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    create ({ commit }, data) {
      return new Promise((resolve, reject) => {
        create(data).then(res => {
          commit('ADD_SEASON', res.data)
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    update ({ commit }, data) {
      return new Promise((resolve, reject) => {
        update(data).then(res => {
          const data = res.data
          commit('UPDATE_SEASON', data)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    remove ({ commit }, id) {
      return new Promise((resolve, reject) => {
        remove(id).then(res => {
          commit('REMOVE_SEASON', id)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  mutations: {
    SET_SEASONS: (state, data) => {
      state.seasons = data.seasons
      state.has_more = data.has_more
    },
    ADD_SEASON: (state, data) => {
      state.seasons = [data, ...state.seasons]
    },
    UPDATE_SEASON: (state, data) => {
      let seasons = state.seasons
      seasons = seasons.map(season => {
        if (season.id === data.id) {
          return data
        } else {
          return season
        }
      })
      state.seasons = seasons
    },
    REMOVE_SEASON: (state, id) => {
      let seasons = state.seasons
      seasons = seasons.filter(season => season.id !== +id)
      state.seasons = seasons
    }
  },
  getters: {
    getSeasons: (state) => () => {
      return state.seasons
    },
    hasMoreSeasons: (state) => () => {
      return state.has_more
    }
  }
}

export default season

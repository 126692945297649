import { listStatusObj } from '@/consts'
import moment from 'moment'

export default {
  computed: {
    isReleaseOpen () {
      return this.list.status >= listStatusObj.timeSlotDetailSent
    },
    isScheduled () {
      return this.list.status >= listStatusObj.scheduled
    },
    isAfterOpen () {
      return this.list.status >= listStatusObj.open
    },
    isOpen () {
      return this.list.status === listStatusObj.open
    },
    isClosed () {
      return this.list.status >= listStatusObj.close
    },
    isBeforeClose () {
      return this.list.status < listStatusObj.close
    },
    isAfterFirstRoundSent () {
      return this.list.status >= listStatusObj.firstRoundSent
    },
    isAfterSecondRoundSent () {
      return this.list.status >= listStatusObj.secondRoundSent
    },
    isAfterNotSelectedSent () {
      return this.list.status >= listStatusObj.notSelectedSent
    },
    isInProgress () {
      return this.list.status === listStatusObj.inProgress
    },
    isReleaseComplete () {
      return this.list.status === listStatusObj.complete
    },
    isPreFinalization () {
      return this.list.status < listStatusObj.finalization
    },
    isNotSelectedSent () {
      return this.list.status === listStatusObj.notSelectedSent
    },
    isFinalization () {
      return this.list.status === listStatusObj.finalization
    },
    isPhysicalFinalization () {
      return this.isPhysicalSignup && this.list.status >= listStatusObj.open && this.list.status <= listStatusObj.finalization
    },
    afterDetailSent () {
      return this.list.status >= listStatusObj.timeSlotDetailSent
    },
    isPhysicalSignup () {
      return !!this.list.is_physical_signup
    },
    isAutomaticFlow () {
      return this.isPhysicalSignup && this.list.is_automatic_physical_flow
    },
    isManualFlow () {
      return this.isPhysicalSignup && !this.list.is_automatic_physical_flow
    },
    isCSVUploaded () {
      return this.isPhysicalSignup && this.list.status >= listStatusObj.open
    },
    isInSelections () {
      return !this.isPhysicalSignup && (
        this.list.status >= listStatusObj.firstRoundSent && this.isPreFinalization
      )
    },
    isScheduledToday () {
      return this.list.release.release_date === moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    badgeEffect (listStatus) {
      if (listStatus < listStatusObj.close) {
        return 'dark'
      } else {
        return 'light'
      }
    },
    badgeType (listStatus) {
      let type = null

      switch (this.rstatus.value) {
        case listStatusObj.notSelected:
          type = 'info'
          break
        case listStatusObj.excluded:
          type = 'info'
          break
        case listStatusObj.scheduled:
          type = 'info'
          break
        case listStatusObj.open:
          type = 'success'
          break
        default:
          type = null
      }
      return type
    },
    badgeTextStatus (listStatus) {
      return listStatus >= listStatusObj.firstRoundSent
    }
  }
}

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      current_user: 'auth/currentUser'
    }),
    canSeeSidebar () {
      return ['admin', 'manager'].includes(this.current_user.role)
    },
    canAccessStore () {
      return ['admin', 'manager'].includes(this.current_user.role)
    },
    canManageStore () {
      return ['admin'].includes(this.current_user.role)
    },
    hasRestrictedStores () {
      return ['manager', 'security', 'advanced_security'].includes(this.current_user.role)
    },
    notPermittedStore () {
      return this.hasRestrictedStores && !this.current_user.stores.includes(this.list.store_id)
    },
    canManageUsers () {
      return this.current_user.role === 'admin'
    },
    canReadCustomers () {
      return ['admin', 'manager'].includes(this.current_user.role)
    },
    canManageSettings () {
      return this.current_user.role === 'admin'
    },
    canScheduleRelease () {
      return ['admin', 'manager'].includes(this.current_user.role)
    },
    canManageRelease () {
      return ['admin'].includes(this.current_user.role)
    },
    canManageSeasons () {
      return ['admin', 'manager'].includes(this.current_user.role)
    },
    canMoveTimeSlots () {
      return ['admin', 'manager', 'advanced_security'].includes(this.current_user.role)
    },
    canEditList () {
      return ['admin', 'manager', 'advanced_security'].includes(this.current_user.role)
    },
    canManageCustomer () {
      return ['admin', 'manager'].includes(this.current_user.role)
    },
    canSendSMS () {
      return ['admin', 'manager', 'advanced_security'].includes(this.current_user.role)
    },
    isReadOnly () {
      return this.current_user.role === 'read_only'
    },
    isSecurity () {
      return this.current_user.role === 'security'
    },
    isAdvancedSecurity () {
      return this.current_user.role === 'advanced_security'
    },
    canSeeWaitlists () {
      return ['admin', 'manager'].includes(this.current_user.role)
    },
    canSeeAsterisks () {
      return ['admin', 'manager'].includes(this.current_user.role)
    },
    canRemoveCustomerFromList () {
      return ['admin', 'manager'].includes(this.current_user.role)
    }
  }
}

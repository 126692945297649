const navs = [
  { name: 'settings.stores', label: 'Store Settings' },
  { name: 'settings.global', label: 'Global Settings' },
  { name: 'settings.customers', label: 'Customer Index' },
  { name: 'settings.users', label: 'Users/Permissions' }
]

const roles = [
  'admin',
  'manager',
  'read_only',
  'security',
  'advanced_security'
]

const releaseTypes = [
  { label: 'Upcoming Releases', upcoming: true },
  { label: 'Past Releases', upcoming: false }
]

// status list for Normal List
const listStatuses = [
  { value: 0, label: 'Not Scheduled' },
  { value: 1, label: 'Excluded' },
  { value: 2, label: 'Scheduled' },
  { value: 3, label: 'Registration Open' },
  { value: 4, label: 'Registration Closed' },
  { value: 5, label: 'First Round of `Selected` sent' },
  { value: 6, label: 'Second Round of `Selected` sent' },
  { value: 7, label: '`Not Selected` sent' },
  { value: 8, label: 'List Finalization' },
  { value: 9, label: '`Time Slot Details` sent' },
  { value: 10, label: 'Release In Progress' },
  { value: 11, label: 'Release Complete' }
]
// status list for Physical Signup
const physicalStatuses = [
  { value: 0, label: 'Not Scheduled' },
  { value: 1, label: 'Excluded' },
  { value: 2, label: 'Scheduled' },
  { value: 3, label: 'Registration Open' },
  { value: 7, label: 'List Finalization' },
  { value: 8, label: 'List Finalization' },
  { value: 9, label: 'List Finalization' },
  { value: 10, label: 'Release In Progress' },
  { value: 11, label: 'Release Complete' }
]

const listStatusObj = {
  notSelected: 0,
  excluded: 1,
  scheduled: 2,
  open: 3,
  close: 4,
  firstRoundSent: 5,
  secondRoundSent: 6,
  notSelectedSent: 7,
  finalization: 8,
  timeSlotDetailSent: 9,
  inProgress: 10,
  complete: 11
}

const timeslotIntervals = [15, 30, 45]

const weekDays = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 0 }
]

const labels = [
  { value: 'n_a', label: 'n/a' },
  { value: 'vip', label: 'VIP' },
  { value: 'banned', label: 'Banned' }
]

const statuses = [
  { value: '1', label: 'Selected' },
  { value: '2', label: 'Not Selected' }
]

const listReleaseTabs = [
  { value: '1', label: 'Time Slots' },
  { value: '2', label: 'Waitlist' }
]

const whitelistCountries = ['GB', 'FR', 'DE', 'IT', 'NL', 'ES', 'MX', 'US']

const timezones = [
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/Mexico_City',
  'America/New_York',
  'Europe/London',
  'Europe/Madrid',
  'Europe/Paris',
  'Europe/Amsterdam',
  'Europe/Berlin',
  'Europe/Rome'
]

const domainMapping = {
  register: {
    iso: 'USA',
    shortCode: 'us'
  },
  paris: {
    iso: 'FRA',
    shortCode: 'fr'
  },
  london: {
    iso: 'GBR',
    shortCode: 'gb'
  }
}

const timezoneStamps = {
  pst:
    'PST|PST PDT PWT PPT|80 70 70 70|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261q0 1nX0 11B0 1nX0 SgN0 8x10 iy0 5Wp1 1VaX 3dA0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1a00 1fA0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|15e6',
  gmt: 'GMT|UTC|0|0|'
}

const closeRegAfterArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 60, 0]

const listDefaultFilter = state => ({
  orderBy: 'created_at desc',
  searchText: '',
  spanIndex: 'all',
  limit: parseInt(process.env.VUE_APP_PAGINATION_LIMIT, 10) || 100, // state?.list?.status > 4 ? 100 : 100,
  selected: '1',
  id: 0,
  page: 1
})

const toggleAttributes = {
  labels: {
    checked: 'YES',
    unchecked: 'NO'
  },
  colors: {
    checked: '#67c23a',
    unchecked: '#f56c6c'
  }
}

export {
  navs,
  roles,
  releaseTypes,
  listStatuses,
  physicalStatuses,
  listStatusObj,
  timeslotIntervals,
  weekDays,
  labels,
  statuses,
  listReleaseTabs,
  domainMapping,
  timezones,
  whitelistCountries,
  timezoneStamps,
  closeRegAfterArr,
  listDefaultFilter,
  toggleAttributes
}

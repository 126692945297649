import moment from 'moment'
import { list, validate, create, show, update, destroy } from '@/api/release'
import { listStatusObj } from '@/consts'

const release = {
  namespaced: true,
  state: {
    releases: [],
    stores: [],
    season: {},
    release: {},
    bookings: 0
  },
  actions: {
    list ({ commit, getters, rootState }, params) {
      const payload = {
        ...params,
        view_all: rootState.auth.viewMode
      }

      return new Promise((resolve, reject) => {
        list(payload).then(res => {
          const data = res.data
          commit('SET_RELEASES', data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    validate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        validate(data).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    create ({ commit }, data) {
      return new Promise((resolve, reject) => {
        create(data).then(res => {
          commit('ADD_RELEASE', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    show ({ commit, getters, rootState }, data) {
      const payload = {
        ...data,
        view_all: rootState.auth.viewMode
      }

      return new Promise((resolve, reject) => {
        show(payload).then(res => {
          commit('SET_RELEASE', res.data)
          commit('SET_STORES', res.data.stores)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    update ({ commit }, data) {
      return new Promise((resolve, reject) => {
        update(data).then(res => {
          commit('SET_RELEASE', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    destroy ({ commit }, id) {
      return new Promise((resolve, reject) => {
        destroy(id).then(res => {
          commit('REMOVE_RELEASE', id)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  mutations: {
    SET_RELEASES: (state, data) => {
      state.releases = data.releases
      state.season = data.season
      state.stores = data.stores
      state.bookings = data.bookings?.[0]?.storeId
    },
    ADD_RELEASE: (state, data) => {
      state.releases = [data, ...state.releases]
    },
    SET_RELEASE: (state, data) => {
      state.release = data
    },
    SET_STORES: (state, data) => {
      state.stores = data
    },
    REMOVE_RELEASE: (state, id) => {
      let releases = state.releases
      state.releases = releases.filter(release => release.id !== +id)
    }
  },
  getters: {
    getSeason: (state) => () => {
      return state.season
    },
    getReleases: (state) => (isUpcoming) => {
      return state.releases.filter(release => {
        const releaseDuration = Math.max(...release.lists.map(list => list.release_duration))
        return moment(release.release_date).add(releaseDuration - 1, 'days').isAfter(moment().subtract(1, 'days'), 'day') === isUpcoming
      })
    },
    getStores: (state) => {
      return state.stores
    },
    getRelease: (state) => () => {
      return state.release
    },
    getBookings: (state) => state.bookings,
    getActiveList (state, getters, rootState) {
      /*
       * TODO Refactored logic - test w/h SNY team
      const [{ list: availList = {} }, ...securityList] = state.releases.map(release => {
        const releaseList = release.lists.find(({ store_id: storeId = '', status = '' }) =>
          storeId === rootState.auth.user.store_id &&
          (status === status === listStatusObj.finalization && status <= listStatusObj.inProgress)
        )
        return { list: releaseList } || null
      }).filter(l => l.list)
      const [{ list: activeList = {} }] = [].concat({ list: availList }, securityList)
        .filter(({ list: { status = '' } = {} }) => status && status === listStatusObj.inProgress)
      return activeList || availList
      */
      let lists = state.releases.filter(release => {
        const releaseDuration = Math.max(...release.lists.map(list => list.release_duration))
        return moment(release.release_date).add(releaseDuration - 1, 'days').isAfter(moment().subtract(1, 'days'), 'day')
      }).map(release => release.lists).flat()

      const userStores = rootState?.auth?.user?.stores || []
      if (userStores.length) {
        lists = lists.filter(list => userStores.includes(list.store_id))
      }

      const inProgressList = lists.find(list => list.status === listStatusObj.inProgress)
      const timeSlotSentList = lists.find(list => list.status === listStatusObj.timeSlotDetailSent)
      return inProgressList || timeSlotSentList
    }
  }
}

export default release

<template>
  <el-container
    class="app"
    :class="sidebarClass"
  >
    <Sidebar
      @logo-redirect="logoRedirect"
    />
    <el-container
      class="wrap is-vertical admin-wrap"
    >
      <Header
        @logo-redirect="logoRedirect"
      />
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Sidebar from './sidebar-left'
import Header from './header'
import { mapGetters, mapState } from 'vuex'
import redirectionMixin from '@/mixins/redirection'

export default {
  name: 'layout',
  components: {
    Sidebar,
    Header
  },
  mixins: [redirectionMixin],
  computed: {
    ...mapState({
      limit: state => state.layout.limit
    }),
    ...mapGetters({
      sidebarClose: 'layout/isSidebarClose',
      sidebarStatic: 'layout/isSidebarStatic'
    }),
    sidebarClass () {
      return this.sidebarStatic()
        ? 'sidebar-static'
        : this.sidebarClose()
          ? 'sidebar-closed'
          : 'sidebar-opened'
    }
  },
  created () {
    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('orientationchange', this.handleOrientationChange, false)
    this.handleWindowResize()
    this.loadStores()
    this.loadSeasons().then((data) => {
      return this.conditionallyRedirect(data)
    })
  },
  methods: {
    logoRedirect () {
      this.loadSeasons().then((data) => {
        this.$router.push({
          name: 'seasons'
        }).then(() => {
          return this.conditionallyRedirect(data)
        })
      })
    },
    handleWindowResize () {
      this.$store.dispatch('layout/toggleSidebar')
    },
    handleOrientationChange () {
      this.$store.dispatch('layout/toggleSidebar')
    },
    loadSeasons () {
      return this.$store.dispatch('season/list', {
        store_handle: this.$route.query.store,
        limit: this.limit,
        default_store_id: this.$route.query.default_store_id
      })
    },
    loadStores () {
      this.$store.dispatch('store/list')
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('orientationchange', this.handleOrientationChange, false)
  }
}
</script>

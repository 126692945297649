import { load, update, destroy, invite } from '@/api/user'

const user = {
  namespaced: true,
  state: {
    users: [],
    invite: {},
    totals: 0
  },
  actions: {
    load ({ commit }, params) {
      return new Promise((resolve, reject) => {
        load(params)
          .then(res => {
            const data = res.data
            commit('SET_USERS', data)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    update ({ commit }, data) {
      return new Promise((resolve, reject) => {
        update(data)
          .then(res => {
            commit('UPDATE_USER', res.data)
            resolve(res.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    destroy ({ commit }, id) {
      return new Promise((resolve, reject) => {
        destroy(id)
          .then(res => {
            commit('REMOVE_USER', id)
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    invite ({ commit }, data) {
      return new Promise((resolve, reject) => {
        invite(data)
          .then(res => {
            commit('CREATE_INVITE', res.data)
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    revert ({ commit }, data) {
      return new Promise(resolve => {
        commit('UPDATE_USER', data)
        resolve()
      })
    }
  },
  mutations: {
    SET_USERS: (state, data) => {
      state.users = data.users
      state.totals = data.totals
    },
    REMOVE_USER: (state, id) => {
      state.users = state.users.filter(user => user.id !== +id)
      state.totals -= 1
    },
    UPDATE_USER: (state, data) => {
      let users = state.users
      state.users = []
      let index = users.findIndex(user => data && user.id === data.id)
      if (index > -1) {
        users[index] = data
      }
      state.users = users
    },
    CREATE_INVITE: (state, data) => {
      state.invite = data
    }
  },
  getters: {
    getUsers: state => filter => {
      let users = state.users ?? []
      console.log('users', users)
      if (!users?.length) {
        return users
      }
      if (filter?.order_by === 'name') {
        users = users?.sort((a, b) => {
          if (a?.full_name?.toLowerCase() < b?.full_name?.toLowerCase()) {
            return -1
          }
          if (a?.full_name?.toLowerCase() > b?.full_name?.toLowerCase()) {
            return 1
          }
          return 0
        })
      } else if (filter?.order_by === 'created_at') {
        users = users?.sort((a, b) => {
          if (a?.created_at > b?.created_at) {
            return -1
          }
          if (a?.created_at < b?.created_at) {
            return 1
          }
          return 0
        })
      }

      if (filter?.search_text && users?.length) {
        users = users
          ?.filter(user =>
            user?.full_name
              ?.toLowerCase()
              ?.includes(filter.search_text.toLowerCase())
          )
          .slice(0, filter.limit)
      } else {
        users = users?.slice(
          (filter.page - 1) * filter.limit,
          filter.page * filter.limit
        )
      }

      return users
    },
    getTotalCount: state => filter => {
      let users = state.users
      if (filter.search_text) {
        users = users.filter(user =>
          user.full_name
            .toLowerCase()
            .includes(filter.search_text.toLowerCase())
        )
      }
      return users.length
    }
  }
}

export default user

import request from '@/utils/request'

export function getCustomers (params) {
  return request({
    url: '/waitlists',
    method: 'get',
    params: params
  })
}

export function show (id) {
  return request({
    url: `/waitlists/${id}`,
    method: 'get'
  })
}

export function addCustomer (data) {
  return request({
    url: '/waitlists',
    method: 'post',
    data: data
  })
}

export function removeWaitlistCustomer (data) {
  return request({
    url: `/waitlists/${data.id}/customers`,
    method: 'delete'
  })
}

export function waitlistRegistration (data) {
  return request({
    url: `/waitlists/${data.id}/registration`,
    method: 'post',
    data: data
  })
}

export function timeslotWaitlistMovement (data) {
  return request({
    url: `/waitlists/${data.id}/movement`,
    method: 'post',
    data: data
  })
}

export function clear (data) {
  return request({
    url: `/waitlists/${data.storeId}`,
    method: 'delete',
    data: data
  })
}

export function management (data) {
  return request({
    url: `/waitlists/management`,
    method: 'post',
    data: data
  })
}

<template>
  <fragment>
    <el-row :gutter="20">
      <el-col :span="24">
        <p class="small h-two text-center">Overview</p>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="text-center">
      <el-col
        :lg="{span: 3, offset: 4}"
        :sm="{span: 5, offset: 1}"
        :xs="{span: 10, offset: 2}"
      >
        <p class="name text-left">Total Expected</p>
        <p class="value text-left">{{expected}}</p>
      </el-col>
      <el-col
        :lg="3" :sm="5"
        :xs="{span: 10, offset: 2}"
      >
        <p class="name text-left">Check-in</p>
        <p class="value text-left">{{checkin}} {{percentStatus(checkin) | percent}}</p>
      </el-col>
      <el-col
        :lg="3" :sm="5"
        :xs="{span: 10, offset: 2}"
      >
        <p class="name text-left">Entered</p>
        <p class="value text-left">{{entered}} {{percentStatus(entered) | percent}}</p>
      </el-col>
      <el-col
        :lg="3" :sm="4"
        :xs="{span: 10, offset: 2}"
      >
        <p class="name text-left">Late</p>
        <p class="value text-left">{{late}} {{percentStatus(late) | percent}}</p>
      </el-col>
      <el-col
        :lg="3" :sm="4"
        :xs="{span: 10, offset: 2}"
      >
        <p class="name text-left">No Shows</p>
        <p class="value text-left">{{noShow}} {{percentStatus(noShow) | percent}}</p>
      </el-col>
    </el-row>
  </fragment>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import listStates from '@/mixins/listStates'
import permissionMixin from '@/mixins/permissions'

export default {
  name: 'pre-release-header',
  mixins: [ listStates, permissionMixin ],
  data () {
    return {
      searchText: ''
    }
  },
  watch: {
    searchText (value) {
      this.$emit('filterList', value)
    }
  },
  computed: {
    ...mapState({
      editMode: state => state.list.editMode,
      list: state => state.list.list
    }),
    ...mapGetters({
      expected: 'list/getExpedited',
      entered: 'list/getEntered',
      checkin: 'list/getCheckin',
      late: 'list/getLate',
      noShow: 'list/getNoShow'
    })
  },
  methods: {
    ...mapActions('list', [
      'setEditMode'
    ]),
    percentStatus (status) {
      if (status > 0) {
        return (status / this.expected * 100).toFixed(1)
      } else {
        return 0
      }
    }
  }
}
</script>

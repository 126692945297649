import request from '@/utils/request'

export function login (userInfo) {
  return request({
    url: '/auth/sign_in',
    method: 'post',
    data: {
      email: userInfo.email.trim(),
      password: userInfo.password
    }
  })
}

export function logout () {
  return request({
    url: '/auth/sign_out',
    method: 'delete'
  })
}

export function validateToken () {
  return request({
    url: '/auth/validate_token',
    method: 'get'
  })
}

export function resetPassword (email) {
  return request({
    url: '/auth/password',
    method: 'post',
    data: {
      email: email,
      redirect_url: `${process.env.VUE_APP_HOST.replace(/\/$/, '')}/${process.env.VUE_APP_PREFIX_ADMIN_ROUTE}/new-password`
    }
  })
}

export function updatePassword (auth) {
  return request({
    url: '/auth/password',
    method: 'patch',
    data: auth
  })
}

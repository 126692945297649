<template>
  <thead class="el-table__header" :data-cy-selected="filter.selected === '1' ? 'true' : 'false'">
    <tr>
      <template v-if="isReleaseOpen || !isPhysicalSignup">
        <th style="min-width: 2px;">&nbsp;</th>
        <th
          style="min-width: 40px;"
          :class="{'hidden-xs-only': editMode}"
        >#</th>
        <th style="min-width: 160px;">Name</th>
        <th v-if="filter.selected === '1' && !isReleaseOpen" style="min-width: 100px;">Time</th>
        <th
          style="min-width: 60px;"
          class="hide-xs"
        >{{ isPhysicalSignup ? 'Ticket #' : 'CC'}}</th>
        <th
          style="width: 185px;"
          :style="(isReleaseOpen ? 'text-align:right' : '')"
          :class="(isReleaseOpen ? 'pr-ten' : '')"
          :colspan="(isReleaseOpen && editMode ? 2 : 1)">Status</th>
        <th
          v-if="!isReleaseOpen"
          class="handle-wrapper">&nbsp;</th>
      </template>
      <template v-else>
        <th style="min-width: 5px;">&nbsp;</th>
        <th style="min-width: 80px;">Ticket #</th>
        <th style="min-width: 50px;">#</th>
        <th style="min-width: 60px;" v-if="filter.selected === '1'">Time</th>
        <th style="min-width: 180px;">Name</th>
        <th style="min-width: 130px;">Phone Number</th>
        <th style="min-width: 100px;">Email</th>
        <th
          v-if="editMode"
          class="handle-wrapper">&nbsp;</th>
      </template>
    </tr>
  </thead>
</template>
<script>
import { mapState } from 'vuex'
import listStates from '@/mixins/listStates'

export default {
  name: 'post-finalization-header',
  mixins: [ listStates ],
  computed: {
    ...mapState({
      editMode: state => state.list.editMode,
      list: state => state.list.list,
      filter: state => state.list.filter
    })
  }
}
</script>

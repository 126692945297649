<template>
  <fragment>
    <el-row :gutter="20">
      <el-col :lg="17" :sm="12"
        class="mb-fifteen mt-ten">
        <el-link
          class="link-btn"
          :underline="false"
          icon="el-icon-arrow-left"
          @click.prevent="backToRelease"
          v-if="canManageRelease"
        >Back to {{list.release.name}}</el-link>
        &nbsp;
      </el-col>
      <el-col
        :lg="7"
        :sm="12"
        class="mb-fifteen mt-ten">
        <el-input
          prefix-icon="el-icon-search"
          size="medium"
          placeholder="Search list..."
          v-model="searchText"
          @input="debounceOnChange"
        ></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :sm="12">
        <p class="h-two">{{list.release.name}} |
          {{list.release.release_date | moment('MM/D/YYYY')}}
        </p>
        <p>{{list.store.name}}</p>
      </el-col>
      <el-col :sm="12"
        class="text-right button-section"
        v-if="canEditList && !notPermittedStore"
      >
        <el-button
          type="info"
          icon="el-icon-plus"
          size="small"
          v-if="canManageCustomer"
          @click.prevent="$emit('showAddCustomer')">Add Customer(s)</el-button>
        <el-button
          type="info"
          icon="el-icon-upload"
          size="small"
          @click.prevent="$emit('showUploadCSV')">Upload CSV</el-button>
        <pre v-if="!isPreFinalization && !editMode && !isPhysicalSignup">
          <el-button
            type="info"
            size="small"
            @click.prevent="$emit('triggerEditMode')"
            data-cy-label="edit-list-button"
          >Edit List</el-button>
        </pre>
        <pre v-if="!isPreFinalization && filter.spanIndex !== 'all' && customers.length > 0 && !editMode && !isPhysicalSignup">
          <el-button
            type="info"
            size="small"
            @click.prevent="$emit('redistribute')"
          >Redistribute</el-button>
        </pre>

        <el-form
          :inline="true" label-position="left" label-width="160px"
          class="mt-ten"
          v-if="isPhysicalFinalization && isAutomaticFlow"
        >
          <el-form-item label="Send Notification">
            <el-select v-model="notification" placeholder="Time Slot Detail" size="small">
              <el-option
                label="Not Selected" value="not_selected_sent"
                :disabled="isNotSelectedSent"
              ></el-option>
              <el-option
                label="Time Slot Detail" value="time_slot_detail_sent"
                :disabled="!isNotSelectedSent"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="mr-0">
            <el-button
              type="primary" size="small"
              :loading="sending"
              @click.prevent="sendNotification"
            >Send</el-button>
          </el-form-item>
        </el-form>

      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-tag
          class="round mr-five"
          type="warning"
          effect="dark"
          size="mini"
          v-if="isPhysicalSignup"
        >
          Physical Signup
          <span v-if="isAutomaticFlow">(A)</span>
          <span v-else>(M)</span>
        </el-tag>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <p class="h-two small">Overview</p>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <p>{{ isClosed ? 'Total Registrations' : 'Registration Status'}}</p>
        <p>
          <RegistrationStatus
            :status="list.status"
            :is_physical="isPhysicalSignup"
          />
        </p>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
    >
      <el-col :span="6" :md="7" :lg="5" class="mobile-half-wrapper">
        <p class="name">
          {{ isClosed ? 'Total Registrations' : 'Registration Status'}}
        </p>
        <p class="value">{{list.total_registration}}</p>
      </el-col>

      <el-col :span="4" class="mobile-half-wrapper" v-if="isFinalization && customers.length > 0">
        <p class="name">List Total</p>
        <p class="value">{{list.analytics.total_registrants_finalization || '-' }}</p>
      </el-col>

      <el-col :span="4" class="mobile-half-wrapper">
        <p class="name">Selected</p>
        <p class="value">{{list.selected}}</p>
      </el-col>
      <el-col
        :span="4"
        class="mobile-half-wrapper"
      >
        <p class="name">Confirmed</p>
        <p class="value">{{list.confirmed}}</p>
      </el-col>
    </el-row>
    <el-row :gutter="20"
      v-if="isAfterOpen"
    >
      <el-col :span="24">
        <ReleaseDaySelector
          v-if="canShowDaySelector"
          :disabled="filter.selected !== '1'"
        />
      </el-col>
    </el-row>
  </fragment>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import RegistrationStatus from '@/views/admin/releases/components/registration-status'
import listStates from '@/mixins/listStates'
import ReleaseDaySelector from '@/views/admin/lists/release-day-selector'

import permissionMixin from '@/mixins/permissions'
import downloadMixin from '@/mixins/download'
import debounce from 'lodash.debounce'

export default {
  name: 'pre-release-header',
  components: {
    RegistrationStatus,
    ReleaseDaySelector
  },
  mixins: [ listStates, permissionMixin, downloadMixin ],
  data () {
    return {
      searchText: '',
      notification: 'time_slot_detail_sent',
      sending: false
    }
  },
  watch: {
    searchText (value) {
      this.$emit('filterList', value)
    },
    'list.status': {
      handler () {
        if (this.isNotSelectedSent) {
          this.notification = 'time_slot_detail_sent'
        }
        if (this.isOpen) {
          this.notification = 'not_selected_sent'
        }
      }
    }
  },
  computed: {
    ...mapState({
      editMode: state => state.list.editMode,
      list: state => state.list.list,
      customers: state => state.list.customers.customers,
      filter: state => state.list.filter,
      metrics: state => state.list.customers
    }),
    debounceOnChange () {
      return debounce(() => {
        this.$emit('refreshCustomers')
      }, 700)
    },
    canShowDaySelector () {
      return !this.editMode && !this.isPreFinalization && this.list.time_slot_limits.length > 1
    }
  },
  methods: {
    ...mapActions('list', [
      'setLoading'
    ]),
    backToRelease () {
      this.$router.push({
        name: 'release.register',
        params: {
          season_id: this.$route.params.season_id,
          release_id: this.list.release.id
        }
      })
    },
    sendNotification () {
      this.$confirm(this.$t('list.notification.confirm.description', {
        type: this.$t(`list.notification.${this.notification}`)
      }), this.$t('list.notification.confirm.title'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        center: true,
        roundButton: true
      }).then(() => {
        this.setLoading(true)
        this.$store.dispatch('list/sendNotification', {
          id: this.list.id,
          notification: this.notification
        }).then(() => {
          this.setLoading(false)
          this.$message({
            message: 'Notification will be sent through background job.',
            type: 'success'
          })
        })
      })
    }
  }
}
</script>

import { timeslotListMovement } from '@/api/list'
import { timeslotWaitlistMovement } from '@/api/waitlist'
import { mapActions } from 'vuex'
import moment from 'moment-timezone'

export default {
  data () {
    return {
      customersByTimeslot: [],
      inMotion: {
        oldTimeslot: false,
        newTimeslot: false,
        newTimeslotCustomers: []
      }
    }
  },
  watch: {
    customers (value) {
      this.customersByTimeslot = this.sortCustomersByTimeslot(value)
    }
  },
  computed: {
    timeslots () {
      return Object.keys(this.customers.reduce((obj, customer) => {
        obj[(`${customer.span_index}->${customer.timeslot}` || '')] = true
        return obj
      }, {}))
    }
  },
  methods: {
    ...mapActions({
      setListLoading: 'list/setLoading',
      setWaitlistLoading: 'waitlist/setLoading'
    }),
    sortCustomersByTimeslot (customers) {
      const customersClone = Array.from(customers)
        .sort((a, b) => a.index || (a.rank > b.rank) ? 1 : -1, 0)

      if (parseInt(this.filter.selected, 10) !== 1) {
        return [{
          timeslot: '',
          weekday: '',
          customers: customersClone
        }]
      } else {
        return this.timeslots.map(timeslot => {
          const [spanIndex = 0, slot = ''] = timeslot.split('->')
          const slotCustomers = customersClone
            .filter(
              ({ timeslot: cTimeSlot = '', span_index: cSpanIndex = 0 } = {}) =>
                cTimeSlot === slot && cSpanIndex === parseInt(spanIndex, 10)
            )
          const { booking_date: bookingDate = '', weekday = '' } =
            slotCustomers.find(({ booking_date: bookingDate = '', weekday }) => !!bookingDate || !!weekday) || {}
          const obj = {
            timeslot,
            weekday: weekday || (bookingDate ? moment(bookingDate).tz(this.store.timezone).format('dddd') : ''),
            customers: slotCustomers
          }
          return obj
        })
      }
    },
    isDragBetweenTimeslots (event) {
      return !!event.relatedContext.element
    },
    onDragMove (event) {
      if (!this.isDragBetweenTimeslots(event)) {
        return
      }

      this.inMotion.oldTimeslot = (
        `${event.draggedContext.element.span_index}->${event.draggedContext.element.timeslot}`
      )

      this.inMotion.newTimeslot = (
        `${event.relatedContext.element.span_index}->${event.relatedContext.element.timeslot}`
      )
      this.inMotion.newTimeslotCustomers = (
        this.customersByTimeslot.find(timeslot => {
          return timeslot.timeslot === this.inMotion.newTimeslot
        }).customers ||
        []
      ).slice(0)
    },
    getCustomerFromEvent (event) {
      return (
        (event.moved && event.moved.element) ||
        (event.added && event.added.element)
      )
    },
    onDragDrop (event) {
      const customer = this.getCustomerFromEvent(event)
      if (!customer) {
        return false
      }

      this.setListLoading(true)
      this.setWaitlistLoading(true)

      const newIndex = (
        (event.moved || event.added).newIndex
      )

      const newRank = (
        (
          this.inMotion.newTimeslotCustomers[newIndex] &&
          ~this.inMotion.newTimeslotCustomers[newIndex]
        )
          ? this.inMotion.newTimeslotCustomers[newIndex].rank
          : customer.rank
      )

      const newTimeslot = (
        this.inMotion.newTimeslot || customer.timeslot
      )

      const customers = this.customers
        .slice(0)
        .map(_customer => {
          if (_customer.rank === customer.rank) {
            _customer.rank = newRank
            _customer.timeslot = newTimeslot
          }
          return _customer
        })

      const store = this.$store.state.waitlist.store
      const movementPayload = {
        customer_id: customer.id,
        toRank: newRank,
        from: this.inMotion.oldTimeslot || newTimeslot,
        to: this.inMotion.newTimeslot || newTimeslot,
        id: this.list ? this.list.id : store.id
      }

      this.customers = customers
      if (this.list) {
        timeslotListMovement(movementPayload)
          .then(() => {
            this.$emit('refreshCustomers')
          })
      } else {
        timeslotWaitlistMovement(movementPayload)
          .then(() => {
            this.$emit('refreshCustomers')
          })
      }
    }
  }
}

<template>
  <div>
    <el-row :gutter="20">
      <el-col :lg="6" :md="8" :sm="12">
        <el-form-item
          label="Registration Day"
          :show-message="errors.has('registration day')"
          :error="errors.first('registration day')"
        >
          <el-select
            v-model="list.reg_weekday"
            data-vv-name="registration-day"
            v-validate="'required'"
            :clearable="false"
            placeholder="Tuesdays"
            class="full-width"
            :disabled="!editable || notPermittedStore"
          >
            <el-option
              v-for="(weekday, index) in weekDays"
              :key="index"
              :label="`${weekday.label}s`"
              :value="weekday.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :lg="6" :md="8" :sm="12">
        <el-form-item
          :show-message="errors.has('registration opening')"
          :error="errors.first('registration opening')"
        >
          <template slot="label">
            Registration Opening
            <el-tooltip class="item" placement="top">
              <div slot="content" class="pre-whitespace">{{$t('tooltip.registration_opening')}}</div>
              <i class="el-icon-warning float-right"></i>
            </el-tooltip>
          </template>
          <sny-time-select
            v-model="list.online_reg_opening"
            :weekday="list.reg_weekday"
            :value="list.online_reg_opening"
            :disabled="!editable || notPermittedStore"
            :options="{
              start: '00:00',
              step: `00:15`,
              end: '24:00'
            }"
            placeholder="Select time"
            data-vv-name="registration-opening"
            v-validate="'required'"
            :useStandardTime="!isEuStore(list.store.country)"
          ></sny-time-select>
        </el-form-item>
      </el-col>
      <el-col
        :lg="6" :md="8" :sm="12"
        v-if="isPhysicalSignup"
      >
        <el-form-item>
          <template slot="label">
            Physical Sign Up Flow
            <el-tooltip class="item" placement="top">
              <div slot="content" class="pre-whitespace">{{$t('tooltip.physical_signup')}}</div>
              <i class="el-icon-warning float-right"></i>
            </el-tooltip>
          </template>
          <el-select
            v-model="list.is_automatic_physical_flow"
            :clearable="false"
            class="full-width"
            :disabled="!editable || notPermittedStore"
            data-vv-name="physical-signup-flow"
          >
            <el-option label="Manual" :value="false"></el-option>
            <el-option label="Automatic" :value="true"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col
        :lg="6" :md="8" :sm="12"
        v-if="!isPhysicalSignup"
      >
        <el-form-item
          label="Close Registration After"
        >
          <el-select
            v-model="list.close_reg_after"
            :disabled="!editable && isAfterOpen || notPermittedStore"
            placeholder="3 minutes"
            class="full-width"
          >
            <span slot="-">h</span>
            <el-option
              v-for="(limit, index) in reg_after_arr"
              :key="index"
              :value="limit"
              :label="limit === 0 ? 'N/A' : `${limit} minutes`"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col
        :lg="6" :md="8" :sm="12"
        v-if="!isPhysicalSignup"
      >
        <el-form-item
          :show-message="errors.has('total-registration-maximum')"
          :error="errors.first('total-registration-maximum')"
        >
          <template slot="label">
            Total Registration Maximum
            <el-tooltip class="item" :content="$t('tooltip.registration_maximum')" placement="top">
              <i class="el-icon-warning float-right"></i>
            </el-tooltip>
          </template>
          <el-input-number
            :disabled="!editable || notPermittedStore"
            v-model="list.reg_limit"
            placeholder="1000"
            :controls="false"
            :min="0"
            :precision="0"
            v-validate="'required'"
            data-vv-name="total-registration-maximum"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <el-col
        :lg="6" :md="8" :sm="12"
        v-if="!isPhysicalSignup"
      >
        <el-form-item
          label="Release Duration"
        >
          <el-select
            placeholder="1 Day"
            class="full-width"
            v-model="list.release_duration"
            name="release-duration"
            :disabled="!editable && isReleaseComplete || notPermittedStore"
            data-vv-name="release-duration"
          >
            <span slot="-">D</span>
            <el-option
              v-for="(duration, index) in regDurations"
              :key="index"
              :value="duration"
              :disabled="meta.release_duration > duration && isAfterFirstRoundSent"
              :label="`${duration} Day`"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      v-if="isPhysicalSignup"
    >
      <el-col :md="18" :sm="12">
        <el-form-item
          label="Physical Sign-up Note (location details to display on the registration page)"
        >
          <el-input
            type="textarea"
            :disabled="!editable || notPermittedStore"
            v-model="list.note"
            data-vv-name="physical-sign-up-note"
            rows="3"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      v-if="list.is_included && (!isPhysicalSignup || isAutomaticFlow)"
    >
      <el-col>
        <p class="h-two small mb-fifteen">Time Slots</p>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      v-if="list.is_included && (!isPhysicalSignup || isAutomaticFlow)"
    >
      <el-col :xl="5" :lg="6" :sm="12">
        <el-form-item
          label="Total Slots Available"
          :show-message="errors.has('total slots available')"
          :error="errors.first('total slots available')"
        >
          <el-input-number
            v-model="list.total_slots_available"
            :disabled="!editable && isScheduled || notPermittedStore"
            placeholder="1000"
            :min="0"
            :precisions="0"
            :controls="false"
            data-vv-name="total-slots-available"
            v-validate="'required'"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :xl="5" :lg="6" :sm="12">
        <el-form-item
          label="First Time Slot"
          :show-message="errors.has('first time slot')"
          :error="errors.first('first time slot')"
        >
          <sny-time-select
            v-model="list.first_time_slot"
            :value="list.first_time_slot"
            :disabled="!editable && isAfterFirstRoundSent || notPermittedStore"
            :options="{
              start: '00:00',
              step: `00:${list.time_slot_interval}`,
              end: list.last_time_slot
            }"
            placeholder="Select time"
            data-vv-name="first-time-slot"
            v-validate="'required'"
            :useStandardTime="!isEuStore(list.store.country)"
          ></sny-time-select>
        </el-form-item>
      </el-col>
      <el-col :xl="5" :lg="6" :sm="12">
        <el-form-item
          label="Last Time Slot"
          :show-message="errors.has('last time slot')"
          :error="errors.first('last time slot')"
        >
          <sny-time-select
            v-model="list.last_time_slot"
            :value="list.last_time_slot"
            :disabled="!editable && isReleaseComplete || notPermittedStore"
            :options="{
              start: list.first_time_slot,
              step: `00:${list.time_slot_interval}`,
              end: '24:00'
            }"
            placeholder="Select time"
            data-vv-name="last-time-slot"
            v-validate="'required'"
            :useStandardTime="!isEuStore(list.store.country)"
          ></sny-time-select>
        </el-form-item>
      </el-col>
      <el-col :xl="5" :lg="6" :sm="12">
        <el-form-item
          label="Time Slot Intervals"
          :show-message="errors.has('time slot intervals')"
          :error="errors.first('time slot intervals')"
        >
          <el-select
            placeholder="Interval"
            class="full-width"
            data-vv-name="time-slot-intervals"
            v-validate="'required'"
            :disabled="!editable && isAfterFirstRoundSent || notPermittedStore"
            v-model="list.time_slot_interval"
          >
            <el-option
              v-for="(interval, index) in timeslotIntervals"
              :key="index"
              :label="`${interval} minutes`"
              :value="interval">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      :gutter="20"
      v-if="!isPhysicalSignup || isAutomaticFlow"
    >
      <el-col>
        <p class="name">
          Time Slot Limits &nbsp;&nbsp;
          <span class="sub" :class="{ error: timeSlotLimits !== list.total_slots_available }">
            ({{timeSlotLimits}}/{{list.total_slots_available}} slots available)
          </span>
        </p>
        <p class="value mb-twenty-five">Customize the capacity for each timeslot</p>
      </el-col>
    </el-row>
    <template
      v-if="!isPhysicalSignup || isAutomaticFlow"
    >
      <div
        v-for="(timeslot_limit, index) in list.time_slot_limits"
        :key="index"
      >
        <h3>Day {{ index + 1 }}</h3>
        <time-slot-limits
          :first_time_slot="list.first_time_slot"
          :last_time_slot="list.last_time_slot"
          :interval="list.time_slot_interval"
          :list="list"
          :iteration="index"
          :key="index"
          :disabled="(!editable && isReleaseComplete) || ((meta.release_duration > index) && afterDetailSent) || notPermittedStore"
          :hasChangedTimeslots="hasChangedTimeslots"
        ></time-slot-limits>
      </div>
    </template>
    <el-row :gutter="20"
      class="time-slot-settings"
      v-if="!isPhysicalSignup"
    >
      <el-col :lg="6" :sm="12">
        <div class="time-slot-setting">
          <p class="name small">
            1st Round `Selected`
            <el-tooltip class="item" :content="$t('tooltip.first_round_selected')" placement="top">
              <i class="el-icon-warning float-right"></i>
            </el-tooltip>
          </p>
          <el-form-item
            label="Time"
            :error="!list.first_round_time ? `First round time required` : ''"
            :show-message="!list.first_round_time"
          >
            <sny-time-select
              v-model="list.first_round_time"
              :value="list.first_round_time"
              :weekday="list.reg_weekday"
              :disabled="!editable && isAfterFirstRoundSent || notPermittedStore"
              :options="{
                start: list.online_reg_opening,
                step: `00:15`,
                end: '24:00'
              }"
              placeholder="Select time"
              data-vv-name="first-round-time"
              v-validate="'required'"
              :useStandardTime="!isEuStore(list.store.country)"
            ></sny-time-select>
          </el-form-item>
          <el-form-item
            label="Invites to be sent"
            :show-message="errors.has('first round limit')"
            :error="errors.first('first round limit')"
          >
            <el-input
              :disabled="!editable && isAfterFirstRoundSent"
              data-vv-name="first-round-limit"
              v-model="list.first_round_limit"
              v-validate="'required|integer'"
            >
              <template slot="prefix">%</template>
              <template slot="suffix">({{firstRoundLimit}} slots)</template>
            </el-input>
          </el-form-item>
          <el-form-item
            class="mb-seven"
          >
            <el-checkbox
              disabled
              v-model="list.first_round_notification"
              data-vv-name="first-round-notification"
            >Send Notifications</el-checkbox>
          </el-form-item>
        </div>
      </el-col>
      <el-col :lg="6" :sm="12">
        <div class="time-slot-setting left-bordered sm-border-none">
          <p class="name small">
            2nd Round `Selected`
            <el-tooltip class="item" placement="top">
              <div slot="content" class="pre-whitespace">{{$t('tooltip.second_round_selected')}}</div>
              <i class="el-icon-warning float-right"></i>
            </el-tooltip>
          </p>
          <el-form-item
            label="Time"
            :error="!list.second_round_time ? `Second round time required` : ''"
            :show-message="!list.second_round_time"
          >
            <sny-time-select
              v-model="list.second_round_time"
              :value="list.second_round_time"
              :weekday="list.reg_weekday"
              :disabled="!editable && isAfterFirstRoundSent || notPermittedStore"
              :options="{
                start: list.online_reg_opening,
                step: `00:15`,
                end: '24:00'
              }"
              placeholder="Select time"
              data-vv-name="second-round-time"
              v-validate="'required'"
              :useStandardTime="!isEuStore(list.store.country)"
            ></sny-time-select>
          </el-form-item>
          <el-form-item
            :show-message="errors.has('second round limit')"
            :error="errors.first('second round limit')"
          >
            <template slot="label">
              Percentage of remaining slots
            </template>
            <el-input
              :disabled="!editable && isAfterSecondRoundSent || notPermittedStore"
              v-model="list.second_round_percentage"
              data-vv-name="second-round-limit"
              v-validate="'required|integer'"
            >
              <template slot="prefix">%</template>
              <template slot="suffix">({{secondRoundLimit}} slots)</template>
            </el-input>
          </el-form-item>
          <el-form-item
            class="mb-seven"
          >
            <el-checkbox
              :disabled="!editable && isAfterSecondRoundSent || notPermittedStore"
              v-model="list.second_round_notification"
              data-vv-name="second-round-notification"
            >Send Notifications</el-checkbox>
          </el-form-item>
        </div>
      </el-col>
      <el-col :lg="6" :sm="12">
        <div class="time-slot-setting left-bordered lg-border-none sm-border-none">
          <p class="name small">
            Not `Selected`
            <el-tooltip class="item" placement="top">
              <div slot="content" class="pre-whitespace">{{$t('tooltip.not_selected')}}</div>
              <i class="el-icon-warning float-right"></i>
            </el-tooltip>
          </p>
          <el-form-item
            label="Time"
            :error="!list.not_selected_notify_time ? `Not selected time required` : ''"
            :show-message="!list.not_selected_notify_time"
          >
            <sny-time-select
              v-model="list.not_selected_notify_time"
              :value="list.not_selected_notify_time"
              :weekday="list.reg_weekday"
              :disabled="!editable && isAfterSecondRoundSent || notPermittedStore"
              :options="{
                start: list.online_reg_opening,
                step: `00:15`,
                end: '24:00'
              }"
              placeholder="Select time"
              data-vv-name="not-selected-time"
              v-validate="'required'"
              :useStandardTime="!isEuStore(list.store.country)"
            ></sny-time-select>
          </el-form-item>
          <el-form-item
            class="hidden-md-and-down"
          >
            <div class="empty-form-item"></div>
          </el-form-item>
          <el-form-item
            class="mb-seven"
          >
            <el-checkbox
              :disabled="!editable && isAfterNotSelectedSent || notPermittedStore"
              v-model="list.not_selected_notification"
              data-vv-name="not-selected-notification"
            >Send Notifications</el-checkbox>
          </el-form-item>
        </div>
      </el-col>
      <el-col :lg="6" :sm="12">
        <div class="time-slot-setting left-bordered sm-border-none">
          <p class="name small">
            Time Slot Details
            <el-tooltip class="item" placement="top">
              <div slot="content" class="pre-whitespace">{{$t('tooltip.timeslot_details')}}</div>
              <i class="el-icon-warning float-right"></i>
            </el-tooltip>
          </p>
          <el-form-item
            :error="!list.time_slot_notify_time ? `Time slot detail required` : ''"
            :show-message="!list.time_slot_notify_time"
          >
            <template slot="label">
              Time
            </template>
            <sny-time-select
              v-model="list.time_slot_notify_time"
              :value="list.time_slot_notify_time"
              :weekday="timeSlotDetailsWeekday"
              :disabled="!editable && afterDetailSent || notPermittedStore"
              :options="{
                start: list.online_reg_opening,
                step: `00:15`,
                end: '24:00'
              }"
              placeholder="Select time"
              data-vv-name="time-slot-time"
              v-validate="'required'"
              :useStandardTime="!isEuStore(list.store.country)"
            ></sny-time-select>
          </el-form-item>
          <el-form-item
            class="hidden-md-and-down"
          >
            <div class="empty-form-item"></div>
          </el-form-item>
          <el-form-item
            class="mb-seven"
          >
            <el-checkbox
              :disabled="!editable && afterDetailSent || notPermittedStore"
              v-model="list.time_slot_detail_notification"
              data-vv-name="time-slot-notification"
            >Send Notifications</el-checkbox>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import SnyTimeSelect from '@/views/components/sny-time-select'
import TimeSlotLimits from './time-slot-limits'
import { timeslotIntervals, weekDays, closeRegAfterArr } from '@/consts'
import listStates from '@/mixins/listStates.js'
import calcMixins from '@/mixins/calc'
import permissionMixins from '@/mixins/permissions'
import times from 'lodash.times'
import moment from 'moment-timezone'
export default {
  name: 'regular-signup-settings',
  props: ['list', 'editable', 'meta', 'hasChangedTimeslots'],
  components: {
    TimeSlotLimits,
    SnyTimeSelect
  },
  mixins: [listStates, calcMixins, permissionMixins],
  data () {
    return {
      saving: false,
      basicSetting: false,
      showCloseReg: false,
      timeslotIntervals: timeslotIntervals,
      reg_after_arr: closeRegAfterArr,
      weekDays: weekDays,
      regDurations: this.rangeArray(1, 6),
      status: 0,
      showTimeSlotSettings: true
    }
  },
  computed: {
    timeSlotDetailsWeekday () {
      const date = this.list.release.release_date
      const timezone = this.list.store.timzone
      const val = parseInt(moment.tz(date, timezone).format('d'), 10) - 1
      return val
    },
    timeSlotLimits () {
      const sum = (a, b) => a + b

      const isTimeslotGreaterOrEq = (timeslot1, timeslot2) => {
        const [[hours1, minutes1], [hours2, minutes2]] = [timeslot1, timeslot2]
          .map(timeslot => timeslot.split(':').map(Number))

        if (hours1 > hours2) {
          return true
        }

        if (hours1 === hours2) {
          return minutes1 >= minutes2
        }

        return false
      }

      return this.list.time_slot_limits.map(limits => {
        return Object.keys(limits)
          .filter(timeslot => {
            return (
              isTimeslotGreaterOrEq(timeslot, this.list.first_time_slot) &&
              isTimeslotGreaterOrEq(this.list.last_time_slot, timeslot)
            )
          })
          .map(timeslot => Number(limits[timeslot] || 0))
          .reduce(sum, 0)
      }).reduce(sum, 0)
    },
    availableTimeSlots () {
      return this.getTimeSlotList(this.list.first_time_slot, this.list.last_time_slot, this.list.time_slot_interval)
    },
    firstRoundLimit () {
      const limit = Math.max(0, this.list.total_slots_available * this.list.first_round_limit / 100)
      return Math.round(limit)
    },
    /**
     * Calculates this dynamic property client
     * side for immediate feedback as other settings
     * are adjusted. Use "second_round_total_sent" if
     * available, as this is set as part of the second
     * round selected job.
     */
    secondRoundLimit () {
      const limit = Math.max((
        this.list.total_slots_available - this.list.confirmed
      ) * this.list.second_round_percentage / 100,
      0)
      return this.list.second_round_total_sent || Math.round(limit)
    }
  },
  watch: {
    'list.release_duration': {
      handler () {
        this.showTimeSlotSettings = false
        this.updateTimeSlotLimits()
      }
    }
  },
  methods: {
    updateTimeSlotLimits () {
      let currentLimitsLength = this.list.time_slot_limits.length
      let diff = this.list.release_duration - currentLimitsLength

      if (diff > 0) {
        let limitObj = {}
        this.availableTimeSlots.forEach(timeslot => {
          limitObj[timeslot] = 0
        })
        times(diff, () => {
          this.list.time_slot_limits.push(limitObj)
        })
      } else {
        this.$delete(this.list.time_slot_limits, this.list.time_slot_limits.length - 1)
      }
      this.showTimeSlotSettings = true
    },
    limitCount (timeslotLimit) {
      return Object.keys(timeslotLimit).map(key => Number(timeslotLimit[key]))
        .reduce((a, b) => a + b, 0)
    }
  }
}
</script>

<template>
  <fragment>
    <template v-if="isReleaseOpen || !isPhysicalSignup">
      <td class="el-check">
        <el-checkbox
          :value="meta.selects.includes(customer.id)"
          @input="$emit('customerSelected', customer.id)" />
      </td>
      <td
        class="hidden-xs-only"
      >{{customer.index}}</td>
      <td>
        {{customer.full_name}}
        <span v-if="customer.note" class="asterisks">*</span>
        <span
          class="asterisks"
          v-if="customer.label"
          :class="`label-${customer.label}`"
        >*</span>
      </td>
      <td v-if="shouldShowTimeSlot">
        {{timeslot.timeslot.split('->').pop()}}
      </td>
      <td
        class="hide-xs"
      >{{isPhysicalSignup ? customer.timeslot : customer.last_4}}</td>
      <td
        class="pr-ten"
        :class="(isReleaseOpen ? 'text-right' : '')"
        width="185px"
      >
        <customer-checkin-actions
          v-if="isReleaseOpen && !notPermittedStore"
          :customer="customer"
          :timeslot="timeslot"
          @checkinAction="$emit('refreshCustomers')" />
        <customer-status v-else :customer="customer" />
      </td>
      <td class="handle-wrapper">
        <div class="handle">
          <i class="fa fa-navicon"></i>
        </div>
      </td>
    </template>
    <template v-else>
      <td class="el-check">
        <el-checkbox
          :value="meta.selects.includes(customer.id)"
          @input="$emit('customerSelected', customer.id)" />
      </td>
      <td>{{customer.ticket || ' - '}}</td>
      <td>{{customer.index}}</td>
      <td>
        {{timeslot.timeslot ? timeslot.timeslot : ' - '}}
      </td>
      <td>
        {{customer.full_name}}
        <span v-if="customer.note" class="asterisks">*</span>
        <span v-if="customer.label" class="asterisks" :class="`label-${customer.label}`">*</span>
      </td>
      <td>{{customer.e164_phone}}</td>
      <td
        class="pr-ten"
        :class="(isReleaseOpen ? 'text-right' : '')">
        <customer-checkin-actions
          v-if="isReleaseOpen && !notPermittedStore"
          :customer="customer"
          :timeslot="timeslot"
          @checkinAction="$emit('refreshCustomers')" />
        <span v-else>{{customer.email}}</span>
      </td>
      <td class="handle-wrapper">
        <div class="handle">
          <i class="fa fa-navicon"></i>
        </div>
      </td>
    </template>
  </fragment>
</template>
<script>
import { mapState } from 'vuex'
import CustomerStatus from '@/views/admin/lists/customer-status'
import CustomerCheckinActions from '@/views/admin/lists/customer-checkin-actions'
import listStates from '@/mixins/listStates'
import listHelpers from '@/mixins/listHelpers'
import permissionMixin from '@/mixins/permissions'
import calc from '@/mixins/calc'

export default {
  name: 'timeslot-groups-edit-row',
  props: [
    'customer',
    'customers',
    'list',
    'timeslot',
    'selectedTabInFocus',
    'timeslotIndex',
    'showSelected'
  ],
  components: {
    CustomerStatus,
    CustomerCheckinActions
  },
  mixins: [ listStates, listHelpers, calc, permissionMixin ],
  data () {
    return {}
  },
  computed: {
    ...mapState({
      meta: state => state.list.meta,
      filter: state => state.list.filter
    }),
    shouldShowTimeSlot () {
      return this.selectedTabInFocus && this.customer.timeslot && !this.isReleaseOpen
    }
  }
}
</script>

<template>
  <el-row :gutter="20">
    <el-col
      :sm="3"
      :span="6"
      v-for="(time, index) in getTimeSlotList(first_time_slot, last_time_slot, interval)"
      :key="index"
    >
      <el-form-item
        :label="time"
        :show-message="!/^\d+$/.test(list.time_slot_limits[iteration][time])"
        :error="!/^\d+$/.test(list.time_slot_limits[iteration][time]) ? `Numbers Only` : null"
      >
        <el-input-number
          :disabled="!list.is_included || disabled"
          data-vv-name="time-slot-limit"
          v-model="list.time_slot_limits[iteration][time]"
          :min="0"
          :precision="0"
          :controls="false"
          required
          v-on:change="updateList(time, $event)"
          controls-position="right"
        ></el-input-number>
      </el-form-item>
    </el-col>
  </el-row>
</template>
<script>
import calcMixin from '@/mixins/calc'
export default {
  name: 'time-slot-limits',
  props: ['list', 'first_time_slot', 'last_time_slot', 'interval', 'disabled', 'iteration', 'hasChangedTimeslots'],
  mixins: [ calcMixin ],
  methods: {
    updateList (time, value) {
      this.hasChangedTimeslots()
      const day = this.iteration || 0
      const originalTimeslots = this.list.time_slot_limits
      const dayTimeSlots = originalTimeslots[day]
      const newTimeSlots = this.getTimeSlotList(
        this.first_time_slot,
        this.last_time_slot,
        this.interval
      ).map((val) => ({
        [val]: val === time
          ? value
          : dayTimeSlots[val] ?? 0
      }))

      this.list.time_slot_limits = Array.from(
        { length: originalTimeslots.length },
        (v, i) => i === day
          ? newTimeSlots.reduce((a, b) => ({ ...a, ...b }), {})
          : originalTimeslots[i]
      )
    }
  }
}
</script>

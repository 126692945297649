<template>
  <fragment>
    <el-row
      :gutter="20"
      v-if="list.is_physical_signup"
    >
      <el-col :col="24" class="mb-fifteen">
        <p class="h-two small mt-zero">Settings</p>
        <div v-if="list.is_included">
          <el-row :gutter="20">
            <el-col :lg="5" :md="6" :span="12">
              <p class="name small">Registration </p>
              <p class="value">{{registrationDate | date_time_string(list.store.timezone, normalizeTime)}}</p>
            </el-col>
            <el-col :lg="5" :md="6" :span="12">
              <p class="name small">In-Store Release</p>
              <p class="value">{{instoreRelease | date_time_string(list.store.timezone, normalizeTime)}}</p>
            </el-col>
            <el-col :lg="5" :md="6" :span="12">
              <p class="name small">Available Slots</p>
              <p class="value">{{list.total_slots_available}}</p>
            </el-col>
            <el-col :lg="4" :md="6" :span="12">
              <p class="name small">Registration Maximum</p>
              <p class="value">{{list.reg_limit}}</p>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-else>
      <el-col :span="24">
        <p class="h-two small mt-zero">Settings</p>
        <el-row :gutter="20">
          <el-col :lg="4" :md="5" :sm="6">
            <p class="name small">Registration</p>
            <p class="value">{{registrationDate | date_time_string(list.store.timezone, normalizeTime)}}</p>
          </el-col>
          <el-col :lg="4" :md="5" :sm="6">
            <p class="name small">In-Store Release</p>
            <p class="value">{{instoreRelease | date_time_string(list.store.timezone, normalizeTime)}}</p>
          </el-col>
          <el-col :lg="4" :md="5" :sm="6">
            <p class="name small">Available Slots</p>
            <p class="value">{{list.total_slots_available}}</p>
          </el-col>
          <el-col :lg="4" :md="5" :sm="6">
            <p class="name small">Registration Maximum</p>
            <p class="value">{{list.reg_limit}}</p>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="24" v-if="testKey && isScheduled && !isAfterOpen">
        <el-divider></el-divider>
        <p class="h-two small mt-zero">Test Key</p>
        <el-row :gutter="20">
          <el-col :lg="4" :md="5" :sm="6">
            <p class="name small"><el-link type="primary"><u>?key={{testKey.key}}</u></el-link></p>
            <p class="value">Expires: ~{{testKey.expiresAt}} minute(s)</p>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="24" v-if="isAfterOpen">
        <p class="h-two small">Registration Performance</p>
        <el-row :gutter="20">
          <el-col :lg="4" :md="6" :sm="8" :span="12">
            <p class="name small">Total Registration</p>
            <p class="value">{{list.total_registration}}</p>
          </el-col>
          <el-col :lg="4" :md="6" :sm="8" :span="12">
            <p class="name small">Selected</p>
            <p class="value">{{list.selected}}</p>
          </el-col>
          <el-col :lg="4" :md="6" :sm="8" :span="12">
            <p class="name small">Confirmed</p>
            <p class="value">{{list.confirmed}}</p>
          </el-col>
          <el-col :lg="4" :md="6" :sm="8" :span="12">
            <p class="name small">Canceled</p>
            <p class="value"> {{list.canceled}}</p>
          </el-col>
          <el-col :lg="4" :md="6" :sm="8" :span="12">
            <p class="name small">Pending</p>
            <p class="value">{{list.pending}}</p>
          </el-col>
          <el-col :lg="4" :md="6" :sm="8" :span="12" v-if="afterDetailSent">
            <p class="name small">Waitlist</p>
            <p class="value">{{list.waitlist}}</p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :col="24" v-if="(isPhysicalSignup && isAfterOpen) || (!isPhysicalSignup && isReleaseOpen)">
        <p class="h-two small mt-five">Release Performance</p>
        <div>
          <el-row :gutter="20">
            <el-col :sm="5" :span="12">
              <p class="name small">Total Expected</p>
              <p class="value">
                <span>{{list.expected}}</span>
              </p>
            </el-col>
            <el-col :sm="5" :span="12">
              <p class="name small">Checked-in</p>
              <p class="value">
                <span v-if="isBeforeClose"> - </span>
                <span v-else>{{list.check_in}} {{ percent(list.check_in, list.expected) | percent}}</span>
              </p>
            </el-col>
            <el-col :sm="5" :span="12">
              <p class="name small">Entered</p>
              <p class="value">
                <span v-if="isBeforeClose"> - </span>
                <span v-else>{{list.entered}} {{ percent(list.entered, list.expected) | percent}}</span>
              </p>
            </el-col>
            <el-col :sm="4" :span="12">
              <p class="name small">Late</p>
              <p class="value">
                <span v-if="isBeforeClose"> - </span>
                <span v-else>{{list.late}} {{ percent(list.late, list.expected) | percent}}</span>
              </p>
            </el-col>
            <el-col :sm="4" :span="12">
              <p class="name small">No Shows</p>
              <p class="value">
                <span v-if="isBeforeClose"> - </span>
                <span v-else>{{list.no_show}} {{ percent(list.no_show, list.expected) | percent}}</span>
              </p>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </fragment>
</template>
<script>
import moment from 'moment-timezone'
import calcMixins from '@/mixins/calc'
import listStates from '@/mixins/listStates'
export default {
  name: 'list-summary',
  props: ['list'],
  mixins: [calcMixins, listStates],
  computed: {
    instoreRelease () {
      return moment.tz(`${this.list.release.release_date} ${this.list.first_time_slot}`, this.list.store.timezone).format()
    },
    registrationDate () {
      return this.list.registration_open_date
    },
    normalizeTime () {
      return !this.isEuStore(this.list.store.country)
    },
    testKey () {
      const { expires_at: expiresAt = '', ...entry } = this.$store
        .state
        .list
        .testKeys.find(({ id: listId = '' }) => listId === this.list.id) || {}
      if (!expiresAt) return
      const expiry = moment.unix(expiresAt)
      const diff = expiry.diff(moment(), 'minutes')
      return { ...entry, expiresAt: diff }
    }
  }
}
</script>

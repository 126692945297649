<template>
  <div class="customer-index">
    <el-row :gutter="20">
      <el-col :span="18" class="title-wrapper">
        <h2 class="main-title">{{$t('settings.customer')}}</h2>
      </el-col>
      <el-col :span="6" class="search-wrapper">
        <el-input
          placeholder="Search customers..."
          size="medium"
          v-model="filter.search_text"
          @input="debounceOnChange"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <div
          class="display-inline float-right"
        >
          <span
            class="text-secondary"
          >Sort by</span>
          <el-select
            v-model="filter.order_by"
            class="ml-seven"
            size="medium"
            placeholder="Select"
          >
            <el-option label="Newest First" value="created_at DESC"></el-option>
            <el-option label="Oldest First" value="created_at ASC"></el-option>
            <el-option label="Name A-Z" value="first_name ASC"></el-option>
            <el-option label="Name Z-A" value="first_name DESC"></el-option>
            <el-option label="N/A First" value="label ASC"></el-option>
            <el-option label="VIP First" value="label DESC"></el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="el-table-wrapper has-border">
        <el-table
          :data="customers"
          v-loading="loading"
          :row-key="row => row.id"
          :expand-row-keys="expandRowKeys"
          ref="customerstb"
          @row-click="expandRow"
        >
          <el-table-column
            label="Name"
            min-width="180"
            prop="full_name"
          ></el-table-column>
          <el-table-column
            label="Label"
          >
            <template slot-scope="scope">
              <fragment
                v-if="scope.row.label !== 'n_a'"
              >
                <el-tag
                  type="info"
                  size="mini"
                  class="mr-five round"
                  effect="plain">
                  {{ labelByValue(scope.row.label) }}
                </el-tag>
              </fragment>
            </template>
          </el-table-column>
          <el-table-column
            label="Phone"
            min-width="160"
            prop="e164_phone">
          </el-table-column>
          <el-table-column
            label="CC"
            prop="last_4">
          </el-table-column>
          <el-table-column
            type="expand"
            class-name="hide-expand-icon"
          >
            <template slot-scope="props">
              <transition name="slide">
                <customer-form
                  :customer="props.row"
                  :hideLabelForm="!canSeeAsterisks"
                  :customer-index="true"
                  @customerRemoved="loadCustomers"
                  @cancel="cancel"
                />
              </transition>
            </template>
          </el-table-column>
        </el-table>
        <div class="el-table__append-wrapper">
          <el-pagination
            @sizeChange="handlePageSize"
            @current-change="handleCurrentPage"
            :current-page.sync="filter.page"
            :pager-count="5"
            :page-sizes="[25, 50, 75, 100]"
            :page-size="filter.limit"
            layout="sizes, prev, pager, next, jumper"
            :total="totals"
            hide-on-single-page>
          </el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import CustomerForm from '@/views/admin/global/customer-form'
import ElPagination from '@/views/components/pagination'
import calcMixin from '@/mixins/calc'
import permissionMixins from '@/mixins/permissions'
import _debounce from 'lodash.debounce'
export default {
  name: 'customers',
  components: {
    CustomerForm,
    ElPagination
  },
  data () {
    return {
      loading: false,
      filter: {
        search_text: '',
        order_by: 'created_at DESC',
        limit: 25,
        page: 1
      },
      expandRowKeys: []
    }
  },
  mixins: [ calcMixin, permissionMixins ],
  computed: {
    ...mapState({
      customers: state => state.customer.customers,
      totals: state => state.customer.totals
    }),
    ...mapGetters({
      label: 'customer/getLabelById'
    }),
    debounceOnChange () {
      return _debounce(this.searchByKey, 700)
    }
  },
  watch: {
    'filter.order_by': {
      handler () {
        this.loadCustomers()
      }
    },
    'filter.limit': {
      handler () {
        this.loadCustomers()
      }
    },
    'filter.page': {
      handler () {
        this.loadCustomers()
      }
    }
  },
  created () {
    this.loadCustomers()
  },
  methods: {
    ...mapActions('customer', [
      'load',
      'refresh'
    ]),
    loadCustomers () {
      this.loading = true
      this.load(this.filter)
        .then(() => {
          this.loading = false
        }).catch(() => {
          console.warn('Error loading customers')
        })
    },
    searchByKey () {
      this.loadCustomers()
    },
    handlePageSize (limit) {
      this.filter.limit = limit
    },
    handleCurrentPage (page) {
      this.filter.page = page
    },
    expandRow (row) {
      const lastId = this.expandRowKeys[0]
      this.expandRowKeys = row.id === lastId ? [] : [row.id]
    },
    updated () {
      let message = this.$t('activity.updated', { name: 'customer' })
      this.$message({
        message: message,
        type: 'success'
      })
    },
    cancel (row) {
      this.refresh(row)
        .then(() => {
          this.expandRowKeys = []
        })
    },
    unique (array) {
      return [...new Set(array)]
    }
  }
}
</script>

<template>
  <el-dialog
    title="Add User"
    :visible.sync="show"
    width="400px"
    center>
    <el-form>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-row :gutter="20">
            <el-col :span="12" >
              <el-form-item
                :show-message="errors.has('first name')"
                :error="errors.first('first name') ? errors.first('first name').includes('required') ? 'First Name Required.' : 'Alphabetic Only' : ''"
              >
                <el-input
                  v-model="user.first_name"
                  placeholder="First Name"
                  data-vv-name="first name"
                  v-validate="'required|alpha'"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :show-message="errors.has('last name')"
                :error="errors.first('last name') ? errors.first('last name').includes('required') ? 'Last Name Required.' : 'Alphabetic Only' : ''"
              >
                <el-input
                  v-model="user.last_name"
                  placeholder="Last Name"
                  data-vv-name="last name"
                  v-validate="'required|alpha'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" >
              <el-form-item>
                <vue-tel-input
                  v-model="user.phone"
                  placeholder="Enter phone number"
                ></vue-tel-input>
              </el-form-item>
              <el-form-item
                :show-message="errors.has('email')"
                :error="errors.first('email')"
              >
                <el-input
                  v-model="user.email"
                  placeholder="Enter email address"
                  data-vv-name="email"
                  v-validate="'required|email'"
                ></el-input>
              </el-form-item>
              <el-form-item
                :show-message="errors.has('permissons level')"
                :error="errors.first('permissons level')"
              >
                <el-select
                  placeholder="Select a permissions level"
                  v-model="user.role"
                  data-vv-name="permissons level"
                  v-validate.disable="`required|included:${roles.join(',')}`"
                  class="full-width"
                  data-vv-as="permissions level"
                >
                  <el-option
                    v-for="role in roles"
                    :key="role"
                    :value="role"
                    :label="$options.filters.humanize(role)"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="shouldHavePermittedStore"
                :show-message="errors.has('assigned stores')"
                :error="errors.first('assigned stores')"
              >
                <el-select
                  placeholder="Select stores"
                  class="full-width"
                  data-vv-name="assigned stores"
                  v-validate.disable="'required'"
                  v-model="user.stores"
                  :multiple-limit="maxAssignedStores"
                  multiple
                >
                  <el-option
                    v-for="store in stores"
                    :key="store.id"
                    :value="store.id"
                    :label="store.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="$emit('close-modal')" round
      >Cancel</el-button>
      <el-button type="primary"
        :loading="loading"
        @click="createUser" round
      >&nbsp;&nbsp;Add&nbsp;&nbsp;</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { roles } from '@/consts'
import { VueTelInput } from 'vue-tel-input'
export default {
  name: 'user-create-form',
  props: ['user', 'user_modal', 'loading'],
  data () {
    return {
      roles: roles
    }
  },
  computed: {
    ...mapState({
      stores: state => state.store.stores
    }),
    show: {
      get () {
        return this.user_modal
      },
      set () {
        this.$emit('close-modal')
      }
    },
    shouldHavePermittedStore () {
      return ['security', 'manager', 'advanced_security'].includes(this.user.role)
    },
    maxAssignedStores () {
      return ['security', 'advanced_security'].includes(this.user.role) ? 1 : 0
    }
  },
  components: {
    VueTelInput
  },
  methods: {
    createUser () {
      this.$validator.validateAll()
        .then(res => {
          if (res) {
            this.$emit('create-user')
          }
        })
    }
  }
}
</script>
